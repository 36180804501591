import React from "react";
import { useNavigate } from "react-router-dom";
import Ecommerce from "../../../static/assets/img/IndustryPage/Ecommerce.png";
import IndustryBackground from "../../../static/assets/img/IndustryPage/IndustryBackground.png";
import analyticsIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/analyticsIcon.png";
import blockchainIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/blockchainIcon.png";
import complianceIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/compilanceIcon.png";
import fintechIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/fintechIcon.png";
import paymentIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/paymentIcon.png";
import securityIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/securityIcon.png";
import icon1 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/Ecommerce/icon1.png";
import icon2 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/Ecommerce/icon2.png";
import icon3 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/Ecommerce/icon3.png";
import icon4 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/Ecommerce/icon4.png";
import icon5 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/Ecommerce/icon5.png";
import icon6 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/Ecommerce/icon6.png";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import CaseStudy from "../../common/Cards/CaseStudy";
import AdvantageSection from "../../common/Industry_Reusable_Components/AdvantageSection";
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import ReusableParagraph from "../../common/Industry_Reusable_Components/ReusableParagraph";
import ServiceOfferings from "../../common/Industry_Reusable_Components/ServiceOfferings";
import { ValuePropositionGrid } from "../../common/Industry_Reusable_Components/ValuePropositionCard";
import ClientTestimonialSec from "../ClientTestimonialSec/ClientTestimonialSec";
import Footer from "../FooterSec/Footer";
const bgColor = "bg-white";
const servicesData = [
  {
    icon: fintechIcon, // Replace with actual image or SVG
    title: "Supply Chain Optimization",
    description:
      "Demand forecasting, inventory management, logistics optimization, and more.",
  },
  {
    icon: paymentIcon,
    title: "Retail Management Solutions",
    description:
      "Point-of-sale (POS) solutions, inventory tracking, customer relationship management (CRM) tools, and more.",
  },
  {
    icon: blockchainIcon,
    title: "E-commerce Integration",
    description:
      "Unified online and offline presence, e-commerce platform integration, and more.",
  },
  {
    icon: complianceIcon,
    title: "Warehouse Management",
    description:
      "Warehouse management systems (WMS), inventory accuracy, order fulfillment speed, and more.",
  },
  {
    icon: analyticsIcon,
    title: "Data Analytics",
    description:
      "Data visualization, predictive analytics, reporting, and more. Call to Action.",
  },
  {
    icon: securityIcon,
    title: "Security & Fraud Prevention",
    description:
      "Protect your business with multi-layered security measures, from cooling periods to advanced authentication.",
  },
];

const advantageData = {
  title: "Advantages with Us",
  image: Ecommerce, // Replace with actual path
  advantages: [
    {
      title: "Industry Expertise",
      description:
        "Our team boasts extensive knowledge of the supply chain and retail sector, ensuring tailored solutions to address your specific needs and challenges.",
    },
    {
      title: "Innovation",
      description:
        "We leverage emerging technologies like AI, blockchain, and IoT to future-proof your operations and help you stay competitive in a rapidly changing market.",
    },
    {
      title: "Custom Solutions",
      description:
        "Our offerings are designed to adapt and grow with your business, ensuring seamless integration and minimal disruption.",
    },
    {
      title: "Security",
      description:
        "Your data and transactions are safeguarded by robust security measures, ensuring confidentiality, integrity, and availability.",
    },
    {
      title: "Client-Centric Approach",
      description:
        "Your success is our priority, and we collaborate closely with you to achieve it, providing personalized support and guidance every step of the way.",
    },
  ],
};

const Solutions = [
  {
    icon: icon1,
    title: "Enhance Supply Chain Efficiency",
    description:
      "Optimize your supply chain with our advanced solutions, featuring demand forecasting, inventory management, and logistics optimization.",
  },
  {
    icon: icon2,
    title: "Elevate Retail Operations",
    description:
      "Streamline your retail management with our comprehensive systems, including point-of-sale (POS) solutions, inventory tracking, and customer relationship management (CRM) tools.",
  },
  {
    icon: icon3,
    title: "Seamless E-commerce Integration",
    description:
      "Unify your online and offline presence with our e-commerce solutions, ensuring a consistent and efficient customer experience.",
  },
  {
    icon: icon4,
    title: "Streamline Warehouse Operations",
    description:
      "Maximize your warehouse efficiency with our warehouse management systems (WMS), featuring improved inventory accuracy and order fulfillment speed.",
  },
  {
    icon: icon5,
    title: "Data-Driven Insights",
    description:
      "Harness the power of data with our analytics solutions, providing valuable insights for informed decision-making in both supply chain and retail management.",
  },
  {
    icon: icon6,
    title: "Expertise in Fintech",
    description:
      "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
  },
];

const EcommerceRetail = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="relative w-full h-[70vh] xl:h-[70vh] bg-cover bg-center flex flex-col items-center justify-center text-center px-6"
        style={{ backgroundImage: `url(${IndustryBackground})` }}
      >
        <div className="container-lg">
          <div className="absolute inset-0 bg-blue-900 opacity-50"></div>
          <div className="relative z-10 text-white w-full flex flex-col items-center text-center">
            <ReusableHeadingSection
              heading="Retail Mastery: Conquer the Digital Landscape "
              subtext="Maximize your retail potential with our data-driven solutions, designed to enhance agility, reduce costs, and improve customer satisfaction.  "
              headingSize="text-[3.75rem] xl:text-[6rem]"
              subtextSize="text-[2.2rem] xl:text-[3.2rem]"
              textColor="text-white"
              subtextColor="text-white"
              align="text-center"
              additionalClasses="mt-6 "
            />

            <div className="mt-10">
              {/* <PrimaryButton 
                title="Learn More" 
                bgColor="bg-transparent hover:bg-green-400 border-2 border-green-400  rounded-full" 
                titleColor="text-white hover:text-black" 
                textSize="text-[1.6rem] xl:text-[2.2rem]" 
                fontWeight="font-medium" 
                width="w-auto px-10 py-6" 
                height="py-3" 
                hasArrow={true} 
                classNames="text-center flex items-center justify-center"
                onClick={() => {
                  document.getElementById("advantages-section")?.scrollIntoView({ behavior: "smooth" });
                }} 
              /> */}
              <button
                onClick={() => {
                  document
                    .getElementById("advantages-section")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                class="
        flex items-center justify-center
        cursor-pointer
        border-2 rounded-full
        bg-inherit text-white hover:bg-[#00D47E] 
        w-[22rem] 
        border-[#00D47E] 
        text-white hover:text-[#0E0731]
        text-[1.6rem]
        poppins-medium
        h-[6rem]
    "
              >
                Learn More
                <div class="mx-[2rem]">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ReusableParagraph
        text="Optimizing supply chain and retail management with innovative technology solutions for enhanced efficiency and customer satisfaction. We can be your strategic partner in transforming supply chain and retail management through cutting-edge technology solutions."
        textColor="text-gray-700"
        textSize="text-[1.5625rem] xl:text-[3rem]"
      />

      <AdvantageSection {...advantageData} id="advantages-section" />
      <ValuePropositionGrid items={Solutions} />

      <div className="w-full bg-[#F2F4F4]">
        <ServiceOfferings services={servicesData} />

        <div className="flex justify-center mt-8 mb-12 bg-[#F2F4F4]">
          <button
            className="bg-[#F2F4F4] border-2 border-[#00D47E] text-black text-[1.6rem] poppins-medium py-3 px-8 rounded-md transition duration-300 w-72 hover:bg-[#00D47E] hover:text-white"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us Now!
          </button>
        </div>
      </div>
      <CaseStudy bgColor={bgColor} />
      <ClientTestimonialSec />
      <Footer />
    </>
  );
};

export default EcommerceRetail;
