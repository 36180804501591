import { call, put, takeLatest } from "redux-saga/effects";
import { authFailure, authSuccess } from "../actions/authActions";
import { endpoints, strapi_base_url } from "../constants";
import { actionTypes } from "../types";
import { AxiosService } from "../services";

function* authCall({ payload, callback }) {
  try {
    const response = yield call(AxiosService,
      {
        method: 'get',
        base_url: strapi_base_url,
        endPoint: endpoints.auth,
        data: payload,
        isAuth: false,
      });

      console.log("authCall response :" , response);
      

      yield put(authSuccess({
        status: response?.status,
        data: response?.data?.data,
        message: response?.message
      })
    )
    if (callback) {
      callback(
        response?.status,
        response?.response,
        response?.message
      ); // Pass the response data to the callback
    }
  } catch (error) {
    console.log("authCall error :", error);
    
    yield put(authFailure({
      status: error.response?.status,
      message: error.response?.message
    }));
  }
}

function* authSaga() {
  yield takeLatest(actionTypes.AUTH_REQUEST, authCall);
}

export default authSaga;