import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { caseStudy } from '../../../Store/actions/caseStudyAction'
import { strapi_base_url } from '../../../Store/constants'
import DetailsHeading from '../../common/DetailsCommons/DetailsHeading'
import LeftStyle from '../../common/DetailsCommons/LeftStyle'
import Benifits from '../Benifits/Benifits'
import Footer from '../FooterSec/Footer'
import KeyChallenges from '../KeyChallenges/KeyChallenges'
import ReadyToGetStartSec from '../ReadyToGetStartSec/ReadyToGetStartSec'
import TechUsed from '../TechUsed/TechUsed'

export default function CaseStudyDetails() 
{
  const { id } = useParams();
  
  //Redux API Call
  const dispatch = useDispatch();
  const selectCaseStudies = useSelector((state) => state.caseStudyReducer.data);
  
  useEffect(()=>
  {
        dispatch(caseStudy());
  },[dispatch]);

  let content_1 = selectCaseStudies[id]?.attributes?.content1.split("\n\n") || [];
  let content_2 = selectCaseStudies[id]?.attributes?.content2.split("\n\n") || [];
  let content_3 = selectCaseStudies[id]?.attributes?.Content3.split("\n\n") || [];
  let content_5 = selectCaseStudies[id]?.attributes?.content_5.split("\n\n") || [];
    
  return (
    <div>
      <DetailsHeading bgImg={"caseStudyDetails"} 
      heading={selectCaseStudies[id]?.attributes?.oneLineDescription} 
      line={selectCaseStudies[id]?.attributes?.Title}
      overview={selectCaseStudies[id]?.attributes?.overview}
      need={"visible"}/>

      <LeftStyle title={selectCaseStudies[id]?.attributes?.title_5} 
      content={content_5} 
      image={strapi_base_url+"_img"+selectCaseStudies[id]?.attributes?.image_5.data?.attributes?.url}/>

      <KeyChallenges title={selectCaseStudies[id]?.attributes?.title1} 
      content={content_1} 
      image={strapi_base_url+"_img"+selectCaseStudies[id]?.attributes?.image1.data?.attributes?.url}/>

      <LeftStyle title={selectCaseStudies[id]?.attributes?.title2} 
      content={content_2} 
      image={strapi_base_url+"_img"+selectCaseStudies[id]?.attributes?.image2.data?.attributes?.url}/>

      <Benifits title={selectCaseStudies[id]?.attributes?.Title3} 
      content={content_3}
      description={selectCaseStudies[id]?.attributes?.Description}/>

      <TechUsed title={selectCaseStudies[id]?.attributes?.Title4} 
      conclusion={selectCaseStudies[id]?.attributes?.Conclusion} 
      tech={selectCaseStudies[id]?.attributes?.technologies?.data}
      document={strapi_base_url+"_img"+selectCaseStudies[id]?.attributes?.document?.data?.attributes?.url}/>

      <ReadyToGetStartSec bold={"poppins-bold"}/>
      <Footer bgColor={"bg-[#070F29]"} lowerBgColor={"bg-[#2666CF]"}/>
    </div>
  )
}