import React from 'react';
import { FaArrowLeft } from 'react-icons/fa6';

function LeftArrowCircle({ onClick, bgBack }) {
  return (
    <button 
      className={`w-20 h-20 rounded-full ${bgBack ? bgBack : "bg-white"} text-black font-bold
      transition duration-300 hover:bg-green-500 hover:text-white border-2
      border-green-500`}
      onClick={onClick}
    >
      <FaArrowLeft className="size-14 pl-4 hover:text-white" />
    </button>
  );
}

export default LeftArrowCircle;
