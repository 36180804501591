import React from 'react';
import { serviceImg, serviceLogo } from '../../../static/constants/imageConstant';
import CaseStudy from '../../common/Cards/CaseStudy';
import ServiceGrowth from '../../common/ServiceCommons/ServiceGrowth';
import ServiceHeading from '../../common/ServiceCommons/ServiceHeading';
import ServiceInclude from '../../common/ServiceCommons/ServiceInclude';
import ServicePartners from '../../common/ServiceCommons/ServicePartners';
import ServiceTransformation from '../../common/ServiceCommons/ServiceTransformation';
import ClientTestimonialSec from '../ClientTestimonialSec/ClientTestimonialSec';
import Footer from '../FooterSec/Footer';
import LatestInsightSec from '../LatestInsightSec/LatestInsightSec';
import ReadyToGetStartSec from '../ReadyToGetStartSec/ReadyToGetStartSec';

const bgColor="bg-white";

const serviceHeading = "Empower Your Business with Intelligent AI & ML Solutions";

const transformHeadingOne = "Transform Your Vision into Reality Leverage the Power of AI and ML for Business Transformation";

const growthHeadingOne = "Driving Growth with End-to-End AI & ML Strategy";
const growthHeadingTwo = "Our AI & ML Approach Includes";

const serviceLineOne = "At Infominez, we guide your organization through a transformative AI and ML journey, turning data into powerful insights and creating intelligent systems that drive innovation, efficiency, and growth.";

const includeHeading = "Our AI and ML Services Include";
const partnerHeadingOne = "Why Choose Infominez for AI &";
const partnerHeadingTwo = "ML Excellence?";

const growthImg = serviceImg.aimlServiceImg;
const includeImg = serviceImg.aimlPlayingImg;
const partnerImg = serviceImg.aimlBotImg;

const transform = [
    {
      title:"Data-Driven Decision Making",
      detail:"Use AI-powered insights to optimize operations, uncover opportunities, and manage risks effectively.", 
      serviceLogo:serviceLogo.serviceLogoOne, 
    },
    {
      title:"Enhanced Productivity",
      detail:"Automate repetitive tasks and streamline workflows with intelligent algorithms.", 
      serviceLogo:serviceLogo.serviceLogoTwo, 
    },
    {
      title:"Customer Personalization",
      detail:"Deliver tailor-made experiences using predictive analytics and machine learning models.", 
      serviceLogo:serviceLogo.serviceLogoThree, 
    },
    {
      title:"Scalable Solutions",
      detail:"Build robust AI systems that grow with your business.",
      serviceLogo:serviceLogo.serviceLogoFour,  
    },
    {
      title:"Ensure Data Security",
      detail:"Implement AI solutions designed to protect data privacy and maintain compliance.",  
      serviceLogo:serviceLogo.serviceLogoFive,
    },
    {
      title:"Partnership Beyond Launch",
      detail:"Rely on our ongoing maintenance and support to ensure your product continues to thrive, adapt, and evolve with your users' needs.",  
      serviceLogo:serviceLogo.serviceLogoSix,
    },  
]

const growth = [
    {
        title:"Define Business Objectives",
        detailOne:"Align AI/ML initiatives with your business goals. Assess Data Readiness: Evaluate your current data ecosystem for AI integration.",
    },
    {
        title:"Develop Models",
        detailOne:"Design and build ML models tailored to your use cases. Implement and Integrate Seamlessly deploy and integrate AI systems into your existing infrastructure.",
    },
    {
        title:"Optimize and Iterate",
        detailOne:"Continuously refine models to improve accuracy and adaptability. Foster AI-Driven Culture: Equip your teams with the skills and mindset for AI adoption.",
    },
    {
        title:"DevOps and Continuous Integration",
        detailOne:"Seamless user experiences that foster customer loyalty.",
    },
    {
        title:"Maintenance and Support",
        detailOne:"Ongoing maintenance and support services to ensure your product stays up-to-date and responsive to changing market demands.",
    },
]

const include = [
    {
        title:"AI Strategy & Consulting",
        detailOne:"Partner with us to define a scalable AI strategy that aligns with your business vision.",
    },
    {
        title:"Custom Machine Learning Models",
        detailOne:"Develop tailored ML models for predictive analytics, NLP, computer vision, and more.",
    },
    {
        title:"Data Preprocessing & Integration",
        detailOne:"Ensure high-quality data pipelines and seamless integration across platforms.",
    },
    {
        title:"AI-Powered Automation",
        detailOne:"Optimize workflows through AI-driven robotic process automation (RPA).",
    },
    {
        title:"Advanced Analytics & Insights",
        detailOne:"Turn data into actionable strategies with AI-powered tools.",
    },
    {
        title:"Compliance & Ethical AI",
        detailOne:"Implement responsible AI systems with built-in compliance to industry standards.",
    },
]

const partner=[
    {
        title:"Expertise in AI & ML",
        detailOne:"Leverage our deep technical knowledge and experience in delivering AI solutions.",
    },
    {
        title:"Innovative Problem Solving",
        detailOne:"Create cutting-edge solutions tailored to your unique business challenges.",
    },
    {
        title:"Scalable and Flexible Systems",
        detailOne:"Build adaptable AI infrastructure to meet your future needs.",
    },
    {
        title:"Accelerated Insights",
        detailOne:"Achieve faster time-to-value with efficient deployment and iteration.",
    },
    {
        title:"Ethical AI Practices",
        detailOne:"Ensure responsible and compliant AI implementation.",
    },
]

function AIMLService() {
  return (
    <div>
       <ServiceHeading serviceHeading={serviceHeading} serviceLineOne={serviceLineOne}/>
       <ServiceTransformation id="transformation" buttonText={"Unlock AI-Driven Opportunities "}
        transform={transform} transformHeadingOne={transformHeadingOne} bgBack={"bg-[#F2F4F4]"}/>
       <ServiceGrowth growth={growth} growthHeadingOne={growthHeadingOne} growthHeadingTwo={growthHeadingTwo} 
       growthImg={growthImg} classNames={"ml-12"} w={"lg:w-[160rem]"}/>
       <ServiceInclude include={include} includeHeading={includeHeading} includeImg={includeImg} 
       showIncludeButton = {"visible"} w={"lg:w-[80rem]"} bgBack={"bg-[#F2F4F4]"}/>
       <div className="h-[8rem]"></div>
       <ServicePartners partner={partner} partnerHeadingOne={partnerHeadingOne} partnerHeadingTwo={partnerHeadingTwo} partnerImg={partnerImg}/>
       <CaseStudy bgColor={bgColor}/>
       <ClientTestimonialSec/>
       <LatestInsightSec/>
       <ReadyToGetStartSec id="ready" bgColor={bgColor}/>
       <Footer/>
    </div>
  )
}

export default AIMLService; 