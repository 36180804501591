export const NavbarImg = 
{
    LogoLight: require('../assets/img/logos/infominez-logo-light.png'),
    LogoDark: require('../assets/img/logos/infominez-logo-dark.png'),
    upperColon: require('../assets/img/logos/upperColon.png'),
}

export const Client =
{
    clientA: require('../assets/img/photos/Client1.png'),
    clientB: require('../assets/img/photos/sonali.png'),
    clientC: require('../assets/img/photos/manohar.png'),
}

export const trustedClients =
{
    IcfLogo: require('../assets/img/logos/ICFLogo.png'),
    NictLogo: require('../assets/img/logos/NICTLogo.png'),
    MaayaLogo: require('../assets/img/logos/MaayaLogo.png'),
    PaulLogo: require('../assets/img/logos/PaulLogo.png'),
    PaidLogo: require('../assets/img/logos/paidPromo.png'),
    chameleonLogo: require('../assets/img/logos/chameleonlogo.png'),
    inadzLogo: require('../assets/img/logos/InadzLogo.png'),
    fitZoneLogo : require('../assets/img/logos/FitZoneLogo.png'),
    rackBankLogo: require('../assets/img/logos/rackbankLogo.png'),
    investmentorLogo: require('../assets/img/logos/InvestmentorLogo.jpg'),
    EpsLogo: require('../assets/img/logos/Epslogo.png'),
    kritiLogo: require('../assets/img/logos/kritiLogo.png'),
    KastaLogo: require('../assets/img/logos/KastaPipesLogo.png'),
    andBuyLogo: require('../assets/img/logos/andBuylogo.png'),
}

export const latestInsights = 
{
    LatestOne: require('../assets/img/photos/latestOne.png'),
    LatestTwo: require('../assets/img/photos/latestTwo.png'),
    LatestThree: require('../assets/img/photos/LatestThree.png'),
    authorPhotoOne: require('../assets/img/photos/authorPhoto.png'),
}

export const caseStudy = 
{
    caseUPI: require('../assets/img/photos/caseUPI.png'),
}

export const footerLogo =
{
    callUs: require('../assets/img/logos/callUs.png'),
    mailUs: require('../assets/img/logos/mailUs.png'),
    locateUs: require('../assets/img/logos/locateUs.png'),
} 

export const serviceImg = 
{
   serviceVision: require("../assets/img/photos/serviceVision.jpg"),
   productServiceImg: require("../assets/img/photos/productImg.png"),
   productPartner: require("../assets/img/photos/productPartner.png"),
   aimlServiceImg: require("../assets/img/photos/Ai&MlPic.png"),
   aimlPlayingImg: require("../assets/img/photos/PlayingAiPic.png"),
   aimlBotImg: require("../assets/img/photos/AiBot.png"),
   DataStrategyImg: require("../assets/img/photos/DataStrategyPic.png"),
   DataStrategyAnalysisImg: require("../assets/img/photos/DataStrategyAnalysis.png"),
   DataPartnerImg: require("../assets/img/photos/DataPartner.png"),
   CloudPartnerImg: require("../assets/img/photos/CloudPartner.png"),

}

export const serviceLogo =
{
    serviceLogoOne: require("../assets/img/logos/serviceLogoOne.png"),
    serviceLogoTwo: require("../assets/img/logos/serviceLogoTwo.png"), 
    serviceLogoThree: require("../assets/img/logos/serviceLogoThree.png"), 
    serviceLogoFour: require("../assets/img/logos/serviceLogoFour.png"), 
    serviceLogoFive: require("../assets/img/logos/serviceLogoFive.png"), 
    serviceLogoSix: require("../assets/img/logos/serviceLogoSix.png"), 
}

export const ServiceCloudLogo = 
{
    CloudLogoOne: require("../assets/img/logos/CloudLogoOne.png"),
    CloudLogoTwo: require("../assets/img/logos/CloudLogoTwo.png"),
    CloudLogoThree: require("../assets/img/logos/CloudLogoThree.png"),
    CloudLogoFour: require("../assets/img/logos/CloudLogoFour.png"),
    CloudLogoFive: require("../assets/img/logos/CloudLogoFive.png"),
    CloudLogoSix: require("../assets/img/logos/CloudLogoSix.png"),
}

export const ServicePodImg = 
{
    podTeamImg: require("../assets/img/photos/podTeam.jpg"),
    podStartupImg: require("../assets/img/photos/StartupPod.png"),
    podGrowthImg: require("../assets/img/photos/GrowthPod.png"),
    podCustomImg: require("../assets/img/photos/CustomPod.png"),
    podEnjoyImg: require("../assets/img/photos/PodEnjoy.png"),
}

export const ServicePodLogo =
{
    PodLogoOne: require("../assets/img/logos/PodLogoOne.png"),
    PodLogoTwo: require("../assets/img/logos/PodLogoTwo.png"),
    PodLogoThree: require("../assets/img/logos/PodLogoThree.png"),
    PodLogoFour: require("../assets/img/logos/PodLogoFour.png"),
    PodLogoFive: require("../assets/img/logos/PodLogoFive.png"),
}

export const BlogImg = 
{
    blogDetailPic: require("../assets/img/photos/blogDetailPic.png"),
    man: require("../assets/img/photos/Man.png"),
    girl: require("../assets/img/photos/Girl.png"),
    developer: require("../assets/img/photos/Developer.png"),
    related: require("../assets/img/photos/relatedPhotos.png"),
}

export const CarrerImg = 
{
    carrerPic: require("../assets/img/photos/CarrerPagePic.png"),
    lifeAtInfominez: require("../assets/img/photos/lifeAtInfominez.png"),
    chooseInfominez: require("../assets/img/photos/chooseInfominezPic.png"),
    greenUpperColon: require("../assets/img/logos/greenUpperColon.png"),
}

export const CarrierLogo =
    {
      carrierLogoOne: require("../assets/img/logos/CarrerLogoOne.png"),
      carrierLogoTwo: require("../assets/img/logos/CarrerLogoTwo.png"),
      carrierLogoThree: require("../assets/img/logos/CarrerLogoThree.png"),
      carrierLogoFour: require("../assets/img/logos/CarrerLogoFour.png"),  
    }

export const CaseStudyDetailsImg = 
{
  caseStudyDetailPic: require("../assets/img/photos/caseStudyDetailPic.png"),
  valueProposition: require("../assets/img/photos/valueProposition.png"),
  keyChallenges: require("../assets/img/photos/keyChallenges.png"),
  solutions: require("../assets/img/photos/solutions.png"),
  visual: require("../assets/img/photos/visualize.png"),
}