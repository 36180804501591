import React, { useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { FaChevronDown } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router";
import { NavbarImg } from "../../../static/constants/imageConstant";
import PrimaryButton from "../../common/Buttons/PrimaryButton";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const isContactUs = location.pathname === "/contact-us";
  const isResources = location.pathname.includes("resources");
  // Dropdown states
  const [industriesDropdown, setIndustriesDropdown] = useState(false);
  const [mobileIndustriesDropdown, setMobileIndustriesDropdown] =
    useState(false);

  const [resourcesDropdown, setResourcesDropdown] = useState(false);
  const [mobileResourcesDropdown, setMobileResourcesDropdown] = useState(false);

  // Services dropdown states
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [mobileServicesDropdown, setMobileServicesDropdown] = useState(false);

  const activePage =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [navbarDropdown, setNavbarDropdown] = useState(false);
  // Added screen size state
  const [screenSize, setScreenSize] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });
  const dropdownRef = useRef(null);

  // Updated navLinks: Added Career as a direct link
  const navLinks = [
    {
      title: "Home",
      path: "/",
      active: activePage === "" || activePage === "#",
    },
    {
      title: "Career",
      path: "/careers",
      active: window.location.href.includes("careers"),
    },
    {
      title: "About Us",
      path: "/about-us",
      active: window.location.href.includes("about-us"),
    },
    {
      title: "Contact Us",
      path: "/contact-us",
      active: window.location.href.includes("contact-us"),
    },
  ];

  const industryLinks = [
    { title: "Fintech & Banking", path: "/fintech-banking" },
    { title: "E-Commerce & Retail", path: "/ecommerce-retail" },
    { title: "Edutech and Learning", path: "/edutech-learning" },
    { title: "Health and Wellness", path: "/health-wellness" },
  ];

  const servicesLinks = [
    { title: "Product Engineering", path: "/product-engineering" },
    { title: "AI & ML Engineering", path: "/ai-ml-engineering" },
    { title: "Data Engineering", path: "/data-engineering" },
    { title: "Cloud Engineering", path: "/cloud-engineering" },
    { title: "POD Services", path: "/pod-services" },
  ];

  // Function to handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to determine if device is iPad Air or Asus Zenbook Fold
  const isSpecificDevice = () => {
    // iPad Air width is around 820px, Asus Zenbook Fold is around 853x1280
    return (
      (screenSize.width >= 768 && screenSize.width <= 900) ||
      (screenSize.width >= 840 &&
        screenSize.width <= 860 &&
        screenSize.height >= 1270 &&
        screenSize.height <= 1290)
    );
  };

  // Add this function to handle navigation with scroll to top
  const handleNavigation = (path, dropdownStateSetter = null) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Close dropdown if provided
    if (dropdownStateSetter) {
      dropdownStateSetter(false);
    }

    // Close mobile menu if open
    if (navbarDropdown) {
      setNavbarDropdown(false);
    }
  };

  const renderNavBtn = () => {
    // Adjust button size for specific devices
    const buttonWidth = isSpecificDevice() ? "w-[14rem]" : "w-[16rem]";
    const textSize = isSpecificDevice() ? "text-[1.3rem]" : "text-[1.5rem]";
    const height = isSpecificDevice() ? "h-[4.5rem]" : "h-[5.2rem]";

    return (
      <PrimaryButton
        onClick={() => handleNavigation("/contact-us")}
        title="Get Started"
        bgColor="bg-transparent hover:bg-[#00D47E]"
        borderColor="border-2 border-[#00D47E]"
        titleColor={
          isContactUs && !isScrolled
            ? "text-black hover:text-black"
            : "text-white hover:text-black"
        }
        textSize={textSize}
        fontWeight="font-semibold"
        width={buttonWidth}
        height={height}
        classNames="rounded-full transition duration-300 select-none"
        hasArrow={false}
      />
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  //for closing dropdown when we click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navbarDropdown &&
        !event.target.closest(".mobile-menu") &&
        !event.target.closest('button[aria-label="Toggle mobile menu"]')
      ) {
        setNavbarDropdown(false);
        setMobileIndustriesDropdown(false);
        setMobileServicesDropdown(false);
        setMobileResourcesDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [navbarDropdown]);

  // Function to close all dropdowns
  const closeAllDropdowns = () => {
    setIndustriesDropdown(false);
    setResourcesDropdown(false);
    setServicesDropdown(false);
  };
  const serviceSubroutes = [
    "/product-engineering",
    "/ai-ml-engineering",
    "/data-engineering",
    "/cloud-engineering",
    "/pod-services",
    // Add all your service-related subroutes here
  ];
  const industriesSubroutes = [
    "/fintech-banking",
    "/ecommerce-retail",
    "/edutech-learning",
    "/health-wellness",

    // Add all your service-related subroutes here
  ];
  const resourcesSubroutes = ["/blog-section", "/case-studies"];
  // Check if current path matches any service subroute
  const isOnServicePage = serviceSubroutes.some(
    (route) =>
      location.pathname === route || location.pathname.startsWith(route + "/")
  );
  const isOnIndustriesPage = industriesSubroutes.some(
    (route) =>
      location.pathname === route || location.pathname.startsWith(route + "/")
  );
  const isOnResourcesPage = resourcesSubroutes.some(
    (route) =>
      location.pathname === route || location.pathname.startsWith(route + "/")
  );
  // Determine if we should use mobile menu based on screen size
  const isMobileMenu = () => {
    return screenSize.width < 768 || isSpecificDevice();
  };
  const mobileMenuActive = isMobileMenu();
  return (
    <nav
      id="navbar"
      className={`navbar ${isScrolled ? "bg-blue-950" : ""} ${
        !isDetailsPage && "navbar-bg"
      } transition-all ease-in duration-100 poppins-light flex justify-between items-center w-full h-[10rem] fixed overflow-visible z-[80]`}
    >
      <div className="nav-container flex justify-between max-w-[1440px] mx-auto items-center container-lg py-[1.2rem] px-4 md:px-6 w-full overflow-visible">
        {/* Logo */}
        <div
          className={`nav-logo cursor-pointer ${
            isSpecificDevice() ? "w-[16rem]" : "w-[18rem] md:w-[23rem]"
          }`}
          onClick={() => handleNavigation("/")}
        >
          <img
            className="w-full h-auto"
            src={
              isContactUs && !isScrolled
                ? NavbarImg.LogoDark
                : NavbarImg.LogoLight
            }
            alt="logo"
          />
        </div>

        {/* Desktop Navigation - Hide on specific devices */}
        <div
          className={`${
            mobileMenuActive ? "hidden" : "hidden md:flex"
          } justify-end items-center relative overflow-visible`}
        >
          {/* Home Link */}
          <div className="flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]">
            <a
              className={`${
                navLinks[0].active ? "poppins-bold" : ""
              } text-[1.6rem] nav-link select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem] cursor-pointer`}
              onClick={() => handleNavigation(navLinks[0].path)}
            >
              {navLinks[0].title}
            </a>
            {navLinks[0].active && (
              <div className="w-full h-[0.2rem] rounded-[.3rem] bg-[#00D47E]"></div>
            )}
          </div>

          {/* Services Dropdown - Desktop */}
          <div
            className="relative flex flex-col items-center lg:mx-[1.6rem] mx-[1rem] overflow-visible select-none"
            onMouseEnter={() => setServicesDropdown(true)}
            onMouseLeave={() => setServicesDropdown(false)}
          >
            <button
              className={`text-[1.6rem] nav-link flex items-center gap-2 select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem]`}
            >
              Services
              <FaChevronDown className="text-lg" />
            </button>
            <div
              className={`w-full h-[0.2rem] rounded-[.3rem] bg-[#00D47E] transition-all duration-200 ${
                servicesDropdown || isOnServicePage
                  ? "opacity-100"
                  : "opacity-0"
              }`}
            ></div>

            {/* Buffer area to prevent closing issue */}
            {servicesDropdown && (
              <div className="absolute top-full mt-0 w-[18rem] bg-transparent h-2"></div>
            )}

            {servicesDropdown && (
              <div
                className="absolute top-full mt-2 w-[18rem] bg-blue-950 text-white shadow-lg select-none"
                onMouseEnter={() => setServicesDropdown(true)}
                onMouseLeave={() => setServicesDropdown(false)}
              >
                {servicesLinks.map((service, index) => (
                  <a
                    key={index}
                    className="block text-[1.375rem] poppins-regular px-4 py-2 hover:bg-blue-800 transition-colors duration-200 cursor-pointer select-none"
                    onClick={() =>
                      handleNavigation(service.path, setServicesDropdown)
                    }
                  >
                    {service.title}
                  </a>
                ))}
              </div>
            )}
          </div>

          {/* Industries Dropdown - Desktop */}
          <div
            className="relative flex flex-col items-center lg:mx-[1.6rem] mx-[1rem] overflow-visible select-none"
            onMouseEnter={() => setIndustriesDropdown(true)}
            onMouseLeave={() => setIndustriesDropdown(false)}
          >
            <button
              className={`text-[1.6rem] nav-link flex items-center gap-2 select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem]`}
            >
              Industries
              <FaChevronDown className="text-lg" />
            </button>
            <div
              className={`w-full h-[0.2rem] rounded-[.3rem] bg-[#00D47E] transition-all duration-200 ${
                industriesDropdown || isOnIndustriesPage
                  ? "opacity-100"
                  : "opacity-0"
              }`}
            ></div>
            {industriesDropdown && (
              <div className="absolute top-full mt-0 w-[18rem] bg-transparent h-2 select-none"></div>
            )}

            {industriesDropdown && (
              <div
                className="absolute top-full mt-2 w-[18rem] bg-blue-950 text-white shadow-lg select-none"
                onMouseEnter={() => setIndustriesDropdown(true)}
                onMouseLeave={() => setIndustriesDropdown(false)}
              >
                {industryLinks.map((industry, index) => (
                  <a
                    key={index}
                    className="block text-[1.375rem] poppins-regular px-4 py-2 hover:bg-blue-800 transition-colors duration-200 cursor-pointer select-none"
                    onClick={() =>
                      handleNavigation(industry.path, setIndustriesDropdown)
                    }
                  >
                    {industry.title}
                  </a>
                ))}
              </div>
            )}
          </div>

          {/* Resources Dropdown - Desktop */}
          <div
            className="relative flex flex-col items-center lg:mx-[1.6rem] mx-[1rem] overflow-visible"
            onMouseEnter={() => setResourcesDropdown(true)}
            onMouseLeave={() => setResourcesDropdown(false)}
          >
            <button
              className={`text-[1.6rem] nav-link flex items-center gap-2 select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem]`}
            >
              Resources
              <FaChevronDown className="text-lg" />
            </button>
            <div
              className={`w-full h-[0.2rem] rounded-[.3rem] bg-[#00D47E] transition-all duration-200 ${
                resourcesDropdown || isOnResourcesPage
                  ? "opacity-100"
                  : "opacity-0"
              }`}
            ></div>
            {resourcesDropdown && (
              <div className="absolute top-full mt-0 w-[18rem] bg-transparent h-2"></div>
            )}

            {resourcesDropdown && (
              <div
                className="absolute top-full mt-2 w-[18rem] bg-blue-950 text-white shadow-lg select-none"
                onMouseEnter={() => setResourcesDropdown(true)}
                onMouseLeave={() => setResourcesDropdown(false)}
              >
                <a
                  className="block text-[1.375rem] poppins-regular px-4 py-2 hover:bg-blue-800 transition-colors duration-200 cursor-pointer select-none"
                  onClick={() =>
                    handleNavigation("/blog-section", setResourcesDropdown)
                  }
                >
                  Blogs
                </a>
                <a
                  className="block text-[1.375rem] poppins-regular px-4 py-2 hover:bg-blue-800 transition-colors duration-200 cursor-pointer select-none"
                  onClick={() =>
                    handleNavigation("/case-studies", setResourcesDropdown)
                  }
                >
                  Case Studies
                </a>
              </div>
            )}
          </div>

          {/* Career Link - Desktop (now a direct link without dropdown) */}
          <div className="flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]">
            <a
              className={`${
                navLinks[1].active ? "poppins-bold" : ""
              } text-[1.6rem] nav-link select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem] cursor-pointer`}
              onClick={() => handleNavigation(navLinks[1].path)}
            >
              {navLinks[1].title}
            </a>
            {navLinks[1].active && (
              <div className="w-full h-[0.2rem] rounded-[.3rem] bg-[#00D47E]"></div>
            )}
          </div>

          {/* About Us Link */}
          <div className="flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]">
            <a
              className={`${
                navLinks[2].active ? "poppins-bold" : ""
              } text-[1.6rem] nav-link select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem] cursor-pointer`}
              onClick={() => handleNavigation(navLinks[2].path)}
            >
              {navLinks[2].title}
            </a>
            {navLinks[2].active && (
              <div className="w-full h-[0.2rem] rounded-[.3rem] bg-[#00D47E]"></div>
            )}
          </div>

          {/* Contact Us Link */}
          <div className="flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]">
            <a
              className={`${
                navLinks[3].active ? "poppins-bold" : ""
              } text-[1.6rem] nav-link select-none ${
                isContactUs && !isScrolled ? "text-black" : "text-white"
              } mb-[.4rem] cursor-pointer`}
              onClick={() => handleNavigation(navLinks[3].path)}
            >
              {navLinks[3].title}
            </a>
            {navLinks[3].active && (
              <div className="w-full h-[0.2rem] rounded-[.3rem] bg-[#EA4335]"></div>
            )}
          </div>
        </div>

        {/* Desktop Get Started Button - Hide on specific devices */}
        <div className={`${mobileMenuActive ? "hidden" : "hidden md:block"}`}>
          {renderNavBtn()}
        </div>

        {/* Mobile & Tablet Menu Button and Get Started */}
        <div
          className={`flex ${
            !mobileMenuActive ? "md:hidden" : ""
          } justify-end items-center`}
        >
          {renderNavBtn()}
          <button
            aria-label="Toggle mobile menu"
            className="ml-[1.2rem] p-2"
            onClick={() => {
              if (navbarDropdown) {
                setMobileIndustriesDropdown(false);
                setMobileServicesDropdown(false);
                setMobileResourcesDropdown(false);
              }

              setNavbarDropdown(!navbarDropdown);
            }}
          >
            {navbarDropdown ? (
              <CgClose
                size={24}
                color={isContactUs && !isScrolled ? "#000000" : "#ffffff"}
              />
            ) : (
              <HiMenu
                size={24}
                color={isContactUs && !isScrolled ? "#000000" : "#ffffff"}
              />
            )}
          </button>
        </div>

        {/* Mobile & Tablet Navigation Dropdown */}
        {navbarDropdown && (
          <div
            className={`${
              !mobileMenuActive ? "md:hidden" : ""
            }  absolute right-0 top-32 w-80 bg-[#11214D] shadow-lg z-50 mobile-menu`}
          >
            {/* Home Link */}
            <div className="py-3 pl-6">
              <a
                className="text-[1.8rem] font-medium block text-white hover:text-green-400 transition-all cursor-pointer select-none"
                onClick={() => handleNavigation(navLinks[0].path)}
              >
                Home
              </a>
              {navLinks[0].active && (
                <div className="h-0.5 bg-green-400 w-24 mt-1"></div>
              )}
            </div>

            {/* Services Dropdown */}
            <div className="py-3 pl-6">
              <button
                className="text-[1.8rem] font-medium w-full text-left text-white flex items-center justify-between pr-4 select-none"
                onClick={() => {
                  setMobileServicesDropdown((prev) => !prev);
                  setMobileIndustriesDropdown(false);
                  setMobileResourcesDropdown(false);
                }}
              >
                Services <FaChevronDown className="text-white text-[1.8rem]" />
              </button>
              {isOnServicePage && (
                <div className="h-0.5 bg-green-400 w-32 mt-1"></div>
              )}
              {mobileServicesDropdown && (
                <div className="mt-2 border border-white rounded-md bg-[#11214D] p-2 shadow-lg">
                  {servicesLinks.map((service, index) => (
                    <a
                      key={index}
                      className="block text-[1.5rem] py-2 pl-4 text-white hover:text-green-400 cursor-pointer select-none"
                      onClick={() => handleNavigation(service.path)}
                    >
                      {service.title}
                    </a>
                  ))}
                </div>
              )}
            </div>

            {/* Industries Dropdown */}
            <div className="py-3 pl-6">
              <button
                className="text-[1.8rem] font-medium w-full text-left text-white flex items-center justify-between pr-4 select-none"
                onClick={() => {
                  setMobileIndustriesDropdown((prev) => !prev);
                  setMobileServicesDropdown(false);
                  setMobileResourcesDropdown(false);
                }}
              >
                Industries{" "}
                <FaChevronDown className="text-white text-[1.8rem]" />
              </button>
              {isOnIndustriesPage && (
                <div className="h-0.5 bg-green-400 w-36 mt-1"></div>
              )}
              {mobileIndustriesDropdown && (
                <div className="mt-2 border border-white rounded-md bg-[#11214D] p-2 shadow-lg">
                  {industryLinks.map((industry, index) => (
                    <a
                      key={index}
                      className="block text-[1.5rem] py-2 pl-4 text-white hover:text-green-400 cursor-pointer select-none"
                      onClick={() => handleNavigation(industry.path)}
                    >
                      {industry.title}
                    </a>
                  ))}
                </div>
              )}
            </div>

            {/* Resources Dropdown */}
            <div className="py-3 pl-6">
              <button
                className="text-[1.8rem] font-medium w-full text-left text-white flex items-center justify-between pr-4 select-none"
                onClick={() => {
                  setMobileResourcesDropdown((prev) => !prev);
                  setMobileIndustriesDropdown(false);
                  setMobileServicesDropdown(false);
                }}
              >
                Resources <FaChevronDown className="text-white text-[1.8rem]" />
              </button>
              {isOnResourcesPage && (
                <div className="h-0.5 bg-green-400 w-40 mt-1"></div>
              )}
              {mobileResourcesDropdown && (
                <div className="mt-2 border border-white rounded-md bg-[#11214D] p-2 shadow-lg">
                  <a
                    className="block text-[1.5rem] py-2 pl-4 text-white hover:text-green-400 cursor-pointer select-none"
                    onClick={() => handleNavigation("/blog-section")}
                  >
                    Blogs
                  </a>
                  <a
                    className="block text-[1.5rem] py-2 pl-4 text-white hover:text-green-400 cursor-pointer select-none"
                    onClick={() => handleNavigation("/case-studies")}
                  >
                    Case Studies
                  </a>
                </div>
              )}
            </div>

            {/* Career Link */}
            <div className="py-3 pl-6">
              <a
                className="text-[1.8rem] font-medium block text-white hover:text-green-400 transition-all cursor-pointer select-none"
                onClick={() => handleNavigation(navLinks[1].path)}
              >
                Career
              </a>
              {navLinks[1].active && (
                <div className="h-0.5 bg-green-400 w-24 mt-1"></div>
              )}
            </div>

            {/* About Us Link */}
            <div className="py-3 pl-6">
              <a
                className="text-[1.8rem] font-medium block text-white hover:text-green-400 transition-all cursor-pointer select-none"
                onClick={() => handleNavigation(navLinks[2].path)}
              >
                About Us
              </a>
              {navLinks[2].active && (
                <div className="h-0.5 bg-green-400 w-32 mt-1"></div>
              )}
            </div>

            {/* Contact Us Link */}
            <div className="py-3 pl-6">
              <a
                className="text-[1.8rem] font-medium block text-white hover:text-green-400 transition-all cursor-pointer select-none"
                onClick={() => handleNavigation(navLinks[3].path)}
              >
                Contact Us
              </a>
              {navLinks[3].active && (
                <div className="h-0.5 bg-green-400 w-40 mt-1"></div>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
