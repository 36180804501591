import React, { useState, useRef, useEffect } from "react";
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import worldMap from "../../../static/assets/img/GetInTouch/Map-icon.png";
import ReCAPTCHA from "react-google-recaptcha";
import { contactUs } from "../../../Store/actions/contactUsActions";
import { useDispatch } from "react-redux";
import Footer from "../FooterSec/Footer";

const GetInTouch = () => {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({
    file: null,
    name: "",
    email: "",
    company: "",
    appBudget: "",
    projectDetails: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [progress, setProgress] = useState(100);
  const toastDuration = 3000;
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");

  const recaptcha = useRef(null);

  useEffect(() => {
    let interval;
    if (showToast) {
      setProgress(100);
      const stepTime = 10;
      const steps = toastDuration / stepTime;
      const decrementValue = 100 / steps;

      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress - decrementValue;
          return newProgress > 0 ? newProgress : 0;
        });
      }, stepTime);

      const timeout = setTimeout(() => {
        setShowToast(false);
      }, toastDuration);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [showToast]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const selectedFile = files[0];
      if (selectedFile) {
        // Validate file size (max 5MB)
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        if (selectedFile.size > maxSize) {
          setFileError("File size exceeds 5MB limit");
          return;
        }

        // Validate file type
        const allowedTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "image/jpeg",
          "image/png",
        ];
        if (!allowedTypes.includes(selectedFile.type)) {
          setFileError("Only PDF, DOC, DOCX, JPG, and PNG files are allowed");
          return;
        }

        // Clear any previous errors
        setFileError("");
        setFileName(selectedFile.name);

        // Update state with file
        setContactData((prevData) => ({
          ...prevData,
          [name]: selectedFile,
        }));

        // Show success toast
        showNotification(`uploaded successfully`);
      }
    } else {
      setContactData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const showNotification = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptcha.current.getValue();
    if (!recaptchaValue) {
      showNotification("Please complete the reCAPTCHA");
      return;
    }

    const formData = new FormData();
    if (contactData.file) {
      formData.append("file", contactData.file);
    }
    formData.append("email", contactData.email);
    formData.append("fullName", contactData.name);
    formData.append("company", contactData.company);
    formData.append("appBudget", contactData.appBudget);
    formData.append("projectDetails", contactData.projectDetails);
    formData.append("reCaptcha", recaptchaValue);

    try {
      dispatch(contactUs(formData));
      showNotification("Sent Successfully");

      setContactData({
        file: null,
        name: "",
        email: "",
        company: "",
        appBudget: "",
        projectDetails: "",
      });
      setFileName("");
      recaptcha.current.reset();
    } catch (error) {
      showNotification("Failed to send your message. Please try again.");
    }
  };

  const removeFile = () => {
    setContactData((prev) => ({ ...prev, file: null }));
    setFileName("");
    setFileError("");
  };

  return (
    <div className="bg-white w-full min-h-screen pt-48 font-poppins">
      {/* Container aligned with navbar */}
      <div className="max-w-[1440px] mx-auto px-4 md:px-6 w-full">
        <div className="w-full bg-white">
          <ReusableHeadingSection
            heading="Get In Touch With Our Team"
            subtext={
              <>
                We have the team & Know-how to help you scale 10x
                <br /> faster
              </>
            }
            headingSize="text-[3.75rem] lg:text-[6rem] xl:text-[6rem] "
            subtextSize="text-[1.5625rem] lg:text-[2.5rem] xl:text-[2.5rem] mt-2"
            textColor="text-gray-800"
            subtextColor="text-[#8A929A] font-normal"
            align="text-center"
          />
        </div>
      </div>

      {/* Map section with proper full-width appearance but still contained within layout */}
      <div className="w-full mt-20">
        <div className="max-w-[1440px] mx-auto px-4 md:px-6 lg:px-8">
          <img
            src={worldMap}
            alt="World Map"
            className="max-width:100% h-auto object-contain"
          />
        </div>
      </div>

      {/* Form section */}
      <div className="max-w-[1440px] mx-auto px-4 md:px-6 w-full">
        <section className="bg-[#FFFFFF] py-0 mt-8">
          <div className="w-full bg-[#F2F4F4] p-4 md:p-8 rounded-[1.5rem] ">
            <h2 className="text-[2rem] xl:text-[2.4rem] poppins-semibold mt-6 md:mt-12 mb-4 md:mb-14 text-center">
              Write Us a few words about your project and we'll Prepare a
              proposal for you within 24 Hours.
            </h2>
            <form
              className="grid grid-cols-1 md:grid-cols-2 gap-14"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                name="name"
                className="w-full p-6 poppins-medium text-[1.8rem]"
                placeholder="Your Name"
                value={contactData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                className="w-full p-6 poppins-medium text-[1.8rem]"
                placeholder="Your Email"
                value={contactData.email}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="company"
                className="w-full p-6 poppins-medium text-[1.8rem]"
                placeholder="Company"
                value={contactData.company}
                onChange={handleChange}
              />
              <input
                type="text"
                name="appBudget"
                className="w-full p-6 poppins-medium text-[1.8rem]"
                placeholder="Approx. Budget"
                value={contactData.appBudget}
                onChange={handleChange}
              />

              <div className="md:col-span-2">
                <textarea
                  name="projectDetails"
                  rows="1"
                  className="w-full p-6 poppins-medium text-[1.8rem]"
                  placeholder="Project Details"
                  value={contactData.projectDetails}
                  onChange={handleChange}
                />
              </div>

              <div className="md:col-span-2 flex flex-wrap md:flex-nowrap items-center">
                {/* File Upload - Left */}
                <div className="w-full md:w-1/4 bg-white p-4 mb-4 md:mb-0">
                  <div
                    className={`w-full flex border-[.1rem] rounded-[1rem] border-dashed ${
                      fileError ? "border-red-500" : "border-[#878791]"
                    } text-gray-500 text-center cursor-pointer`}
                  >
                    <label
                      htmlFor="file-upload"
                      className="w-full flex items-center p-3 cursor-pointer"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        role="img"
                        viewBox="0 0 24 24"
                        className="text-[6rem] text-[#4285F4] ml-[-0.5rem]"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14.727 6.727H14V0H4.91c-.905 0-1.637.732-1.637 1.636v20.728c0 .904.732 1.636 1.636 1.636h14.182c.904 0 1.636-.732 1.636-1.636V6.727h-6zm-.545 10.455H7.09v-1.364h7.09v1.364zm2.727-3.273H7.091v-1.364h9.818v1.364zm0-3.273H7.091V9.273h9.818v1.363zM14.727 6h6l-6-6v6z"></path>
                      </svg>
                      <span className="text-[#878791] poppins-medium text-[1.8rem] ml-2">
                        {fileName
                          ? fileName.substring(0, 15) +
                            (fileName.length > 15 ? "..." : "")
                          : "Drop it like it's hot"}
                      </span>
                      {fileName && (
                        <button
                          type="button"
                          onClick={removeFile}
                          className="ml-4 text-[2rem] text-red-500 hover:text-red-700"
                        >
                          ✕
                        </button>
                      )}
                    </label>
                    <input
                      id="file-upload"
                      name="file"
                      type="file"
                      className="hidden"
                      onChange={handleChange}
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                    />
                  </div>
                  {fileError && (
                    <p className="text-red-500 text-[1.4rem] mt-2">
                      {fileError}
                    </p>
                  )}
                  <p className="text-gray-500 text-[1.2rem] mt-2">
                    Max size: 5MB. Allowed formats: PDF, DOC, DOCX, JPG, PNG
                  </p>
                </div>

                {/* reCAPTCHA - Center */}
                <div className="w-full md:w-2/4 flex justify-center items-center mb-4 md:mb-0">
                  <ReCAPTCHA
                    sitekey={"6Ld-o9IqAAAAAIz_LyeIAl4WiFYaW56Z8e6QFKfK"}
                    ref={recaptcha}
                  />
                </div>

                {/* Submit Button - Right */}
                <div className="w-full md:w-1/4 flex justify-center md:justify-end items-center">
                  <button
                    type="submit"
                    className="w-[300px] md:w-[250px] h-[6.9rem] bg-[#F2F4F4] border-2 border-[#00D47E] text-black text-[1.6rem] rounded-[0.8rem] py-3 poppins-medium transition duration-300 hover:bg-[#00D47E] hover:text-white"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
            <p className="text-[#000000] mt-[4rem] text-[1.6rem] xl:text-[2.2rem] text-center poppins-regular">
              If you need to have a DNA first, just contact us at{" "}
              <span className="text-[#14174E] poppins-bold">
                hello@infominez.com
              </span>
            </p>
          </div>
        </section>
      </div>

      {/* Custom Toast Notification */}
      {showToast && (
        <div className="fixed top-44 right-10 max-w-md w-[400px] bg-white rounded-lg shadow-2xl overflow-hidden z-50">
          <div className="px-6 py-4 flex items-center relative">
            <button
              onClick={() => setShowToast(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <div className="bg-green-500 p-2 rounded-full mr-4">
              <svg
                className="w-7 h-7 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>

            <p className="text-gray-700 text-[1.375rem] poppins-medium">
              {toastMessage}
            </p>
          </div>

          <div className="bg-gray-200 h-2 w-full">
            <div
              className="bg-green-500 h-2 transition-all duration-300 ease-in-out"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
};

export default GetInTouch;
