import React from 'react'
import { serviceImg } from '../../../static/constants/imageConstant'
import CaseStudy from '../../common/Cards/CaseStudy'
import CloudServiceDetails from '../../common/ServiceCommons/CloudCommons/CloudServiceDetails'
import CloudServiceTransformation from '../../common/ServiceCommons/CloudCommons/CloudServiceTransformation'
import ServiceHeading from '../../common/ServiceCommons/ServiceHeading'
import ServiceInclude from '../../common/ServiceCommons/ServiceInclude'
import ClientTestimonialSec from '../ClientTestimonialSec/ClientTestimonialSec'
import Footer from '../FooterSec/Footer'
import LatestInsightSec from '../LatestInsightSec/LatestInsightSec'
import ReadyToGetStartSec from '../ReadyToGetStartSec/ReadyToGetStartSec'

const bgColor="bg-white";
const showcloudButton = "visible";

const serviceHeading = "Empower Your Business with End-to-End Cloud Solutions";

const serviceLineOne = "Unlock innovation and scalability with Infominez's comprehensive cloud engineering services. We design, develop, and manage cloud solutions that propel your business toward digital excellence.";

const includeHeading = "Why Partner with Infominez for";
const includeHeadingTwo = "End-to-End Success";

const cloudHeadingOne = "Why Partner with Infominez for End-to-End Success? Your trusted partner for cloud excellence.";

const cloudHeadingTwo = "Our Cloud Engineering Services Tailored solutions for every stage of your cloud journey.";

const cloudHeadingThree = "How Cloud Excellence is Your Pathway to Success  secure, Accelerate your business transformation with scalable, secure, and cost-effective cloud solutions.";

const cloudHeadingFour = "Advantages with Us Infominez Your Strategic Cloud Partner";

const cloudSubHeadingOne = "What Sets Us Apart";
const cloudSubHeadingThree = "Benefits of Our Cloud Solutions";
const cloudSubHeadingFour = "Here’s why we’re the best partner for your cloud transformation";

const cloudHeadingDetailOne = "When you choose Infominez, you’re choosing a cloud engineering partner that prioritizes innovation, scalability, and security. We work with businesses across industries—including Banking, FinTech, EduTech, Retail, and more—delivering tailored solutions designed to meet your specific business needs.";

const cloudHeadingDetailTwo = "At Infominez, we offer a full range of cloud engineering services designed to drive your business’s success. From infrastructure development to security and compliance, we’ve got you covered.";

const cloudHeadingDetailThree = "Our cloud services are designed to deliver agility, innovation, and cost efficiency. Whether you’re in Banking, FinTech, Retail, EduTech, or another industry, our cloud-first approach ensures that your business stays competitive in today’s digital world.";

const cloudHeadingDetailFour = "With years of experience, Infominez brings unmatched expertise to your cloud projects. Our team of cloud engineers and consultants works hand-in-hand with your business to ensure success at every stage of the journey.";

const includeImg = serviceImg.CloudPartnerImg;

const include = [
    {
        title:"Expertise and Experience",
        detailOne:"Leverage our extensive industry knowledge to ensure project",
        detailTwo:"success and minimize risks.",
    },
    {
        title:"Cost-Effective Solutions",
        detailOne:"Benefit from efficient development and competitive pricing",
        detailTwo:"that align with your budget and goals.",
    },
    {
        title:"Innovative Solutions",
        detailOne:"Access proven technologies and innovative strategies that",
        detailTwo:"help keep your product competitive and future-ready.",
    },
    {
        title:"Adaptive Project Delivery",
        detailOne:"Experience agile project management that quickly adapts to",
        detailTwo:"evolving requirements while staying on time and within budget.",
    },
    {
        title:"Collaboration & Transparency",
        detailOne:"Enjoy open communication and a collaborative approach that",
        detailTwo:"keeps your vision at the forefront.",
    }
]

const cloudDetailsOne = [
    {
      title:"Industry Expertise",
      detailOne:"Our deep knowledge across multiple sectors ensures that our solutions are highly customized for your unique challenges.",
    },
    {
      title:"Pioneers in Technology",
      detailOne:"We’re always ahead of the curve, leveraging the latest cloud technologies to drive innovation.", 
    },
    {
      title:"Security-First Approach",
      detailOne:"We embed advanced security protocols in every solution to safeguard your data.", 
    },
    {
      title:"Scalability and Agility",
      detailOne:"Our cloud infrastructures are designed to grow with your business, ensuring peak performance as you scale.", 
    },
    {
      title:"24/7 Support",
      detailOne:"Our dedicated support team is available around the clock to keep your cloud operations running smoothly.",
    },
    {
      title:"Scalability and Agility",
      detailOne:"Our cloud infrastructures are designed to grow with your business, ensuring peak performance as you scale.",
    }, 
]

const cloudDetailsTwo = [
    {
        title:"Cloud Solution Design",
        detailOne:"We architect custom cloud solutions that align with your business goals, delivering efficiency, performance, and cost savings.",
    },
    {
        title:"Cloud Infrastructure Development",
        detailOne:"Our team builds robust, scalable cloud infrastructures res that evolve with your business, ensuring smooth operations and minimal downtime.",
    },
    {
        title:"Cloud Migration and Integration",
        detailOne:"We seamlessly transition your business to the cloud with minimal disruption, ensuring seamless integration with existing systems.",
        detailTwo:"",
    },
    {
        title:"DevOps and Cloud Automation",
        detailOne:"Automate development, testing, and deployment with our cloud-powered DevOps solutions, allowing your business to innovate faster.",
    },
    {
        title:"Cloud Security and Compliance",
        detailOne:"Our security experts ensure that your cloud environment is protected with state-of-the-art security measures and compliance with industry regulations.",
    },
]

const cloudDetailsThree = [
    {
        title:"Optimized Performance",
        detailOne:"Ensure seamless, high-performance operations with our cloud technologies.",
    },
    {
        title:"Agility and Flexibility",
        detailOne:"Innovate faster and bring new products to market with our flexible cloud infrastructures.",
    },
    {
        title:"Cost-Effective",
        detailOne:"Save on infrastructure costs with optimized cloud solutions.",
    },
    {
        title:"Enhanced Security",
        detailOne:"Protect critical business data with enterprise-grade cloud security.",
    },
    {
        title:"Scalable Solutions",
        detailOne:"Adapt to your business’s growing needs without limitations.",
    },
    
]

const cloudDetailsFour = [
    {
        title:"Proven Industry Experience",
        detailOne:"We’ve successfully delivered cloud solutions across diverse industries.",
    },
    {
        title:"Tailored Cloud Solutions",
        detailOne:"Every solution is custom-built to fit your business’s unique needs.",
    },
    {
        title:"Cutting-Edge Technology",
        detailOne:"We leverage the latest advancements in cloud technology to give your business a competitive edge.",
    },
    {
        title:"Dedicated 24/7 Support",
        detailOne:"Our support team is always available to resolve issues and keep your systems running smoothly.",
    },
    {
        title:"Commitment to Security",
        detailOne:"We prioritize security from day one, ensuring your business is protected.",
    },
]

function CloudEngineeringService() 
{
  return (
    <div>
        <ServiceHeading serviceHeading={serviceHeading} serviceLineOne={serviceLineOne}/>
        <CloudServiceTransformation id="transformation"/>
        <div className="h-[8rem]"></div>

        <CloudServiceDetails cloudHeading={cloudHeadingOne} cloudHeadingDetail={cloudHeadingDetailOne} 
        cloudSubHeading={cloudSubHeadingOne} cloudDetails={cloudDetailsOne} showCloudButton={showcloudButton} 
        classNames={"text-center"} buttonTitle={"See How We Can Help Your Business"} bgBack={"bg-[#F2F4F4]"}/>
        <div className="h-[8rem]"></div>

        <CloudServiceDetails cloudHeading={cloudHeadingTwo} cloudHeadingDetail={cloudHeadingDetailTwo} 
        cloudDetails={cloudDetailsTwo}/>

        <CloudServiceDetails cloudHeading={cloudHeadingThree} cloudHeadingDetail={cloudHeadingDetailThree} 
        cloudSubHeading={cloudSubHeadingThree} cloudDetails={cloudDetailsThree} bgColor={"bg-white"} 
        showCloudButton={showcloudButton} classNames={"text-left ml:24 md:ml-[14rem]"} 
        textSize={"text-[2.4rem] md:text-[3rem]"} buttonTitle={"Start Transforming Your Business Today"}/>
        
        <CloudServiceDetails cloudHeading={cloudHeadingFour} cloudHeadingDetail={cloudHeadingDetailFour}
         cloudSubHeading={cloudSubHeadingFour} cloudDetails={cloudDetailsFour} 
         classNames={"text-left ml:24 md:ml-56"} textSize={"text-[2.4rem] md:text-[3rem]"}/>
        <div className="h-[8rem]"></div>

        <ServiceInclude include={include} includeHeading={includeHeading} 
        includeHeadingTwo={includeHeadingTwo} includeImg={includeImg} w={"lg:w-[70rem]"}/>
        <CaseStudy bgColor={"bg-white"}/>
        <LatestInsightSec/>
        <ClientTestimonialSec/>
        <ReadyToGetStartSec id="ready" bgColor={bgColor}/>
        <Footer/>
    </div>
  )
}

export default CloudEngineeringService; 