import { actionTypes } from "../types";
 
export const aboutUs = (payload, callback) => ({
    type:actionTypes.ABOUT_US_REQUEST,
    payload,
    callback
});
 
export const aboutUsSuccess = (payload) => ({
    type:actionTypes.ABOUT_US_SUCCESS,
    payload,
});
 
export const aboutUsFailure = (payload) => ({
    type:actionTypes.ABOUT_US_FAILURE,
    payload,
});
 
export const aboutUsReset = (payload) => ({
    type:actionTypes.ABOUT_US_RESET,
    payload,
});
 
export const aboutUsResetAll = (payload) => ({
    type:actionTypes.ABOUT_US_RESET_ALL,
    payload,
});