import { call, put, takeLatest } from "redux-saga/effects";
import { imageFailure, imageSuccess } from "../actions/imageAction";
import { endpoints, strapi_base_url } from "../constants";
import { AxiosService } from "../services";
import { actionTypes } from "../types";

function* images({payload, callback})
{
    try {
        const response = yield call(AxiosService, 
        {
            method: 'get',
            base_url: strapi_base_url,
            endPoint: endpoints.employeeReviews + `?populate=*`,
            data: payload,
            isAuth: true,
        });

        yield put(imageSuccess({
            status: response?.status,
            data: response?.data?.data,
            message: response?.message
        })
        )
        if (callback) {
            callback(
                response?.status,
                response?.response,
                response?.message
            ); // Pass the response data to the callback
        }
    } catch (error) 
    {
        yield put(imageFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* imageSaga() 
{
    yield takeLatest(actionTypes.IMAGE_REQUEST, images);
}

export default imageSaga;