import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CaseStudyBackground from "../../../static/assets/img/ResourcesPage/CaseStudy.png";
import { caseStudy } from "../../../Store/actions/caseStudyAction";
import { strapi_base_url } from "../../../Store/constants";
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import Footer from "../FooterSec/Footer";
import ReadyToGetStartSec from "../ReadyToGetStartSec/ReadyToGetStartSec";

const CaseStudyListingSection = () => 
{
  //Redux API Calling
  const dispatch = useDispatch();
  const selectCaseStudies = useSelector((state) => state.caseStudyReducer.data);

  useEffect(()=>
  {
        dispatch(caseStudy());
  },[dispatch])

  // use Navigation
  const navigate = useNavigate();
  const handleNavigate = (e, id) => 
    {
    e.preventDefault();
    navigate(`/caseStudyDetails/${id}`);
  }
  
  //Date-Formatting
  function formatReadableDate(isoString) 
  {
    let date = new Date(isoString);
    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative w-full h-[30vh] md:h-[70vh] overflow-hidden bg-cover bg-center flex flex-col items-center justify-center text-center"
        style={{ backgroundImage: `url(${CaseStudyBackground})` }}
      >
        {/* Apply navbar structure with flex-col instead of justify-between */}
        <div className="nav-container flex flex-col max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
          {/* Hero content */}
          <div className="relative z-10 text-white w-full flex flex-col items-center text-center">
            <ReusableHeadingSection
              heading="Case Study"
              headingSize="text-[5.8rem] md:text-[6rem] lg:text-[6.5rem] xl:text-[8rem] 2xl:text-[12rem]"
              textColor="text-white poppins-normal"
              align="text-center"
              additionalClasses="mt-6"
            />
          </div>
        </div>
      </div>

      {/* Case Study Cards Section */}
      <div className="w-full py-24">
        {/* Apply navbar container structure */}
        <div className="nav-container flex flex-col max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
          {/* Two columns on medium (md) screens and above */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {
              selectCaseStudies.map((study, index) => (
              <div
                key={index}
                className="border border-[#E3E3E3] overflow-hidden w-full mx-auto bg-white"
              >
                <div className="p-6">
                  {/* Card Image */}
                  <img
                    src={strapi_base_url+"_img"+study?.attributes?.thumbnail_image?.data?.attributes?.url}
                    alt="image not found"
                    className="w-full h-auto object-cover"
                  />

                  {/* Card Content */}
                  <div className="p-4">
                    <h3 className="text-[2.8125rem] xl:text-[4rem]/[5.6rem] poppins-semibold mb-2 truncate-headText">{study?.attributes?.Title}</h3>
                    <p className="text-[#000000] poppins-regular text-[1.1875rem] xl:text-[1.8rem] mt-[2rem] mb-[3rem] truncate-text">{study?.attributes?.overview}</p>
                    <div className="flex justify-between items-center mt-4">
                      <a className="text-[#12C140] text-[1.125rem] xl:text-[1.6rem] underline poppins-medium cursor-pointer" onClick={(e) => handleNavigate(e, index)}>
                        Read More
                      </a>
                      <p className="text-[#535B63] text-[1.125rem] xl:text-[1.6rem] poppins-regular">{formatReadableDate(study?.attributes?.createdAt)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ReadyToGetStartSec/>
      <Footer/>
    </div>
  );
};

export default CaseStudyListingSection;