import React from "react";

const ServiceOfferings = ({ services = [] }) => {
  if (!services || services.length === 0) {
    return <p className="text-center text-gray-500">No services available</p>;
  }

  return (
    <div className="w-full bg-[#F2F4F4] text-center py-16">
      <div className="max-w-[1440px] mx-auto px-4 md:px-6 container-lg">
        {/* Title */}
        <h2 className="text-[3.75rem] xl:text-[6rem] poppins-normal text-gray-900 mb-16">
          Service Offerings
        </h2>

        {/* Service Grid */}
        <div className="relative grid grid-cols-1 md:grid-cols-3 gap-9 md:border-b border-gray-300">
          {services.slice(0, 3).map((service, index) => (
            <div
              key={index}
              className={`flex flex-col items-center text-center p-2 relative border-gray-300
                ${index < 2 ? "md:border-r" : ""}`}
            >
              <img src={service.icon} alt={service.title} className="w-12 h-12 mb-4" />
              <h3 className="text-[2rem] xl:text-[2.8rem] poppins-semibold text-[#3C436A]">{service.title}</h3>
              <p className="text-[#707F96] poppins-regular text-[1.8rem]  xl:text-[2.2rem] mt-4" style={{ height: 'auto', minHeight: '6rem', overflowWrap: 'break-word', wordWrap: 'break-word' }}>{service.description}</p>
            </div>
          ))}
        </div>

        {/* Second Row */}
        <div className="relative grid grid-cols-1 md:grid-cols-3 gap-9">
          {services.slice(3, 6).map((service, index) => (
            <div
              key={index}
              className={`flex flex-col items-center text-center p-2 relative border-gray-300
                ${index < 2 ? "md:border-r" : ""}`}
            >
              <img src={service.icon} alt={service.title} className="w-12 h-12 mt-4 mb-4" />
              <h3 className="text-[2rem] xl:text-[2.8rem] poppins-semibold text-[#3C436A]">{service.title}</h3>
              <p className="text-[#707F96] poppins-regular text-[1.8rem] xl:text-[2.2rem] mt-4" style={{ height: 'auto', minHeight: '6rem', overflowWrap: 'break-word', wordWrap: 'break-word' }}>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceOfferings;