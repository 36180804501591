import FooterOne from "../../common/FooterParts/FooterOne";
import FooterTwo from "../../common/FooterParts/FooterTwo";

export default function Footer({bgColor, lowerBgColor}) 
{
    return (
        <footer className="w-full">
          <div className={`text-white py-10 sm:px-5 ${bgColor ? bgColor : "bg-black"}`}>
            <div className="container-lg">
              <div className="max-w-screen-2xl">
                <FooterOne/>
                <FooterTwo/>
              </div>
            </div>
          </div>
          {/* Div distribution two div used */}
          <div className={`w-screen-2xl ${lowerBgColor ? lowerBgColor: "bg-[#14191C]"}`}>
            <div className="container-lg">
              <p className="text-center text-[#F5F5F5] text-[14px] sm:text-[16px] montserrat-medium py-10">©2025 INFOMINEZ All Rights Reserved.</p>
            </div>
          </div> 
        </footer>
      );
}
  