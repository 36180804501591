import React from 'react'
import { FaCircle } from 'react-icons/fa6'
import PrimaryButton from '../Buttons/PrimaryButton'

function ServicePartners({partner, partnerHeadingOne, partnerHeadingTwo, partnerImg, showPartnerButton, bgBack}) 
{
  return (
    <div className="h-fit bg-[#F2F4F4]">
      <div className="max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
          
          <div className="w-full poppins-normal text-[4rem] md:text-[4.5rem] lg:text-[5rem] justify-center mt-4 text-center">
            <p>{partnerHeadingOne}</p>
          </div>
          <div className="w-full poppins-normal text-[4rem] md:text-[4.5rem] lg:text-[5rem] justify-center -mt-4 md:-mt-6 lg:-mt-9 text-center">
            <p>{partnerHeadingTwo}</p>
          </div>

          <div className="flex flex-col lg:flex-row w-full h-fit mt-10 gap-6 lg:gap-10">
               <div className="my-6 md:my-10 lg:my-12 w-full lg:w-1/2 flex justify-center lg:justify-start">
                  <img src={partnerImg} className="w-full max-w-[110rem] lg:max-w-[75rem] 2xl:max-w-[100rem]"/>
               </div>
               <div className="mt-[2.5rem] space-y-5 w-full lg:w-1/2">
               {
                partner.map((val,ind)=>
                {
                  return(
                   <div className="flex flex-row gap-4 md:gap-8 items-start" key={ind}>
                    <FaCircle className="text-[#2666CF] mt-1 md:mt-3 size-4 md:size-6 shrink-0"/>
                     <div className="w-full">
                       <p className="poppins-semibold text-[2rem]/[2.7rem] sm:text-[2rem]/[2.7rem] md:text-[2.4rem]/[3.4rem] text-[#3c436a]">{val.title}</p>
                       <p className="poppins-regular text-[1.5rem]/[2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem] overflow-hidden text-[#8A929A] pt-2">{val.detailOne}</p>
                     </div>
                   </div>      
                )})
               }
            <div className="flex justify-center lg:justify-start lg:!mt-[6rem] lg:!mb-[2rem]">
              <PrimaryButton 
                title={"Discover How We Can Help You Succeed"} 
                fontWeight={"poppins-medium"}
                bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
                height="h-[3.6rem] md:h-[4.5rem] lg:h-[4.5rem]"  
                width="w-[90%] md:w-[50rem]"
                classNames={`${showPartnerButton ? showPartnerButton : "invisible"}`}
                onClick={() => {
                  document.getElementById("ready")?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
               </div>
            </div>
      </div>
      <div className="h-[4rem]"></div>
    </div>
  )
}

export default ServicePartners