import React from 'react'
import { footerLogo } from '../../../static/constants/imageConstant'

function FooterOne() 
{
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 border-b border-[#FFFFFF] mt-6">
  {/* Call Us Section */}
  <div className="flex items-center gap-2 sm:gap-3 xl:ml-[110px] lg:ml-[50px] md:ml-0 ml-36 border-b md:border-b-0 border-r border-[#FFFFFF] py-8 sm:py-14">
    <div className="sm:size-28 md:size-20 lg:size-28 size-20">
      <img src={footerLogo.callUs}/>
    </div>
    <div>
      <p className="text-white poppins-light pl-2 sm:text-[3rem] md:text-[2rem] lg:text-[2.4rem] text-[2.4rem]">Call Us 24/7</p>
      <p className="poppins-semibold sm:text-[3rem] lg:text-[2.3rem] md:text-[2rem] text-[2.2rem]">+91-98260-61259</p>
    </div> 
  </div>

  {/* Email Section */}
  <div className="flex items-center gap-2 sm:gap-3 xl:ml-[70px] lg:ml-[40px] md:ml-0 ml-36 md:text-wrap border-b md:border-b-0 border-r border-[#FFFFFF] py-8 sm:py-16">
    <div className="sm:size-28 md:size-20 lg:size-28 size-20 md:ml-4">
      <img src={footerLogo.mailUs} />
    </div>
    <div>
      <p className="text-white poppins-light pl-2 sm:text-[3rem] md:text-[2rem] lg:text-[2.4rem] text-[2.4rem]">Make a Quote</p>
      <p className="poppins-semibold sm:text-[3rem] lg:text-[2.3rem] md:text-[1.7rem] text-[2.2rem] pl-2">hello@infominez.com</p>
    </div>
  </div>

  {/* Location Section */}
  <div className="flex items-center gap-2 sm:gap-3 border-r border-[#FFFFFF] md:border-none xl:ml-[80px] lg:ml-[50px] md:ml-0 ml-36 py-8 sm:py-16">
    <div className="sm:size-28 md:size-24 lg:size-36 size-20 md:ml-4">
      <img src={footerLogo.locateUs} />
    </div>
    <div>
      <p className="text-white poppins-light pl-2 sm:text-[3rem] md:text-[2rem] lg:text-[2.4rem] text-[2.4rem]">Location</p>
      <p className="poppins-semibold sm:text-[3rem] lg:text-[2.3rem] md:text-[1.7rem] text-[2.2rem] pl-2">
      253, Sai Kripa Colony, Scheme No 171,<br />Indore - 452010
      </p>
    </div>
  </div>
</div>
  )
}

export default FooterOne
