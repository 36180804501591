import { actionTypes } from "../types";
 
export const blogs = (payload, callback) => ({
    type:actionTypes.GET_BLOGS_REQUEST,
    payload,
    callback
});
 
export const blogsSuccess = (payload) => ({
    type:actionTypes.GET_BLOGS_SUCCESS,
    payload,
});
 
export const blogsFailure = (payload) => ({
    type:actionTypes.GET_BLOGS_FAILURE,
    payload,
});
 
export const blogsReset = (payload) => ({
    type:actionTypes.GET_BLOGS_RESET,
    payload,
});
 
export const blogsResetAll = (payload) => ({
    type:actionTypes.GET_BLOGS_RESET_ALL,
    payload,
});