import React from 'react'
import { CarrerImg } from '../../../static/constants/imageConstant'

function InfominezLife() {
  return (
    <div className="h-fit">
    <div className="container-lg px-4 md:px-8 lg:px-16">
        <div className="text-black poppins-normal text-[3.75rem] xl:text-[5rem] text-center mt-10">
            <p>Life at Infominez</p>
        </div>

        <div className="flex flex-col lg:flex-row items-center lg:items-start mb-16">
            <div className="w-full lg:w-[90rem] mt-10">
                <img src={CarrerImg.lifeAtInfominez} className="w-full h-auto" />
            </div>
            <div className="bg-white w-full lg:w-[690px] poppins-medium text-[#707F96] text-[2rem] md:text-[2rem] h-auto lg:h-[350px] mt-10 lg:mt-44 relative lg:-ml-36 p-6 md:p-10 lg:p-0">
                <div className="lg:mt-28 lg:ml-20">
                    <p>At Infominez, we foster a culture of innovation, collaboration, and growth. 
                        Our team thrives in a dynamic environment where creativity is encouraged, 
                        ideas are valued, and every challenge is an opportunity to learn. Whether 
                        it’s through mentorship, hands-on projects, or upskilling, we are committed 
                        to helping each individual reach their full potential, making Infominez not 
                        just a workplace, but a community where talent grows and success flourishes.</p>
                </div>    
            </div>
        </div>
    </div>
</div>

  )
}

export default InfominezLife
