import React from "react";
import { Outlet } from "react-router-dom";

const ResourcePage = () => {
  
  <div>
      <h1>ResourcePage</h1>
      <Outlet />
       {/* ✅ Ye yahan nested routes render karega */}
  </div>
};

export default ResourcePage;
