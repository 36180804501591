import { actionTypes } from '../types';

const initialState = {
    strapiToken: 'Bearer 52dfecb37f1ed7d1b231081ce7929d64c68c8768ace44605bc7cdc7d56de544a685a56578ea8296fa17681c36044cc32c7878fc3dd58473b87289f62a993fc1c67231fa9349e988079dd81e2450118cc06114c6a259111854d8bd4336c970f5d2409685649d8571cdbfc5c600951db9df1104ffaf487c4f2776182f9a221e6ac',
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STRAPI_TOKEN:
            return {
                ...state,
                strapiToken: action.payload
            }
        default:
            return state;
    }
}