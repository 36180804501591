import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import aboutUsReducers from "./reducers/aboutUsReducers";
import authReducers from "./reducers/authReducers";
import blogReducer from "./reducers/blogReducers";
import caseStudyReducer from "./reducers/caseStudyReducer";
import contactUsReducers from "./reducers/contactUsReducers";
import imageReducer from "./reducers/imageReducer";
import technologiesReducer from "./reducers/TechnologiesReducer";
import utilReducers from "./reducers/utilReducers";

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        'utilReducers',
    ],
};

const appReducer = combineReducers({
    utilReducers: utilReducers,
    technologiesReducer:technologiesReducer,
    caseStudyReducer:caseStudyReducer,
    imageReducer:imageReducer,
    blogReducer:blogReducer,
    authReducers:authReducers,
    contactUsReducers:contactUsReducers,
    aboutUsReducers:aboutUsReducers,
})

const rootReducers = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return {
            ...state,
        }
    }

    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);
