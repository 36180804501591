import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { latestInsights } from '../../../static/constants/imageConstant';
import { blogs } from '../../../Store/actions/blogActions';
import { strapi_img_base_url } from '../../../Store/constants';
import RightArrowFull from '../../common/Buttons/RightArrowFull';
import MiniNavbar from '../../common/Navbar/MiniNavbar';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router';
const insights = [
  {
    id: 1,
    image: latestInsights.LatestOne,
    authorPhoto: latestInsights.authorPhotoOne,
    author: 'Rusty Welch',
    date: '23-July-2023',
    title: 'Leveraging AI And Digital Technology For Chronic Care Management',
  },
  {
    id: 2,
    image: latestInsights.LatestTwo,
    authorPhoto: latestInsights.authorPhotoOne,
    author: 'Rusty Welch',
    date: '23-July-2023',
    title: 'Leveraging AI And Digital Technology For Chronic Care Management',
  },
  {
    id: 3,
    image: latestInsights.LatestThree,
    authorPhoto: latestInsights.authorPhotoOne,
    author: 'Rusty Welch',
    date: '23-July-2023',
    title: 'Leveraging AI And Digital Technology For Chronic Care Management',
  }
];
 

function LatestInsightSec() 
{
  const dispatch = useDispatch();
  const blogCaseStudy = useSelector((state) => state.blogReducer.data)
  
  useEffect(()=>
  {
      dispatch(blogs())
  }, [dispatch]);

  const navigate = useNavigate();
  const handleNavigate = (e, id) => 
    {
    e.preventDefault();
    navigate(`/blogDetails/${id}`);
  }
  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  return (
    <div className="h-fit w-full bg-white poppins-normal py-8 sm:py-12 md:py-8">
      <div className="max-w-[1440px] mx-auto px-4 md:px-6 w-full overflow-hidden">
        {/* Title */}
        <p className="text-[3.75rem] xl:text-[6rem] text-center 
           text-gray-900 mt-4 sm:mt-10 md:mt-0">
          Read Our Latest Insights
        </p>

        {/* Mini Navbar */}
        <MiniNavbar />

        {/* Insights Section */}
        <div className="mt-6 sm:mt-20 grid 
            grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 
            gap-6 lg:gap-0 justify-center mb-6 sm:mb-16"
        >
          {blogCaseStudy?.map((val, i) => {
            return (
              <div 
                className="w-[95%] sm:w-[90%] md:w-[100%] lg:w-[95%] xl:max-w-[450px] 
                    h-auto lg:h-fit border border-[#E3E3E3] p-3 sm:p-4 mx-auto 
                    flex flex-col overflow-hidden"
                key={i}
              >
                {/* Image */}
                <img src={strapi_img_base_url + "/api_img"+val.attributes?.main_image?.data?.attributes.url} className="w-full h-[180px] sm:h-[220px] mx-auto object-cover" />

                {/* Author + Date */}
                <div className="flex justify-between items-center mt-3">
                  <div className="flex items-center gap-2 sm:gap-3">
                    <img src={strapi_img_base_url + "/api_img"+val.attributes?.author_image?.data?.attributes.url}
                     className="size-10 sm:size-14 rounded-full" />
                    <p className="text-[1.2rem]/[1.7rem] sm:text-[1.7rem]/[2.6rem] poppins-regular overflow-hidden">
                        {val.attributes.author_name}
                    </p>
                  </div>
                  <p className="text-[1.1rem]/[1.5rem] sm:text-[1.2rem]/[1.8rem]">{moment(val.attributes.createdAt).format("DD-MMM-YYYY")}</p>
                </div>

                {/* Title */}
                <p className="poppins-semibold my-[2rem] text-[2rem] sm:text-[2rem] md:text-[1.9rem]/[2.5rem] 2xl:text-[2rem]/[2.6rem] 
                    h-fit overflow-hidden">
                  {truncateText(val.attributes.sub_main_title, 100)}
                </p>

                {/* Read More Button */}
                <div className="flex items-center gap-2">
                    <a onClick={(e) => handleNavigate(e, i)}>
                      <RightArrowFull/>
                    </a>
                    <a onClick={(e) => handleNavigate(e, i)}
                    className="text-[#2666CF] text-[1.2rem]/[1.7rem] 
                    lg:text-[1.5rem]/[2.3rem] font-medium">
                      Read More
                    </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LatestInsightSec;