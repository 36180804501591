import React from "react";
import ExpertiseSection from "../components/section/ExpertiseSection/ExpertiseSection";
import Footer from "../components/section/FooterSec/Footer";
import LifeAtInfominez from "../components/section/LifeAtInfominez/LifeAtInfominez";
import VisionMission from "../components/section/MissionVision/VisionMission";
import ReadyToGetStartSec from "../components/section/ReadyToGetStartSec/ReadyToGetStartSec";
import StorySection from "../components/section/StorySection/StorySection";
import TeamSection from "../components/section/TeamSection/TeamSection";
import About from "../static/assets/img/AboutPage/AboutPage.png";

const AboutPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center">
      
      {/* Background Image Section with Overlay */}
      <div
        className="relative w-full h-[70vh] sm:h-[50vh] md:h-[60vh] lg:h-[70vh] xl:h-[65vh]  bg-cover bg-center"
        style={{ backgroundImage: `url(${About})` }}
      >
        <div className="container lg">
        {/* Overlay */}
        <div className="absolute inset-0 bg-blue-900 opacity-50"></div>
        </div>
        {/* "About Us" Heading exactly as original */}
        <div className="relative flex items-center justify-center h-full">
          <h1 className="text-[5.625rem] poppins-normal xl:text-[9rem]  text-[#FFFFFF] text-center">About Us</h1>
        </div>
  
      </div>
      <div className="container lg">
      {/* Content Section */}
      <div className="max-w-7.2xl mt-8 px-4 sm:px-8 lg:px-24 pb-12">
        <p className="text-[2rem] xl:text-[2.5rem] py-24 text-[#8A929A] poppins-medium text-center">
          At Infominez, we drive innovation and technology to create real business value.
          Our team of forward-thinking technologists is dedicated to transforming digital possibilities 
          into tangible outcomes. With a mission to empower businesses, we help them reach their full 
          digital potential and embrace fearless innovation.
        </p>
        </div>
      </div>
      <StorySection />
      <TeamSection />
      <ExpertiseSection />
      <VisionMission />
      <LifeAtInfominez />
      <ReadyToGetStartSec bgColor={"bg-white"} />
      <Footer/>
    </div>
  );
};

export default AboutPage;