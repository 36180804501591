import React from 'react'
import CarrerHeading from '../CarrerHeading/CarrerHeading'
import Footer from '../FooterSec/Footer'
import HearTeam from '../HearTeam/HearTeam'
import InfominezLife from '../InfominezLife/InfominezLife'
import OurCoreValues from '../OurCoreValues/OurCoreValues'
import WhyChoose from '../WhyChoose/WhyChoose'

function CarrerPage() {
  return (
    <div>
        <CarrerHeading/>
        <InfominezLife/>
        <OurCoreValues/>
        <HearTeam/>
        <WhyChoose/>
        <Footer/>
    </div>    
  )
}

export default CarrerPage
