import React from "react";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import AdvantageSection from "../../common/Industry_Reusable_Components/AdvantageSection";
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import ReusableParagraph from "../../common/Industry_Reusable_Components/ReusableParagraph";
import ServiceOfferings from "../../common/Industry_Reusable_Components/ServiceOfferings";
import { ValuePropositionGrid } from "../../common/Industry_Reusable_Components/ValuePropositionCard";

import FintechAndBanking from "../../../static/assets/img/IndustryPage/FintechAndBanking.png";
import IndustryBackground from "../../../static/assets/img/IndustryPage/IndustryBackground.png";
import image1 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/FintechImages/image1.png";
import image2 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/FintechImages/image2.png";
import image3 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/FintechImages/image3.png";
import image4 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/FintechImages/image4.png";
import image5 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/FintechImages/image5.png";
import image6 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/FintechImages/image6.png";

import analyticsIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/analyticsIcon.png";
import blockchainIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/blockchainIcon.png";
import complianceIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/compilanceIcon.png";
import fintechIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/fintechIcon.png";
import paymentIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/paymentIcon.png";
import securityIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/securityIcon.png";
import CaseStudy from "../../common/Cards/CaseStudy";
import ClientTestimonialSec from "../ClientTestimonialSec/ClientTestimonialSec";
import Footer from "../FooterSec/Footer";
const bgColor = "bg-white";
// Data for services
const servicesData = [
  {
    icon: fintechIcon,
    title: "Fintech Software Development",
    description:
      "From sleek mobile apps to robust web platforms and analytics, we build solutions that fit your vision.",
  },
  {
    icon: paymentIcon,
    title: "Payment Processing Solutions",
    description:
      "Seamless, secure, and scalable payment gateway integrations tailored to your business.",
  },
  {
    icon: blockchainIcon,
    title: "Blockchain & Cryptocurrency",
    description:
      "Stay at the forefront of the digital revolution with our blockchain and cryptocurrency services.",
  },
  {
    icon: complianceIcon,
    title: "Regulatory Compliance",
    description:
      "Navigate the complexities of legal requirements and industry standards with ease.",
  },
  {
    icon: analyticsIcon,
    title: "Data Analytics",
    description:
      "Unlock actionable insights from your financial data to inform better decisions.",
  },
  {
    icon: securityIcon,
    title: "Security & Fraud Prevention",
    description:
      "Protect your business with multi-layered security measures, from cooling periods to advanced authentication.",
  },
];

// Data for advantages
const advantageData = {
  title: "Advantages with Us",
  image: FintechAndBanking,
  advantages: [
    {
      title: "Industry Expertise",
      description:
        "Partner with a team that speaks your language and understands your challenges.",
    },
    {
      title: "Innovation",
      description:
        "Get ahead of the curve with groundbreaking technology and trends.",
    },
    {
      title: "Custom Solutions",
      description:
        "Receive solutions tailored specifically to your vision and goals.",
    },
    {
      title: "Security",
      description:
        "Rest easy knowing your financial data is protected with state-of-the-art security.",
    },
    {
      title: "Client-Centric Approach",
      description:
        "Your success is our success—expect a dedicated, collaborative partnership.",
    },
  ],
};

// Data for Fintech value propositions
const Fintech = [
  {
    icon: image1,
    title: "Expertise in Fintech",
    description:
      "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
  },
  {
    icon: image2,
    title: "Innovative Technology",
    description:
      "Harness the power of advanced technology to unlock new opportunities.",
  },
  {
    icon: image3,
    title: "Personalized Solutions",
    description:
      "Every solution is crafted to meet your business's unique needs and aspirations.",
  },
  {
    icon: image4,
    title: "Enhanced Security",
    description:
      "We go the extra mile to safeguard your data with cutting-edge security protocols.",
  },
  {
    icon: image5,
    title: "Collaborative Success",
    description:
      "We work hand-in-hand with you to achieve your business objectives, ensuring a seamless experience every step of the way.",
  },
  {
    icon: image6,
    title: "Client-Centric Approach",
    description:
      "Your success is our success—expect a dedicated, collaborative partnership.",
  },
];

const FintechComponent = () => {
  return (
    <>
      <div
        className="relative w-full h-[70vh] xl:h-[70vh] bg-cover bg-center flex flex-col items-center justify-center text-center px-6"
        style={{ backgroundImage: `url(${IndustryBackground})` }}
      >
        <div className="container-lg">
          <div className="absolute inset-0 bg-blue-900 opacity-50"></div>
          <div className="relative z-10 text-white w-full flex flex-col items-center text-center">
            <ReusableHeadingSection
              heading="Pioneering the Digital Finance Revolution"
              subtext="Experience the Future of Finance with Seamless, Secure, and Transformative Solutions"
              titleSize="text-[3.75rem] xl:text-[6rem]"
              subtextSize="text-[2.2rem] xl:text-[3.2rem]"
              textColor="text-white"
              subtextColor="text-white"
              align="text-center"
              additionalClasses="mt-6"
            />

            <div className="mt-10">
              <button
                onClick={() => {
                  document
                    .getElementById("advantages-section")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                class="
        flex items-center justify-center
        cursor-pointer
        border-2 rounded-full
        bg-inherit text-white hover:bg-[#00D47E] 
        w-[22rem] 
        border-[#00D47E] 
        text-white hover:text-[#0E0731]
        text-[1.6rem]
        poppins-medium
        h-[6rem]
    "
              >
                Learn More
                <div class="mx-[2rem]">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ReusableParagraph
        text="Infominez transforms financial challenges into opportunities, helping businesses of all sizes—startups, scaling companies, and established enterprises—achieve their goals. 
        Our expert-driven solutions are designed to enhance customer experiences, streamline operations, and fuel growth. 
        From fintech software development and payment processing to blockchain, regulatory compliance, data analytics, and robust security measures, we deliver the tools and 
        strategies you need to succeed. Partner with Infominez to stay ahead in the fast-evolving fintech landscape."
        textColor="text-gray-700"
        textSize="text-[1.5625rem] xl:text-[3rem]"
      />

      <AdvantageSection {...advantageData} id="advantages-section" />
      <ValuePropositionGrid items={Fintech} />
      <ServiceOfferings services={servicesData} />
      <CaseStudy bgColor={bgColor} />
      <ClientTestimonialSec />
      <Footer />
    </>
  );
};

export default FintechComponent;
