export const base_url = "https://infominez.com/api";

export const strapi_base_url = "https://dev.infominez.com/api"
export const strapi_img_base_url = "https://dev.infominez.com"

export const endpoints = {
    contactUsv1: "/aboutUsV2",
    technologies: "/technologies",
    caseStudies: "/case-studies",
    employeeReviews: "/employee-reviews",
    getBlogs: "/blogs",
    auth: "/auth/local",
    contactUs:"/contactUs",
    aboutUs:"/aboutUs",
}
  