import React from 'react'
import { TiTick } from 'react-icons/ti'

function KeyChallenges({title, content, image}) 
{
  return (
    <div className="h-fit w-full">
    {/* Exact same container as navbar */}
    <div className="nav-container max-w-[1440px] mx-auto container-lg py-[1.2rem] px-4 md:px-6 w-full overflow-visible">
      <div className="poppins-semibold text-center text-[2.5rem] sm:text-[2.7rem] xl:text-[3rem] my-20">
        <p>{title}</p>
      </div>

      {/* Mobile/Tablet image - only visible on small screens */}
      <div className="lg:hidden w-full mb-10">
        <img src={image} className="w-full object-cover" alt="Key challenges" />
      </div>

      <div className="flex flex-col lg:flex-row items-start gap-10">
        <div className="text-[#333840] text-[1.5rem] sm:text-[1.8rem] md:text-[2rem] poppins-regular
         flex flex-col gap-y-10 h-fit w-full lg:w-1/2">
          {
            content.map((val, index) => {
              return(
                <div key={index} className="flex gap-x-5">
                  <div className="flex-shrink-0">
                    <TiTick className="size-12 text-[#0478FF] md:size-16 sm:size-10" />
                  </div>
                  <div>
                    <p>{val}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        {/* Desktop image - only visible on large screens */}
        <div className="hidden lg:block w-full lg:w-1/2">
          <img src={image} className="w-full object-cover" alt="Key challenges" />
        </div>
      </div>
    </div>
  </div>
  )
}

export default KeyChallenges