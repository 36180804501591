import React from 'react';
import Marquee from "react-fast-marquee";
import { strapi_base_url } from '../../../Store/constants';

function TechMarquee({matchedTech}) 
{
  return (
    <>
      <div className="flex mb-10">
        <Marquee pauseOnHover="true" speed={120} play={matchedTech.length<4 ? false : true}>
        {
            matchedTech.map((val, ind) => 
            {
              return(
                <div className="h-fit w-fit px-[5rem]" key={ind}>
                  <img src={strapi_base_url+"_img"+val?.attributes?.logo?.data?.attributes?.url} className="h-90 w-80"/>
                </div>
              )
            })
          }
        </Marquee>
      </div>
    </>
  );
}

export default TechMarquee;