import React, { useRef } from 'react';
import { FaArrowLeft, FaArrowRight, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Client, NavbarImg } from '../../../static/constants/imageConstant';

const ClientTestimonial = () => 
{
  const testimonials = [
    {
      image: Client.clientA,
      name: "SHAIBU CHERIAN",
      position: "Whole Time Director at Paul Merchants Finance",
      testimonial: "Infominez has been an invaluable strategic technology partner for Paul Merchants, guiding us through the complex landscape of digital financial services. Their expertise in developing robust solutions for Prepaid Instruments, Pre-Paid Cards, BBPS, and UPI, along with a deep understanding of integration protocols and payment gateways, has been crucial to our success. Their strong grasp of the Indian fintech ecosystem has enabled them to deliver innovative and compliant solutions tailored to our needs. Moreover, Infominez's commitment to agile development methodologies and their ability to adapt swiftly to evolving regulatory requirements have been indispensable to our business. We highly value our partnership and look forward to continued collaboration.",
      linkedinLink: "https://www.linkedin.com/in/shaibu-cherian-6a098019",
      twitterLink: "https://x.com/shaibugc?s=21",
      shortTestimonial: "Infominez has been an invaluable strategic technology partner for Paul Merchants, guiding us..."
    },
    {
      image: Client.clientB,
      name: "Sonali Bansal",
      position: "Co-Founder at Maaya Money",
      testimonial: "We are excited to share our journey with Infominez, a key player in shaping Mayaa Money's robust application architecture and ensuring efficient delivery, surpassing our expectations. Infominez implemented a Microservices architecture for Mayaa Money's backend system, ensuring scalability, modularity, performance, flexibility, and security. Their introduction of cutting-edge features seamlessly handles pending transactions, greatly enhancing the reliability and efficiency of our payment processing system while minimising operational hassles. In summary, collaborating with Infominez has revolutionized our business. Their professionalism, expertise, and dedication have far exceeded our expectations, and we eagerly anticipate continuing our partnership on future project.",
      linkedinLink: "https://www.linkedin.com/in/iamsonalibansal/",
      twitterLink: "https://x.com/bansal_sonali?s=21",
      shortTestimonial: "We are excited to share our journey with Infominez, a key player in shaping Mayaa Money's..."
    },
    {
      image: Client.clientC,
      name: "Manohar Bhoi",
      position: "COO, EPS (Electronic Payment And Services Pvt. Ltd.)",
      testimonial: "Collaborating with Infominez on a range of projects, such as incident management systems and mobile applications, has been an exceptional experience. Their team consistently demonstrated a high level of expertise and dedication, delivering a comprehensive suite of services that addressed our diverse needs. From enhancing user interfaces to developing specialized modules, Infominez provided solutions that were both innovative and effective. Their meticulous approach and commitment to quality ensured that every project was executed with precision and efficiency. The team's professionalism, expertise, and willingness to assist at all times made the process run smoothly and successfully. I am highly impressed with their work and look forward to continuing our partnership in future collaborations.",
      linkedinLink: "https://www.linkedin.com/in/manohar-bhoi-7609361a8/",
      twitterLink: "https://x.com/manoharbhoi14?s=21",
      shortTestimonial: "Collaborating with Infominez on a range of projects, such as incident management systems and..."
    }
  ];

    const sliderRef = useRef();

    const PrevArrow = (props) => {
        const {className, onClick} = props;
        return (
            <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`}
                 onClick={onClick}>
                <button
                    className="w-[5rem] h-[5rem] rounded-full border-[2px] border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
                    <FaArrowLeft size={20} className=''/>
                </button>
            </div>
        );
    };

    const NextArrow = (props) => {
        const {className, onClick} = props;
        return (
            <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`}
                 onClick={onClick}>
                <button
                    className="w-[5rem] h-[5rem] rounded-full border-[2px] border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
                    <FaArrowRight size={20} className=''/>
                </button>
            </div>
        );
    };

    const gotoNext = () => {
        sliderRef.current.slickNext() ;
    }

    const gotoPrev = () => {
        sliderRef.current.slickPrev();
    }


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 6000,
        centerPadding: '0'
    }

    return (
        <div className='bg-gradient-1'>
            <div className="container-lg">
                <div className="flex flex-wrap">
                        <div className="w-full flex flex-col justify-center items-center mt-[4rem]">
                            <h1 className="text-[6rem] poppins-normal">Client Testimonial</h1>
                            <p className="poppins-medium text-[2.5rem] text-[#8A929A]">
                                What they say about Us
                            </p>
                        </div>
                    {/*<div className="w-full md:w-1/2  md:mt-[6rem] ">*/}
                        <div className="flex justify-end items-end gap-[1rem] px-[8rem] ml-auto md:mt-[6rem]">
                            <button
                                className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
                                onClick={gotoPrev || []}
                            >
                                <FaArrowLeft size={20}/>
                            </button>
                            <button
                                className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
                                onClick={gotoNext || []}
                            >
                                <FaArrowRight size={20}/>
                            </button>
                        </div>
                    {/*</div>*/}
                </div>


                <div className="w-full h-full">
                  
                    <Slider ref={sliderRef} {...settings}
                            className="overflow-hidden pt-[1rem] px-[0px] lg:px-[8rem] relative mb-[7rem]">
                        {
                                testimonials.map((val)=>{
                                  return(
                        <div
                            className="rounded-[1.1rem] w-full h-fit bg-white px-[2.4rem] lg:px-[8rem] pb-[2rem] overflow-hidden flex items-stretch">
                            
                            
                                  <div className="flex flex-col md:flex-row gap-[4.4rem]  items-center">  
                                <div className="relative w-[35rem] h-[50rem] flex flex-col justify-end">
                                    <div className="w-[35rem] h-[45rem] rounded-[1rem] absolute bottom-[3rem]">
                                        <img
                                            src={val.image}
                                            alt=""
                                            className="rounded-[1rem]"
                                        />
                                    </div>
                                    <div className='flex justify-center items-center gap-[2.3rem]'>
                                        <a
                                            href={val.linkedinLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button className='flex justify-center items-center gap-[0.8rem]'>
                                                <p className='text-[2.1rem]'><FaLinkedin/></p>
                                                <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">LinkedIn</h2>
                                            </button>
                                        </a>
                                        <a
                                            href={val.twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex justify-center items-center gap-[0.8rem]"
                                        >
                                            <p className="text-[2.1rem]">
                                                <FaXTwitter/>
                                            </p>
                                            <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">
                                                Twitter
                                            </h2>
                                        </a>
                                    </div>
                                </div>
                                
                                <div className="w-full md:w-[60%] flex flex-col justify-start items-start">
                                    <div className="flex justify-between items-start">
                                        <img src={NavbarImg.upperColon} alt=""
                                             className="w-[50rem] h-[4.7rem] mr-[2rem]"/>
                                        <div className='flex flex-col'>
                                            <h1 className='text-[2rem] my-[2rem] md:mt-0 text-[#5D656D] dm-sans-medium-italic'>{val.testimonial}</h1>
                                            <div className="border border-t border-[#2666CF] h-[1px] w-full mt-5"></div>
                                            <div className="flex flex-col justify-start items-start">
                                                <div className="text-[#000000] text-[3.2rem] montserrat-medium">
                                                    {val.name}
                                                </div>
                                                <div className="text-[1.8rem] montserrat-medium text-[#000000]">
                                                    {val.position}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                        
                      )})
                    }
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default ClientTestimonial;

