import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { technologies } from '../../../Store/actions/TechnologiesAction';
import PrimaryButton from '../../common/Buttons/PrimaryButton';
import TechMarquee from './TechMarquee';

function TechUsed({title, conclusion, tech, document}) 
{
  const dispatch = useDispatch();
  const techData = useSelector((state) => state.technologiesReducer.data);
    
  useEffect(()=>{
    dispatch(technologies());
  },[dispatch]);  
  
  const matchedTech = tech && techData ? techData.filter(techItem => 
    tech.some(item => item.id === techItem.id)) : [];
    
  return (
    <div className="h-fit">
      
        <div className="text-black text-[2.5rem] sm:text-[2.7rem] md:text-[3rem] 
        poppins-semibold my-20 text-center">
          <p>Technology Used</p>
        </div>
        <div className="overflow-x-hidden">
          <TechMarquee matchedTech={matchedTech}/>
        </div>
     
      
      <div className="h-fit bg-[#2666CF]">
        <div className="nav-container max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full text-white">
          <div className="mt-12 mb-8 text-[2.5rem] sm:text-[2.7rem] md:text-[3rem] poppins-semibold text-center md:text-left">
            <p>{title}</p>
          </div>
          <div className="flex flex-col md:flex-row items-center md:items-start justify-between mb-10 gap-6">
            <div className="text-center md:text-left text-[1.7rem] sm:text-[2rem] w-full md:w-2/3">
              <p>{conclusion}</p>
            </div>  
            <div className="mt-6 md:mt-0 lg:mr-[8rem]">
              <a href={document} target="_blank" rel="noreferrer">
                <PrimaryButton 
                  title={"Download Case Study"}
                  fontWeight={"poppins-medium"}
                  bgColor="bg-[#2666CF] border-2 border-white hover:border-[#00D47E] rounded-lg hover:bg-[#00D47E] text-white"
                  classNames={"w-fit px-10 md:h-40 lg:h-28"}
                />
              </a>
            </div> 
          </div>    
        </div>
      </div>
    </div>
  )
}

export default TechUsed