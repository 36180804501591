import React from 'react'
import { serviceImg, serviceLogo } from '../../../static/constants/imageConstant'
import CaseStudy from '../../common/Cards/CaseStudy'
import ServiceGrowth from '../../common/ServiceCommons/ServiceGrowth'
import ServiceHeading from '../../common/ServiceCommons/ServiceHeading'
import ServiceInclude from '../../common/ServiceCommons/ServiceInclude'
import ServicePartners from '../../common/ServiceCommons/ServicePartners'
import ServiceTransformation from '../../common/ServiceCommons/ServiceTransformation'
import ClientTestimonialSec from '../ClientTestimonialSec/ClientTestimonialSec'
import Footer from '../FooterSec/Footer'
import LatestInsightSec from '../LatestInsightSec/LatestInsightSec'
import ReadyToGetStartSec from '../ReadyToGetStartSec/ReadyToGetStartSec'

const bgColor="bg-white";

const transformHeadingOne = "Transform Your Vision into a Thriving Reality";

const serviceHeading = " Empower Your Business with Data-Driven Insights";

const serviceLineOne = "At Infominez, we guide you through an end-to-end journey, from conceptualization to execution. Our structured approach ensures that your ideas evolve into innovative, market-ready products, empowering you to make informed decisions that drive your business forward.";

const growthHeadingOne = "Empowering Business Growth with an End-to-End";
const growthHeadingTwo = "Data Strategy";

const includeHeading = "Our Data Governance and Analytics Services Include";
const partnerHeadingOne = "Why Partner with Infominez for End-to-End Success";

const growthImg = serviceImg.DataStrategyImg;
const includeImg = serviceImg.DataStrategyAnalysisImg;
const partnerImg = serviceImg.DataPartnerImg;

const transform = [
    {
      title:"Make informed decisions",
      detail:"Leverage data-driven insights to optimize operations, identify new opportunities, and mitigate risks.", 
      serviceLogo:serviceLogo.serviceLogoOne, 
    },
    {
      title:"Enhance operational efficiency",
      detail:"Streamline data processes, improve data quality, and boost productivity.", 
      serviceLogo:serviceLogo.serviceLogoTwo, 
    },
    {
      title:"Gain a competitive edge",
      detail:"Understand your customers, markets, and trends better than ever before.", 
      serviceLogo:serviceLogo.serviceLogoThree, 
    },
    {
      title:"Protect your data",
      detail:"Ensure data privacy, security, and compliance with industry regulations.",
      serviceLogo:serviceLogo.serviceLogoFour,  
    },
    {
      title:"Maximize ROI",
      detail:"Extract maximum value from your data investments.",  
      serviceLogo:serviceLogo.serviceLogoFive,
    },
    {
      title:"Partnership Beyond Launch",
      detail:"Rely on our ongoing maintenance and support to ensure your product continues to thrive, adapt, and evolve with your users' needs.",  
      serviceLogo:serviceLogo.serviceLogoSix,
    },  
]

const growth = [
    {
      title:"Define Business Objectives",
    },
    {
        title:"Assess Current Data Landscape ",
    },
    {
        title:"Develop Data Governance Framework ",
    },
    {
        title:"Implement Data Management ",
    },
    {
        title:"Advanced Analytics and Visualization ",
    },
    {
        title:"Foster a Data-Driven Culture ",
    },
    {
        title:"Continuous Improvement and Iteration ",
    },
    {
        title:"Ensure Scalability & Flexibility ",
    },
    {
        title:"Introuce AI/ML concepts ",
    },
    {
        title:"Put and ensure Data Quality points ",
    },
]

const include = [
    {
        title:"Data Strategy & Consulting",
        detailOne:"Collaborate with our experts to define a data strategy aligned with your business objectives, identifying key sources and establishing governance policies.",  
    },
    {
        title:"Data Integration & Management",
        detailOne:"Seamless integration of disparate data sources to consolidate and centralize your data, with robust practices to maintain quality and consistency.",  
    },
    {
        title:"Data Analytics & Visualization",
        detailOne:"Transform your data into actionable insights using advanced analytics and visualization techniques to drive informed decision-making.",  
    },
    {
        title:"Business Intelligence",
        detailOne:"Empower your teams with self-service tools that allow for data exploration, report creation, and dashboard generation, making data-driven decisions accessible to everyone.",  
    },
    {
        title:"Data Governance & Compliance",
        detailOne:"Turn data into actionable strategies with AI-powered tools.",  
    },
    {
        title:"Compliance & Ethical AI",
        detailOne:"Implement frameworks to ensure data privacy, security, and compliance with regulations like GDPR and HIPAA, maintaining data lineage, auditing, and access controls.",  
    },
]

const partner = [
    {
        title:"Data Expertise",
        detailOne:"Our data technology experts offer extensive experience and precision to your initiatives.",
    },
    {
        title:"Innovative Solutions",
        detailOne:"Transform raw data into actionable insights that drive strategic choices for a competitive edge.",
    },
    {
        title:"Compliance Assurance",
        detailOne:"We prioritize data privacy and compliance, ensuring strict protection throughout our governance process.",
    },
    {
        title:"Scalable Solutions",
        detailOne:"Our offerings adapt to your dynamic data needs and expanding analytics requirements.",
    },
    {
        title:"Faster Time-to-Insight",
        detailOne:"Streamlined data processes unlock rapid value extraction, enabling real-time analytics for agile market responses.",
    },
]

function DataEngineeringService() {
  return (
    <div>
        <ServiceHeading serviceHeading={serviceHeading} serviceLineOne={serviceLineOne}/>
        <ServiceTransformation id="transformation" buttonText={"Unlock Your Data Potential"} 
        transform={transform} transformHeadingOne={transformHeadingOne} bgBack={"bg-[#F2F4F4]"}/>
        <ServiceGrowth growth={growth} growthHeadingOne={growthHeadingOne} 
        growthHeadingTwo={growthHeadingTwo} growthImg={growthImg} w={"lg:w-[80rem]"}/>
        <ServiceInclude include={include} includeHeading={includeHeading} includeImg={includeImg} w={"lg:w-[140rem]"}/>
        <div className="h-[8rem]"></div>
        <ServicePartners partner={partner} partnerHeadingOne={partnerHeadingOne} partnerImg={partnerImg} showPartnerButton={"visible"} bgBack={"bg-[#F2F4F4]"}/>
        <CaseStudy bgColor={bgColor}/>
        <LatestInsightSec/>
        <ClientTestimonialSec/>
        <ReadyToGetStartSec id="ready" bgColor={bgColor}/>
        <Footer/>
    </div>
  )
}

export default DataEngineeringService; 