import { actionTypes } from "../types";
 
export const contactUs = (payload, callback) => ({
    type:actionTypes.CONTACT_US_REQUEST,
    payload,
    callback
});
 
export const contactUsSuccess = (payload) => ({
    type:actionTypes.CONTACT_US_SUCCESS,
    payload,
});
 
export const contactUsFailure = (payload) => ({
    type:actionTypes.CONTACT_US_FAILURE,
    payload,
});
 
export const contactUsReset = (payload) => ({
    type:actionTypes.CONTACT_US_RESET,
    payload,
});
 
export const contactUsResetAll = (payload) => ({
    type:actionTypes.CONTACT_US_RESET_ALL,
    payload,
});