import React from 'react'
import { CarrerImg } from '../../../static/constants/imageConstant'

function WhyChoose() {
  return (
    <div className="h-fit">
    <div className="container-lg">
        <div className="flex flex-col lg:flex-row my-20 items-center lg:items-start">
            <div className="w-full lg:w-[380rem]">
                <img src={CarrerImg.chooseInfominez} className="w-full h-auto" />
            </div>
            <div className="text-center lg:text-left">
                <div className="text-[4rem] sm:text-[4rem] md:text-[5rem] poppins-normal lg:ml-[4rem] mt-10 lg:mt-32">
                    <p>Why Choose Infominez?</p>
                </div>
                <div className="mt-[1.5rem] lg:ml-[4rem] px-4 lg:px-0">
                    <div className="poppins-medium text-[#8A929A] text-[2rem] sm:text-[2rem]">
                        <p>At Infominez, we are more than just a technology company;
                           we are a family of forward-thinkers, innovators, and
                           passionate professionals. Our work spans across digital
                           transformation, AI, data engineering, and cutting-edge
                           product development. By joining us, you’ll have the chance to
                           work with industry experts, solve complex problems, and make
                           a tangible impact on the world of technology.
                        </p>
                    </div>
                </div>
                {/* <div className="lg:ml-[6rem] mt-10">
                    <PrimaryButton title={"Explore Opportunities"} classNames={"w-full sm:w-fit px-8 sm:px-16"} />
                </div> */}
            </div>
        </div>
    </div>
</div>

  )
}

export default WhyChoose