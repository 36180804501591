import moment from "moment";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { aboutUs } from "../../../Store/actions/aboutUsActions";
import { auth } from "../../../Store/actions/authActions";
import { blogs } from "../../../Store/actions/blogActions";
import { strapi_img_base_url } from "../../../Store/constants";
import RightArrowFull from '../../common/Buttons/RightArrowFull';
import { useNavigate } from "react-router";
const truncateText = (text, maxLength = 100) => {
  if (!text) return "";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

function RelatedPost() {
  const dispatch = useDispatch();
  const dataa = useSelector((state) => state.blogReducer.data);

  useEffect(() => {
    dispatch(blogs());
    dispatch(auth());
    dispatch(aboutUs());
  }, [dispatch]);
   const navigate = useNavigate();
    const handleNavigate = (e, id) => 
      {
      e.preventDefault();
      navigate(`/blogDetails/${id}`);
    }
  return (
    <div className="h-fit my-4 sm:my-8 md:my-16 lg:my-24 xl:my-[6rem]">
      <div className="max-w-[1440px] mx-auto px-6 ">
        <div className="poppins-medium text-[3rem] sm:text-[3.5rem]/[5rem] md:text-[3.5rem]/[5rem] lg:text-[4rem]/[5.6rem]">
          <p>Related Posts</p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24 mt-3 sm:mt-4 md:mt-6 mb-8 md:mb-12 lg:mb-16">
          {dataa.map((post, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300"
              style={{marginBottom: "1rem" }}
            >
              <div className="relative">
                <img
                  src={`${strapi_img_base_url + "/api_img"}${
                    post.attributes?.main_image?.data?.attributes.url
                  }`}
                  alt={post.attributes.main_title}
                  className="w-full h-[250px] object-cover"
                />
              </div>

              <div className="p-6">
                <div className="flex items-center justify-between mb-2">
                  {/* Author Section */}
                  <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full mr-2 overflow-hidden">
                      <img
                        src={`${strapi_img_base_url + "/api_img"}${
                          post.attributes?.author_image?.data?.attributes.url
                        }`}
                        alt="Author"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <p className="poppins-medium text-[1.375rem] font-medium">
                      {post.attributes.author_name}
                    </p>
                  </div>

                  {/* Date Section */}
                  <p className="text-gray-500 poppins-regular text-[1.375rem] ml-auto">
                    {moment(post.attributes.createdAt).format("DD-MMM-YYYY")}
                  </p>
                </div>

                <h3 className="text-[1.375rem] xl:text-[2rem]/[2.1rem] overflow-hidden text-ellipsis line-clamp-2 poppins-semibold text-gray-800 mt-8 mb-8">
                  {truncateText(post.attributes.main_title, 80)}
                </h3>
                <p className="text-gray-800 text-[1.2rem]/[2.1rem] xl:text-[1.375rem] poppins-regular mb-4 h-[4.5rem] overflow-hidden text-ellipsis line-clamp-2">
                  {truncateText(post.attributes.sub_main_title, 120)}
                </p>
                <div className="flex items-center gap-2">
                  <a onClick={(e) => handleNavigate(e, index)}>
                    <RightArrowFull/>
                  </a>
                  <a onClick={(e) => handleNavigate(e, index)} className="text-[#2666CF] text-[1.375rem]/[2rem] lg:text-[1.5rem]/[2.3rem] font-medium">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RelatedPost;