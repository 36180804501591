import { FaCircle } from "react-icons/fa6";
import PrimaryButton from "../Buttons/PrimaryButton";

function ServiceInclude({include, includeHeading, includeHeadingTwo, includeImg, showIncludeButton, w, bgBack}) 
{
  return (
    <div className="h-fit bg-[#F2F4F4]">
      <div className="max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
        <div className="w-full poppins-normal text-[3.4rem] md:text-[4.5rem] lg:text-[5rem] justify-center mt-8 text-center">
          <p>{includeHeading}</p>
        </div>
        <div className="w-full poppins-normal text-[3.4rem] md:text-[4.5rem] lg:text-[5rem] justify-center -mt-4 md:-mt-6 lg:-mt-8 text-center">
          <p>{includeHeadingTwo}</p>
        </div>

        {/* Image for mobile and tablet only, hidden on lg screens */}
        <div className="lg:hidden w-full my-10 md:my-12">
          <img src={includeImg} className={`w-full ${w ? w : ""}`} alt="Service illustration" />
        </div>

        <div className="flex flex-col lg:flex-row gap-8 lg:gap-x-20 h-fit items-center lg:mt-[3.5rem]">
          <div className="w-full lg:w-1/2 mt-5 md:mt-10 lg:mt-[2.5rem] space-y-5">
            {include.map((val, ind) => {
              return (
                <div className="flex flex-row gap-4 md:gap-6 lg:gap-8 mt-8 lg:mt-0" key={ind}>
                  <FaCircle className="text-[#2666CF] mt-2 md:mt-3 size-4 md:size-5 lg:size-6 flex-shrink-0" />
                  <ul className="space-y-5 md:space-y-8 lg:space-y-10">
                    <li>
                      <p className="poppins-semibold text-[2rem]/[2.7rem] overflow-hidden md:text-[2.4rem]/[3.4rem] text-[#3c436a]">{val.title}</p>
                      <p className="poppins-regular text-[1.5rem] sm:text-[1.8rem] lg:text-[2rem]/[3rem] text-[#8A929A] pt-1 md:pt-2">{val.detailOne}</p>
                    </li>
                  </ul>
                </div>
              );
            })}
            <div>
            
            <div className="flex justify-center lg:justify-start mt-8 md:mt-12 lg:my-[3rem] mb-6 md:mb-8">
              <PrimaryButton 
                title={"Discover How We Can Help You Succeed"} 
                fontWeight={"poppins-medium"}
                bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
                height="h-[3.6rem] md:h-[4.5rem] lg:h-[4.5rem]"  
                width="w-[90%] md:w-[44rem]"  
                classNames={`${showIncludeButton ? showIncludeButton : "invisible"}`}
                className="text-[1.5rem] xl:text-[2rem]"
                onClick={() => {
                  document.getElementById("ready")?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
            </div>
          </div>
          
          {/* Image for desktop only, hidden on smaller screens */}
          <div className="hidden lg:block lg:w-1/2 lg:-mt-[14.5rem] 2xl:-mt-[20rem]">
            <img src={includeImg} className={`w-full ${w ? w : ""}`} alt="Service illustration" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ServiceInclude