import React from 'react';
import { ServicePodImg } from '../../../../static/constants/imageConstant';

function PodEnjoy() 
{
  return (
    <div className="h-fit bg-[#F2F4F4]">
    <div className="container-lg px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row mt-20 justify-between items-center lg:items-start">
            
            <div className="w-full lg:max-w-[600px] flex flex-col justify-center">
                <div className="w-fit mx-auto lg:mx-0 mt-10 lg:mt-40">
                    {/* headings */}
                    <p className="text-center md:text-left text-[3.5rem]/[5rem] sm:text-[3.7rem]/[6rem] md:text-[4rem]/[7rem] lg:text-[4.7rem]/[8.5rem] overflow-hidden poppins-normal lg:-mt-10 2xl:mt-0">
                        Start Your Journey
                    </p>
                    <p className="text-center md:text-left text-[3.5rem]/[5rem] sm:text-[3.7rem]/[6rem] md:text-[4rem]/[7rem] lg:text-[4.7rem]/[8.5rem] overflow-hidden poppins-normal -mt-4 sm:-mt-5 md:-mt-8 lg:-mt-10 2xl:-mt-4">
                        Today with Infominez
                    </p>
                    <p className="text-center md:text-left text-[3.5rem]/[5rem] sm:text-[3.7rem]/[6rem] md:text-[4rem]/[7rem] lg:text-[4.7rem]/[8.5rem] overflow-hidden poppins-normal -mt-4 sm:-mt-5 md:-mt-8 lg:-mt-10 2xl:-mt-4">
                        POD Services
                    </p>

                    {/* details */}
                    <p className="text-center md:text-left text-[#707F96] text-[1.6rem]/[2.6rem] sm:text-[2rem]/[2.6rem] overflow-hidden pt-6 sm:pt-8 md:pt-10 lg:-mt-10 2xl:mt-0">
                        Whether you’re a startup looking to build your MVP or an
                    </p>
                    <p className="text-center md:text-left text-[#707F96] text-[1.6rem]/[2.6rem] sm:text-[2rem]/[2.6rem] overflow-hidden pt-6 sm:pt-8 md:pt-10 md:-mt-10 -mt-8 sm:-mt-8 lg:-mt-10 2xl:-mt-8">
                        enterprise seeking tailored tech solutions, Infominez is here to
                    </p>
                    <p className="text-center md:text-left text-[#707F96] text-[1.6rem]/[2.6rem] sm:text-[2rem]/[2.6rem] overflow-hidden pt-6 sm:pt-8 md:pt-10 md:-mt-10 -mt-8 sm:-mt-8 lg:-mt-10 2xl:-mt-8">
                        provide the talent and expertise you need.
                    </p>
                </div>
            </div>
            
            <div className="w-full flex justify-center lg:max-w-[950px] my-10 sm:my-20 md:my-28 lg:my-36 lg:ml-10">
                <img src={ServicePodImg.podEnjoyImg} className="max-w-full h-auto object-contain" />
            </div>
        </div>
    </div>
</div>

  )
}

export default PodEnjoy;

