import { actionTypes } from "../types";

export const technologies = (payload, callback) => ({
    type:actionTypes.TECHNOLOGIES_REQUEST,
    payload,
    callback
});

export const technologiesSuccess = (payload) => ({
    type:actionTypes.TECHNOLOGIES_SUCCESS,
    payload,
});

export const technologiesFailure = (payload) => ({
    type:actionTypes.TECHNOLOGIES_FAILURE,
    payload,
});

export const technologiesReset = (payload) => ({
    type:actionTypes.TECHNOLOGIES_RESET,
    payload,
});

export const technologiesResetAll = (payload) => ({
    type:actionTypes.TECHNOLOGIES_RESET_ALL,
    payload,
});
