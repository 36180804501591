import { actionTypes } from "../types";

export const image = (payload, callback) => ({
    type:actionTypes.IMAGE_REQUEST,
    payload,
    callback
});

export const imageSuccess = (payload) => ({
    type:actionTypes.IMAGE_SUCCESS,
    payload,
});

export const imageFailure = (payload) => ({
    type:actionTypes.IMAGE_FAILURE,
    payload,
});

export const imageReset = (payload) => ({
    type:actionTypes.IMAGE_RESET,
    payload,
});

export const imageResetAll = (payload) => ({
    type:actionTypes.IMAGE_RESET_ALL,
    payload,
});