import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { NavbarImg } from '../../../static/constants/imageConstant';

function FooterTwo() 
{
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mt-16 text-[#FFFFFF] gap-8 sm:gap-4">
  
    {/* Logo & Description Section */}
    <div className="flex flex-wrap justify-center text-center sm:text-left">
      <div className="h-28 w-80 sm:-ml-16 md:ml-8 lg:-ml-28 xl:-ml-36 2xl:-ml-48">
        <img src={NavbarImg.LogoLight} />
      </div>
      <p className="-mt-8 montserrat-medium sm:pl-10 text-[1.8rem] sm:text-[1.8rem] md:text-[1.6rem] md:-mt-[32px]">
        We are an agency specializing in web design & web automation since 2013
      </p>
    </div>
  
    {/* Explore Section */}
    <div className="sm:ml-40 ml-4">
      <h3 className="text-green-500 montserrat-semibold text-[2.2rem]">Explore</h3>
      <ul className="mt-5 space-y-1 montserrat-medium text-[2rem] sm:text-[1.6rem] md:text-[1.8rem]">
        <li><a href="/">HOME</a></li>
        <li><a href="/about-us">ABOUT</a></li>
        <li><a href="case-studies">CASE STUDY</a></li>
        <li><a href="/blog-section">BLOG</a></li>
      </ul>
    </div>
  
    {/* Support Section */}
    <div className="sm:ml-28 ml-4">
      <h3 className="text-green-500 montserrat-semibold text-[2.2rem]">Support</h3>
      <ul className="mt-5 space-y-1 montserrat-medium text-[2rem] sm:text-[1.6rem] md:text-[1.8rem]">
        <li><a href="/contact-us">CONTACT US</a></li>
      </ul>
    </div>
  
    {/* Social Section */}
    <div className="sm:ml-24 ml-4">
      <h3 className="text-green-500 montserrat-semibold text-[2.2rem]">Social</h3>
      <div className="mt-5 space-y-3 montserrat-medium">
        {[
          { logo: <FaLinkedin size={20}/>, name: "LINKEDIN", link:"https://www.linkedin.com/company/infominez/"},
          { logo: <FaFacebook size={20}/>, name: "FACEBOOK", link:"https://www.facebook.com/infominez"},
          { logo: <RiTwitterXFill size={20}/>, name: "TWITTER", link:"https://x.com/infominez"},
          { logo: <FaInstagram size={20}/>, name: "INSTAGRAM", link:"https://www.instagram.com/infominez/"},
        ].map((item, index) => (
          <div key={index} className="flex items-center gap-3">
            <a href={item.link} target="_blank">{item.logo}</a>
            <a href={item.link} target="_blank" className="text-white text-[2rem] sm:text-[1.6rem] md:text-[1.8rem]">{item.name}</a>
          </div>
        ))}
      </div>
    </div>
  
    {/* Area & Service Section */}
    {/* <div className="flex flex-col gap-6 sm:ml-4 ml-4">
      <div>
        <h3 className="text-green-500 montserrat-semibold text-[16px]">Area we Serve</h3>
        <PrimaryButton 
          title={"SELECT AREA"} 
          titleColor={"text-white"} 
          textSize={"text-[14px] sm:text-[16px] md:text-[12px] lg:text-[16px]"} 
          width={"sm:w-[30rem] w-fit"}
          bgColor={"bg-gray-800"} 
          classNames={"rounded-lg pl-3 pr-16 sm:pr-28 lg:pr-60 md:pr-[110px] montserrat-semibold"} 
        />
      </div>
      <div>
        <h3 className="text-green-500 montserrat-semibold text-[16px]">Our Service</h3>
        <PrimaryButton 
          title={"SELECT SERVICE"} 
          titleColor={"text-white"} 
          textSize={"text-[14px] sm:text-[16px] md:text-[12px] lg:text-[16px]"} 
          width={"sm:w-[30rem] w-fit"}
          bgColor={"bg-gray-800"} 
          classNames={"rounded-lg pl-3 pr-16 sm:pr-28 lg:pr-44 xl:pr-48 leading-[18px] md:pr-[110px] montserrat-semibold"} 
        />
      </div>
    </div> */}
  
  </div>
  )
}

export default FooterTwo
