import React from "react";
import { useNavigate } from "react-router-dom";
import HealthCare from "../../../static/assets/img/IndustryPage/HealthCare.png";
import IndustryBackground from "../../../static/assets/img/IndustryPage/IndustryBackground.png";
import analyticsIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/analyticsIcon.png";
import blockchainIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/blockchainIcon.png";
import complianceIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/compilanceIcon.png";
import fintechIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/fintechIcon.png";
import paymentIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/paymentIcon.png";
import securityIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/securityIcon.png";
import icon1 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/HealthCare/icon1.png";
import icon2 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/HealthCare/icon2.png";
import icon3 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/HealthCare/icon3.png";
import icon4 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/HealthCare/icon4.png";
import icon5 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/HealthCare/icon5.png";
import icon6 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/HealthCare/icon6.png";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import CaseStudy from "../../common/Cards/CaseStudy";
import AdvantageSection from "../../common/Industry_Reusable_Components/AdvantageSection";
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import ReusableParagraph from "../../common/Industry_Reusable_Components/ReusableParagraph";
import ServiceOfferings from "../../common/Industry_Reusable_Components/ServiceOfferings";
import { ValuePropositionGrid } from "../../common/Industry_Reusable_Components/ValuePropositionCard";
import ClientTestimonialSec from "../ClientTestimonialSec/ClientTestimonialSec";
import Footer from "../FooterSec/Footer";
const bgColor="bg-white";
const servicesData = [
    {
        icon: fintechIcon, // Replace with actual image path
        title: "Telemedicine Services",
        description: "Virtual consultations, remote monitoring, and on-demand healthcare.",
    },
    {
        icon:paymentIcon,
        title: "Wellness Programs",
        description: "Tailored plans for nutrition, fitness, and mental health.",
    },
    {
        icon: blockchainIcon,
        title: "AI-Powered Diagnostics",
        description: "Advanced diagnostic tools for early detection and personalized treatment.",
    },
    {
        icon: complianceIcon,
        title: "Chronic Care Management",
        description: "Ongoing support for managing long-term health conditions.",
    },
    {
        icon: analyticsIcon,
        title: "Health Analytics",
        description: "Data visualization, predictive analytics, and personalized reports to track your wellness progress.",
    },
    {
        icon: securityIcon,
        title: "Security & Fraud Prevention",
        description: "Implement added cooling periods, multiple authentications, and PPI limits monitoring.",
    },
];

  
const advantageData = {
    title: "Advantages with Us",
    image: HealthCare, // Replace with actual path
    advantages: [
        {
            title: "Expertise in Healthcare & Wellness",
            description:
                "Our team includes specialists with deep knowledge of the healthcare and wellness sectors, ensuring customized solutions that fit your unique needs.",
        },
        {
            title: "Innovative Technology",
            description:
                "From AI-powered diagnostics to telemedicine, we leverage the latest innovations to bring you personalized care and proactive health management.",
        },
        {
            title: "Scalable Solutions",
            description:
                "Our services grow with you, adapting to your changing health needs, providing seamless integration and easy access to care.",
        },
        {
            title: "Data Security",
            description:
                "We prioritize your privacy, securing your medical data with industry-leading protocols to ensure confidentiality and integrity.",
        },
        {
            title: "Patient-Centered Approach",
            description:
                "Your health is our mission. We work closely with you to tailor a wellness plan that supports your journey toward a healthier lifestyle.",
        },
    ],
};


const Solutions = [
    { 
        icon: icon1, // Replace with actual image path
        title: "Personalized Healthcare Plans", 
        description: "Receive individualized health assessments and wellness plans, utilizing real-time data and advanced diagnostics for precise care."
    },
    { 
        icon: icon2, 
        title: "Proactive Wellness Management", 
        description: "With cutting-edge tools such as AI and predictive analytics, we empower you to stay ahead of potential health issues."
    },
    { 
        icon: icon3, 
        title: "Seamless Telemedicine Services", 
        description: "Access top-quality healthcare from the comfort of your home with our fully integrated telemedicine solutions."
    },
    { 
        icon: icon4, 
        title: "Holistic Wellness Support", 
        description: "From nutrition to mental health, we offer comprehensive wellness services that support every aspect of your well-being."
    },
    { 
        icon: icon5, 
        title: "Data-Driven Health Insights", 
        description: "Make informed decisions about your health with our analytics platforms, offering actionable insights into your wellness journey."
    },
    { 
        icon:icon6, 
        title: "Expertise in Fintech", 
        description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector."
    }
];




  
  const HealthCareAndWellness = () => {
    const navigate = useNavigate();
    return (
      <>
        <div className="relative w-full h-[70vh] xl:h-[70vh] bg-cover bg-center flex flex-col items-center justify-center text-center px-6"
          style={{ backgroundImage: `url(${IndustryBackground})` }}>
            <div className="container-lg">
          <div className="absolute inset-0 bg-blue-900 opacity-50"></div>
          <div className="relative z-10 text-white w-full flex flex-col items-center text-center">
            <ReusableHeadingSection
              heading="Optimal Health: Your Wellness, Our Priority "
              subtext=" Achieve holistic well-being with personalized healthcare solutions, designed to empower you with advanced technology and expert care.  "
              titleSize="text-[3.75rem] xl:text-[6rem]"
              subtextSize="text-[2.2rem] xl:text-[3.2rem]"
              textColor="text-white"
              subtextColor="text-white"
              align="text-center"
              additionalClasses="mt-6"
            />
  
            <div className="mt-10">
            <button 
    onClick={() => {
        document.getElementById("advantages-section")?.scrollIntoView({ behavior: "smooth" });
    }}
    class="
        flex items-center justify-center
        cursor-pointer
        border-2 rounded-full
        bg-inherit text-white hover:bg-[#00D47E] 
        w-[22rem] 
        border-[#00D47E] 
        text-white hover:text-[#0E0731]
        text-[1.6rem]
        poppins-medium
        h-[6rem]
    "
>
    Learn More
    <div class="mx-[2rem]">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
        </svg>
    </div>
</button>
            </div>
          </div>
          </div>
        </div>
  
        <ReusableParagraph 
          text="We provide tailored healthcare and wellness solutions utilizing the latest in medical technology and expert insights to guide you on your wellness journey. Our approach integrates cutting-edge technology with personalized care, making us your strategic partner in achieving optimal health outcomes"
          textColor="text-gray-700"
          textSize="text-[1.5625rem] xl:text-[3rem]"
        />
  
        <AdvantageSection {...advantageData}  id="advantages-section" />
        <ValuePropositionGrid items={Solutions} />
        <div className="w-full bg-[#F2F4F4]">
            <ServiceOfferings services={servicesData} />
        
          <div className="flex justify-center mt-8 mb-12 bg-[#F2F4F4]">
          <button
            className="bg-[#F2F4F4] border-2 border-[#00D47E] text-black text-[1.6rem] poppins-medium py-3 px-8 rounded-md transition duration-300 w-72 hover:bg-[#00D47E] hover:text-white"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us Now!
          </button>
          </div>
        </div>
        <CaseStudy bgColor={bgColor}/>
      <ClientTestimonialSec/>
      <Footer/>

      </>
    );
  };
  
  export default HealthCareAndWellness;
  