export const actionTypes = 
{
    
    // utilActions
    SET_STRAPI_TOKEN: "SET_STRAPI_TOKEN",

    TECHNOLOGIES_REQUEST: 'TECHNOLOGIES_REQUEST',
    TECHNOLOGIES_SUCCESS: 'TECHNOLOGIES_SUCCESS',
    TECHNOLOGIES_FAILURE: 'TECHNOLOGIES_FAILURE',
    TECHNOLOGIES_RESET: 'TECHNOLOGIES_RESET',
    TECHNOLOGIES_RESET_ALL: 'TECHNOLOGIES_RESET_ALL',

    CASE_STUDY_REQUEST: 'CASE_STUDY_REQUEST',
    CASE_STUDY_SUCCESS: 'CASE_STUDY_SUCCESS',
    CASE_STUDY_FAILURE: 'CASE_STUDY_FAILURE',
    CASE_STUDY_RESET: 'CASE_STUDY_RESET',
    CASE_STUDY_RESET_ALL: 'CASE_STUDY_RESET_ALL',

    IMAGE_REQUEST: 'IMAGE_REQUEST',
    IMAGE_SUCCESS: 'IMAGE_SUCCESS',
    IMAGE_FAILURE: 'IMAGE_FAILURE',
    IMAGE_RESET: 'IMAGE_RESET',
    IMAGE_RESET_ALL: 'IMAGE_RESET_ALL',
    
    GET_BLOGS_REQUEST: "GET_BLOGS_REQUEST",
    GET_BLOGS_SUCCESS: "GET_BLOGS_SUCCESS",
    GET_BLOGS_FAILURE: "GET_BLOGS_FAILURE",
    GET_BLOGS_RESET:"GET_BLOGS_RESET",
    GET_BLOGS_RESET_ALL:"GET_BLOGS_RESET_ALL",
  
    CREATE_BLOG_REQUEST: "CREATE_BLOG_REQUEST",
    CREATE_BLOG_SUCCESS: "CREATE_BLOG_SUCCESS",
    CREATE_BLOG_FAILURE: "CREATE_BLOG_FAILURE",
  
    RESET_BLOGS: "RESET_BLOGS",
    AUTH_REQUEST: 'AUTH_REQUEST',
    AUTH_SUCCESS: 'AUTH_SUCCESS',
    AUTH_FAILURE: 'AUTH_FAILURE',
    AUTH_RESET: 'AUTH_RESET',
    AUTH_RESET_ALL: 'AUTH_RESET_ALL',
    
    CONTACT_US_REQUEST: 'CONTACT_US_REQUEST',
    CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
    CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',
    CONTACT_US_RESET: 'CONTACT_US_RESET',
    CONTACT_US_RESET_ALL: 'CONTACT_US_RESET_ALL',
    
    CONTACT_US_REQUEST: 'CONTACT_US_REQUEST',
    CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
    CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',
    CONTACT_US_RESET: 'CONTACT_US_RESET',
    CONTACT_US_RESET_ALL: 'CONTACT_US_RESET_ALL',

    ABOUT_US_REQUEST: 'ABOUT_US_REQUEST',
    ABOUT_US_SUCCESS: 'ABOUT_US_SUCCESS',
    ABOUT_US_FAILURE: 'ABOUT_US_FAILURE',
    ABOUT_US_RESET: 'ABOUT_US_RESET',
    ABOUT_US_RESET_ALL: 'ABOUT_US_RESET_ALL',

  
  };
  