import React from 'react';
import OurTrustedClientsMarquee from './OurTrustedClientsMarquee';



function OurTrustedClientsSec() {

  return (
      <div className="h-fit w-full">
        <div>
          <div className="h-fit flex flex-col items-center justify-center p-4 sm:p-6 md:p-8">
            <h1 className="text-[3.75rem] xl:text-[6rem] font-light text-black poppins-normal text-center">
              Our Trusted Clients
            </h1>
            <div className="overflow-x-hidden">
               <OurTrustedClientsMarquee/>
            </div>
          </div>
        </div>
      </div>  
  );
}

export default OurTrustedClientsSec;