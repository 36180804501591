import React from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';

function PrimaryButton({ title, bgColor, width, borderColor, titleColor, textSize, fontWeight, onClick, height, hasArrow, classNames }) 
{
    return (
        <button
            className={`
                flex items-center justify-center cursor-pointer my-[1.2rem]
                ${bgColor ? bgColor : "bg-[#00D47E] border-2 border-[#00D47E] rounded-lg hover:bg-[#FFFFFF] focus:bg-[#00D47E] focus:outline-[#00D47E]"} 
                ${borderColor ? borderColor : ""} 
                ${titleColor ? titleColor : "text-[#2B2B2B]"}
                ${textSize ? textSize : "text-[2rem]"}
                ${fontWeight ? fontWeight : "font-normal"}
                ${width ? width : "w-[15rem]"} 
                ${height ? height : "h-[5.2rem]"}
                ${classNames}
            `}
            onClick={onClick}
        >
            {title ? title : "Press me"}
            {hasArrow && <FaArrowRightLong className="mx-[2rem]" />}
        </button>
    )
}

export default PrimaryButton