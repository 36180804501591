import React, { useEffect, useRef, useState } from 'react';

const SimpleInput = ({ classNames, handleChange, valueData, name, type = "text", required = false }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const handleFocus = () => {
    setIsFocused(true);
  };
  
  // This is important: if valueData changes externally, the input should reflect it
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = valueData || '';
    }
  }, [valueData]);
  
  return (
    <div className="p-4">
      <input
        ref={inputRef}
        type={type}
        name={name}
        value={valueData || ''}
        onChange={handleChange}
        className={`p-2 border outline-none w-[607px] text-3xl rounded-lg
           h-[30px] md:h-[40px] lg:h-[59px] transition-colors duration-300 ${
          isFocused ? 'border-green-500' : 'border-gray-300'
        } ${classNames}`}
        onFocus={handleFocus}
        required={required}
      />
    </div>
  );
};

export default SimpleInput;