import React from 'react'

function PodHeading() {
  return (
    <div className="h-fit "> 
      <div className="podTeam sm:h-screen h-screen w-full bg-cover bg-center flex items-center">
      <div className="container-lg px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center text-center">
          <p className="text-white poppins-normal text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[4.5rem]">
            Accelerate Your Product Development with Infominez Agile POD Teams: Customized for Your Growth
          </p>
          <p className="poppins-regular text-white text-[1.6rem] sm:text-[1.8rem] md:text-[2.5rem] lg:text-[2.7rem] mt-6">
            Integrated, flexible teams for startups and enterprises—delivering tailored solutions from MVPs to scalable innovations.
          </p>
            {/* <div className="mt-16 sm:mt-8 md:mt-[26px] flex justify-center">
                <PrimaryButton 
                  title={"Schedule a Free Consultation"} 
                  titleColor={"text-white"} 
                  bgColor={"hover:bg-blue-300/10 hover:backdrop-blur-lg hover:text-white"}
                  classNames={"rounded-xl poppins-medium bg-green-500 text-center w-full sm:w-[20rem] md:w-[30rem] lg:w-[40rem] h-[50px]"}/>
              </div> */}
        </div>
      </div> 
      
      <div>
        <div className="container-lg px-4 sm:px-6 lg:px-8">
          <div className="text-[3.5rem] sm:text-[4rem] md:text-[5rem] poppins-normal text-center pt-8 sm:pt-10">
            <p>Our POD Plans</p>
          </div>
          <div className="text-center my-6 sm:my-[20px] md:my-[30px] poppins-regular text-[1.7rem] sm:text-[2rem] text-[#8A929A]">
            <p>
              Infominez Agile POD Services are designed to seamlessly integrate with your business, empowering both startups and enterprises to
              achieve their goals faster and smarter. Whether you’re looking to build an MVP or scale complex enterprise solutions, our custom-built
              PODs provide the flexibility and expertise you need to succeed.
            </p>
            <p className="pt-8 sm:pt-10 md:pt-12">
              We offer three tailored POD service models designed to fit your business’s stage and development needs.
            </p>
          </div>
        </div>  
      </div>
    </div>
   
  )
} 
export default PodHeading
