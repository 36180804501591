import { actionTypes } from "../types"

const initialState = {
    loading: false,
    status: "",
    message:"",
    data: [],
}

export default (state = initialState, action) => {
    switch(action.type){
        case actionTypes.TECHNOLOGIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.TECHNOLOGIES_SUCCESS:
            return {
                ...state,
                loading:false,
                status: action?.payload?.status,
                message: action?.payload?.data?.message,
                data: action?.payload?.data,
            }
        case actionTypes.TECHNOLOGIES_FAILURE:
            return {
                ...state,
                loading:false,
                status: action?.payload?.status,
                message: action?.payload?.data?.message,
            }  
        case actionTypes.TECHNOLOGIES_RESET:
            return {
                ...state,
                status: "",
                message: "",
        }    
        case actionTypes.TECHNOLOGIES_RESET:
            return initialState
              default:
                 return state;             
    }
       
}