import { actionTypes } from "../types";

export const caseStudy = (payload, callback) => ({
    type:actionTypes.CASE_STUDY_REQUEST,
    payload,
    callback
});

export const caseStudySuccess = (payload) => ({
    type:actionTypes.CASE_STUDY_SUCCESS,
    payload,
});

export const caseStudyFailure = (payload) => ({
    type:actionTypes.CASE_STUDY_FAILURE,
    payload,
});

export const caseStudyReset = (payload) => ({
    type:actionTypes.CASE_STUDY_RESET,
    payload,
});

export const caseStudyResetAll = (payload) => ({
    type:actionTypes.CASE_STUDY_RESET_ALL,
    payload,
});