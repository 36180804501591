import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { aboutUs } from "../../../Store/actions/aboutUsActions";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import SimpleInput from "../../common/InputFields/SimpleInput";

function ReadyToGetStartSec({ bgColor, bold, id, bgBack }) {
  const dispatch = useDispatch();
  const recaptcha = useRef(null);
  const formRef = useRef(null);

  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [aboutUsData, setAboutUsData] = useState(initialFormState);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [progress, setProgress] = useState(100);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    phone: "",
    email: "",
  });

  // Handle toast timer
  useEffect(() => {
    let progressTimer;
    let hideTimer;

    if (showToast) {
      // Start progress timer
      progressTimer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress <= 0) {
            clearInterval(progressTimer);
            return 0;
          }
          return prevProgress - 1;
        });
      }, 30); // Decrease by 1% every 30ms

      // Set timer to hide toast after 3 seconds
      hideTimer = setTimeout(() => {
        setShowToast(false);
        setProgress(100);
      }, 3000);
    }

    // Cleanup timers
    return () => {
      clearInterval(progressTimer);
      clearTimeout(hideTimer);
    };
  }, [showToast]);
  //Validate Email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      setValidationErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
    } else {
      setValidationErrors((prev) => ({
        ...prev,
        email: "",
      }));
    }
  };
  // Validate phone number - Simplified to just require exactly 10 digits
  const validatePhone = (phoneNumber) => {
    // Extract only digits from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");
    const containsLetters = /[a-zA-Z]/.test(phoneNumber);
    if (containsLetters) {
      setValidationErrors((prev) => ({
        ...prev,
        phone: "Phone number cannot contain letters",
      }));
      return false;
    }
    // Check for exactly 10 digits
    if (!phoneNumber || digitsOnly.length === 0) {
      setValidationErrors((prev) => ({
        ...prev,
        phone: "Phone number is required",
      }));
      return false;
    } else if (digitsOnly.length !== 10) {
      setValidationErrors((prev) => ({
        ...prev,
        phone: "Phone number must be 10 digits",
      }));
      return false;
    }

    // Clear previous error if validation passes
    setValidationErrors((prev) => ({ ...prev, phone: "" }));
    return true;
  };

  // Live validation on blur for better user experience
  const handleBlur = (e) => {
    if (e.target.name === "phone" && e.target.value) {
      validatePhone(e.target.value);
    }
    if (e.target.name === "email" && e.target.value) {
      validateEmail(e.target.value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check for alpha characters in phone field while typing
    if (name === "phone") {
      const containsLetters = /[a-zA-Z]/.test(value);
      if (containsLetters) {
        setValidationErrors((prev) => ({
          ...prev,
          phone: "Phone number cannot contain letters",
        }));
      } else {
        // Only clear the error if it was specifically about letters
        if (validationErrors.phone === "Phone number cannot contain letters") {
          setValidationErrors((prev) => ({ ...prev, phone: "" }));
        }
      }
    }

    setAboutUsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const showToastMessage = (message, type = "success") => {
    // Make sure we have a message before showing the toast
    if (!message || message.trim() === "") return;

    setToastMessage(message);
    setToastType(type);
    setProgress(100);
    setShowToast(true);
  };

  const resetForm = () => {
    // Reset form state
    setAboutUsData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    // Reset validation errors
    setValidationErrors({
      phone: "",
      email: "",
    });

    // Reset recaptcha
    if (recaptcha.current) {
      recaptcha.current.reset();
    }

    // Also reset the actual form element in DOM
    if (formRef.current) {
      formRef.current.reset();
    }

    console.log("Form reset complete");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    // Validate phone before submission
    const isPhoneValid = validatePhone(aboutUsData.phone);

    if (!isPhoneValid) {
      // Make sure there's an error message before showing toast
      const errorMessage =
        validationErrors.phone || "Phone number must be exactly 10 digits";
      showToastMessage(errorMessage, "error");
      return;
    }

    const recaptchaValue = recaptcha.current
      ? recaptcha.current.getValue()
      : null;

    if (!recaptchaValue) {
      showToastMessage("Please complete the reCAPTCHA", "error");
      return;
    }

    // Set submitting state to prevent multiple submissions
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("fullName", aboutUsData.name);
    formData.append("email", aboutUsData.email);
    formData.append("phone", aboutUsData.phone);
    formData.append("message", aboutUsData.message);
    formData.append("reCaptcha", recaptchaValue);

    try {
      // Dispatch the action and wait for it to complete
      await dispatch(aboutUs(formData));

      // Show success message
      showToastMessage("Thanks For Contacting Us!", "success");

      // Reset form after a longer delay to ensure state updates complete
      setTimeout(() => {
        resetForm();
        setIsSubmitting(false);
      }, 500);
    } catch (error) {
      console.error("Form submission error:", error);
      showToastMessage(
        "There was an error submitting the form. Please try again.",
        "error"
      );
      setIsSubmitting(false);
    }
  };

  return (
    <div
      id={id}
      className={`h-fit w-full ${bgColor ? bgColor : "bg-[#F2F4F4]"}`}
    >
      <div className="container-lg">
        {/* Title */}
        <p
          className={`text-[3.75rem] xl:text-[6rem] text-center mt-10 sm:mt-16 ${
            bold ? bold : "poppins-normal"
          }`}
        >
          Ready to Get Started?
        </p>
        <p className="leading-[30px] text-[1.8rem] sm:leading-[52px] sm:text-4xl text-center poppins-medium text-[#8A929A] -mt-4 sm:-mt-6">
          Transforming businesses with data, AI/ML, and cloud solutions
        </p>

        {/* Form Fields */}
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="mt-10 sm:mt-20 flex justify-center gap-5 flex-wrap">
            <div className="w-full sm:w-[45%]">
              <label className="poppins-medium ml-2 sm:ml-6 text-[2rem] xl:text-[2.2rem]">
                Your Name<sup className="text-red-500">*</sup>
              </label>
              <SimpleInput
                name="name"
                valueData={aboutUsData.name}
                handleChange={handleChange}
                classNames="w-full h-12 md:h-18 lg:h-24 p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="w-full sm:w-[45%]">
              <label className="poppins-medium ml-2 sm:ml-6 text-[2rem] xl:text-[2.2rem]">
                Contact email<sup className="text-red-500">*</sup>
              </label>
              <SimpleInput
                name="email"
                type="email"
                valueData={aboutUsData.email}
                handleChange={handleChange}
                classNames="w-full h-12 md:h-18 lg:h-24 p-3 border border-gray-300 rounded-lg"
                required
              />
              {validationErrors.email && (
                <p className="text-red-500 text-[1.375rem] ml-2 sm:ml-6 mt-1">
                  {validationErrors.email}
                </p>
              )}
            </div>

            <div className="w-full sm:w-[45%]">
              <label className="poppins-medium ml-2 sm:ml-6 text-[2rem] xl:text-[2.2rem]">
                Phone No.<sup className="text-red-500">*</sup>
              </label>
              <SimpleInput
                name="phone"
                type="tel"
                pattern="[0-9]{10}"
                title="Phone number must be 10 digits only (no letters allowed)"
                valueData={aboutUsData.phone}
                handleChange={handleChange}
                onBlur={handleBlur}
                classNames="w-full h-12 md:h-18 lg:h-24 p-3 border border-gray-300 rounded-lg"
                required
              />
              {validationErrors.phone && (
                <p className="text-red-500 text-[1.375rem] ml-2 sm:ml-6 mt-1">
                  {validationErrors.phone}
                </p>
              )}
              {/* <p className="text-gray-500 text-[1.375rem] ml-2 sm:ml-6 mt-1">Format: Exactly 10 digits required (formatting characters allowed)</p> */}
            </div>

            <div className="w-full sm:w-[45%]">
              <label className="poppins-medium ml-2 sm:ml-6 text-[2rem] xl:text-[2.2rem]">
                Message<sup className="text-red-500">*</sup>
              </label>
              <SimpleInput
                name="message"
                valueData={aboutUsData.message}
                handleChange={handleChange}
                classNames="w-full h-12 md:h-18 lg:h-24 p-3 border border-gray-300 rounded-lg"
                required
              />
            </div>
          </div>

          {/* ReCaptcha & Submit Button */}
          <div className="flex flex-col sm:flex-row items-center justify-evenly gap-6 sm:gap-10 mt-10 mb-10">
            {/* ReCaptcha */}
            <div className="w-full sm:w-auto flex justify-center">
              <ReCAPTCHA
                sitekey="6Ld-o9IqAAAAAIz_LyeIAl4WiFYaW56Z8e6QFKfK"
                onChange={(value) => console.log("ReCAPTCHA value:", value)}
                ref={recaptcha}
              />
            </div>
            {/* Submit Button */}
            <div className="w-full sm:w-auto flex justify-center">
              <PrimaryButton
                title={isSubmitting ? "Submitting..." : "Submit"}
                type="submit"
                classNames={
                  "poppins-medium px-20 sm:px-40 transition duration-300"
                }
                bgColor=
                {`${bgBack? bgBack:"bg-white"} border-2 border-[#00D47E] rounded-2xl hover:bg-[#00D47E] `}
                titleColor={"text-black hover:!text-white"}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </form>
      </div>

      {/* Toast Notification - Fixed to prevent blank toasts */}
      {showToast && toastMessage && toastMessage.trim() !== "" && (
        <div className="fixed top-44 right-10 max-w-md w-[400px] bg-white rounded-lg shadow-2xl overflow-hidden z-50">
          <div className="px-6 py-4 flex items-center relative">
            {/* Close Button */}
            <button
              onClick={() => setShowToast(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            {/* Conditional Icon based on toast type */}
            {toastType === "success" ? (
              <div className="bg-green-500 p-2 rounded-full mr-4">
                <svg
                  className="w-7 h-7 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              </div>
            ) : (
              <div className="bg-red-500 p-2 rounded-full mr-4">
                <svg
                  className="w-7 h-7 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </div>
            )}

            {/* Message */}
            <p className="text-gray-700 text-[1.375rem] poppins-medium">
              {toastMessage}
            </p>
          </div>

          {/* Progress bar with conditional color */}
          <div className="bg-gray-200 h-2 w-full">
            <div
              className={`${
                toastType === "success" ? "bg-green-500" : "bg-red-500"
              } h-2 transition-all duration-300 ease-in-out`}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReadyToGetStartSec;
