import React from 'react'
import { SiTicktick } from 'react-icons/si'

function Benefits({title, content, description}) 
{
  return (
    <div className="h-fit">
      <div className="h-fit visual bg-cover bg-center text-white">
        <div className="nav-container max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
            <div className="text-[2.5rem] sm:text-[2.7rem] xl:text-[3rem] text-center poppins-semibold mt-20">
                <p>{title}</p>
            </div>
            <div className="poppins-normal text-center text-[1.7rem] mt-5">
                <p>{description}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-24 gap-x-6 md:gap-x-10 lg:gap-x-16 gap-y-8 mb-20">
                  { 
                  content.map((val, index)=>
                  {
                    return(
                      <div key={index} className="flex gap-x-4 md:gap-x-6"> 
                          <div className="flex-shrink-0 mt-3">
                              <SiTicktick className="size-8 sm:size-8 md:size-10"/>
                          </div>
                          <div className="poppins-semibold text-[1.4rem] sm:text-[1.7rem]">
                              <p>{val}</p>
                          </div>
                      </div> 
                    )})   
                  }
            </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits