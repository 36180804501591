import React from 'react';
import { FaCircle } from 'react-icons/fa6';

function PodStartup({startUpHeading, startUpImg, startUp}) {
  return (
    <div className="h-fit">
      <div className="container-lg">
        <div className="flex flex-col lg:flex-row">
          {/* Desktop image - hidden on small screens */}
          <div className="mt-4 hidden lg:block h-[80rem] w-full">
            <img 
              src={startUpImg} 
              className="w-full h-full object-cover object-center" 
            />
          </div>
          <div className="container-lg">
            <div className="bg-white my-10 lg:my-20 mb-12 w-full sm:w-[90%] md:w-[80%] lg:w-[580px] lg:absolute lg:-ml-24 lg:h-[70rem] overflow-hidden">
              <div className="my-4">
                <div className="text-center lg:text-left lg:pl-[8.5rem] text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] poppins-normal">
                  <p>{startUpHeading}</p>
                </div>

                {/* Mobile/Tablet image - only shown on small screens */}
                <div className="flex justify-center lg:hidden my-6">
                  <img 
                    src={startUpImg} 
                    className="w-[90%] sm:w-[80%] md:w-[70%] object-cover" 
                  />
                </div>

                <div className="ml-0 sm:ml-[2rem] md:ml-[4rem] lg:ml-[6rem] mt-6 lg:mt-[2.5rem] space-y-8 px-5 lg:px-0">
                  {startUp.map((val, ind) => {
                    return (
                      <div className="flex items-center gap-4 sm:gap-6 md:gap-8" key={ind}>
                        <div className="flex-shrink-0 -mt-[32px] md:-mt-[40px] lg:-mt-[40px] xl:-mt-[48px] 2xl:-mt-[6rem]">
                          <FaCircle className="text-[#41DF6B] size-4 sm:size-5 md:size-6 lg:size-8" />
                        </div>
                        <ul>
                          <li className="my-4 lg:my-0">
                            <p className="poppins-semibold text-[1.8rem] sm:text-[2rem] md:text-[2rem]/[2.7rem] text-[#3C436A] lg:text-[2.4rem]/[3.2rem] xl:text-[2.5rem]/[3.5rem] lg:mt-4 overflow-hidden">{val.title}</p>
                            <p className="poppins-regular text-[1.6rem] sm:text-[1.7rem] md:text-[1.8rem]/[2.7rem] text-[#8A929A] pt-2">
                              {val.detail}
                            </p>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PodStartup