import React from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';

function ServiceTransformation({buttonText, transform, transformHeadingOne, id, bgBack}) 
{
  return (
    <div id={id} className="h-fit bg-[#F2F4F4]">
      <div className="max-w-[1440px] mx-auto px-4 md:px-6 w-full">
        {/* Heading Fix */}
        <div className="flex justify-center">
          <div className="poppins-normal text-[4rem] md:text-[4.5rem] lg:text-[5rem] text-center pt-[2.4rem]">
            <p>{transformHeadingOne}</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row flex-wrap w-full h-fit gap-y-8 md:gap-y-12 lg:gap-y-16 mt-8 md:mt-12 lg:mt-16 justify-center">
           {transform.map((val, ind) => (
             <div className="w-full md:w-1/2 h-fit px-4 md:px-6" key={ind}>
               <div className="flex flex-row gap-6 space-y-3 h-fit">
                   <div className="flex-shrink-0 mt-4">
                      <img src={val.serviceLogo} className="w-20 md:w-24 lg:w-28" />
                   </div>
                   <div className="h-fit">
                       <p className="poppins-semibold text-[1.8rem] md:text-[2rem] lg:text-[2.2rem]">{val.title}</p>
                       <p className="poppins-regular text-[1.6rem] md:text-[1.8rem] lg:text-[2rem] pt-2 text-[#8A929A]">{val.detail}</p>
                   </div>
               </div>
             </div>
           ))}
        </div>

        {/* Button Fix */}
        <div className="flex justify-center mt-8 md:mt-12 lg:mt-[4rem] mb-6 md:mb-8 lg:mb-10">
          <PrimaryButton 
            title={buttonText}
            fontWeight={"poppins-medium"}
            bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
            height="h-[3.6rem] md:h-[4.5rem] lg:h-[4.5rem]"  
            width="w-[90%] md:w-[32rem] lg:w-[34rem]"   
            className="text-[1.5rem] xl:text-[2rem] px-6"
            onClick={() => {
              document.getElementById("ready")?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ServiceTransformation