import axios from "axios";
import store from "./configStore";
 
export const AxiosService = ({ method, base_url, endPoint, data, ContentType, isAuth }) => {
    const state = store.getState();
    // let accessToken = state.utilReducers?.strapiToken
    let accessToken = "Bearer 52dfecb37f1ed7d1b231081ce7929d64c68c8768ace44605bc7cdc7d56de544a685a56578ea8296fa17681c36044cc32c7878fc3dd58473b87289f62a993fc1c67231fa9349e988079dd81e2450118cc06114c6a259111854d8bd4336c970f5d2409685649d8571cdbfc5c600951db9df1104ffaf487c4f2776182f9a221e6ac";

    console.log('====================================');
    console.log("calling AxiosService: ",
        {
            "method": method,
            "url": base_url + endPoint,
            "body": data,
            "ContentType": ContentType ? ContentType : 'application/json',
            "isAuth": isAuth,
            "token": isAuth ? accessToken : "",
        }
    );
    console.log('====================================');
    if (method === 'get') {
        return axios({
            method: method,
            url: base_url + endPoint,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    } else if (method === 'post' || method === 'put') {
        return axios({
            method: method,
            url: base_url + endPoint,
            data: data,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    } else {
        return axios({
            method: method,
            url: base_url + endPoint,
            data: data,
            headers: isAuth ?
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `${accessToken}`,
                }
                :
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                },
        });
    }
};