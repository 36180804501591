import React from 'react'
import { ServicePodImg } from '../../../static/constants/imageConstant'
import PodChoose from '../../common/ServiceCommons/PodServices.jsx/PodChoose'
import PodEnjoy from '../../common/ServiceCommons/PodServices.jsx/PodEnjoy'
import PodGrowth from '../../common/ServiceCommons/PodServices.jsx/PodGrowth'
import PodHeading from '../../common/ServiceCommons/PodServices.jsx/PodHeading'
import PodStartup from '../../common/ServiceCommons/PodServices.jsx/PodStartup'
import ClientTestimonialSec from '../ClientTestimonialSec/ClientTestimonialSec'
import Footer from '../FooterSec/Footer'
import ReadyToGetStartSec from '../ReadyToGetStartSec/ReadyToGetStartSec'

const startUpHeading = "Startup POD";
const customHeading = "Custom POD";

const startUpImg = ServicePodImg.podStartupImg;
const customImg  = ServicePodImg.podCustomImg;

const startUp=[
    {
        title:"For Startups Ready to Build and Ship Fast",
        detail:"A lean and agile team perfect for early-stage startups that need to develop their MVP or get to market quickly.",
    },
    {
        title:"Team Composition",
        detail:"1 Frontend Developer, 1 Backend Developer, 1 QA Tester and Client Coordinator",
    },
    {
        title:"Ideal for",
        detail:"MVP development, proof of concept, rapid feature rollouts.",
    },
    {
        title:"Benefits",
        detail:"Minimize development costs and time while maximizing your product's potential for market success. ",
    },
]

const custom = [
    {
        title: "For Enterprises Seeking Full Flexibility ",
        detail:"A fully customizable solution designed to meet unique enterprise-level demands, offering end-to-end development tailored to your specific project requirements.",  
    },
    {
        title: "Team Composition",
        detail:"Fully flexible—choose from developers, QA engineers, DevOps experts, project managers, and more, based on your needs.",  
    },
    {
        title: "Ideal for",
        detail:"Enterprises with specialized project requirements, cross-functional teams, and complex multi-phase deployments.",  
    },
    {
        title: "Benefits",
        detail:"Full control over team structure, project pace, and resource allocation—adapt as your business grows and evolves.",  
    },
]

function PodService() 
{
  return (
    <div>
       <PodHeading/>
       <PodStartup startUpHeading={startUpHeading} startUpImg={startUpImg} startUp={startUp}/>
       <PodGrowth/>
       <PodStartup startUpHeading={customHeading} startUpImg={customImg} startUp={custom}/>
       <div className="h-[7rem]"></div>
       <PodChoose/> 
       <ClientTestimonialSec/>
       <div className="h-[7rem]"></div>
       <PodEnjoy/>
       <ReadyToGetStartSec bgColor={"bg-white"}/>
       <Footer/>
    </div>
  )
}

export default PodService
