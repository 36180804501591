import React from 'react';

const AdvantageSection = ({ 
  id = 'AdvantageWithUs',
  title = 'Advantages with Us',
  image,
  advantages,
  backgroundColor = '#F2F4F4'
}) => {
  return (
    <div id={id} className={`bg-[${backgroundColor}]`}>
      <div className="nav-container flex justify-between max-w-[1440px] mx-auto items-center container-lg py-[1.2rem] px-4 md:px-6 w-full overflow-visible">
        <div className="w-full">
          {/* Section Header */}
          <div className="poppins-normal text-[3.75rem] xl:text-[6rem] items-center text-center justify-center my-[4rem] mt-[8rem]">
            {title}
          </div>
          
          {/* Main content container with proper alignment */}
          <div className="w-full flex flex-col lg:flex-row gap-[5rem]">
            {/* Image Container - Fully aligned with text content */}
            <div className="w-full lg:w-[50%] flex">
              <div className="w-full h-full flex items-center">
                <div className="w-full h-full flex items-start">
                  <img
                    src={image}
                    alt="Advantage illustration"
                    className="w-full h-auto object-contain my-auto"
                  />
                </div>
              </div>
            </div>
            
            {/* Text Container - No extra spacing */}
            <div className="w-full lg:w-[50%] flex flex-col justify-between">
              <ul className="w-full list-none p-0 m-0 flex flex-col">
                {advantages.map((advantage, index) => (
                  <li key={index} className={`flex items-start gap-[2rem] ${index !== 0 ? 'mt-[2rem]' : ''}`}>
                    <span className="h-[1.4rem] w-[1.4rem] xl:h-[1.8rem] xl:w-[1.8rem] bg-[#41DF6B] rounded-full mt-4 flex-shrink-0"></span>
                    <div className="w-full">
                      <h3 className="poppins-semibold text-[2rem] xl:text-[2.8rem] text-[#3C436A] mb-[0.5rem]">{advantage.title}</h3>
                      <p className="text-[#707F96] text-[1.8rem] xl:text-[2.2rem] poppins-regular mb-[1rem]">
                        {advantage.description}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantageSection;