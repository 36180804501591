import React from 'react'

function DetailsHeading({bgImg, headingOneBlog, headingTwoBlog, heading, line, overview, need}) 
{
  return (
<div className="h-fit">
  <div className={`${bgImg} h-[535px] sm:h-[535px] w-full bg-cover bg-center flex flex-col justify-center items-center`}>
    <div className="container-lg px-4 sm:px-6 md:px-12 lg:px-24 xl:px-32">
      <div className="flex flex-col items-center justify-center text-white poppins-normal text-center">
        <p className="text-[4.5rem] sm:text-[4.5rem] md:text-[5rem]">{headingOneBlog}</p>
        <p className="text-[4.5rem] sm:text-[4.5rem] md:text-[5rem]">{headingTwoBlog}</p>
      </div>

      <div className="text-white flex flex-col items-center justify-center text-center mt-8 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-24">
        <p className="text-[4rem] sm:text-[5rem] md:text-[4.5rem] poppins-normal">{heading}</p>

        <hr className={`w-full sm:w-[500px] md:w-[700px] mt-6 sm:mt-8 md:mt-10 ${need ? need : "hidden"}`} />

        <div className="mt-6 sm:mt-8 md:mt-10 poppins-medium text-center">
          <p className="text-[1.5rem] sm:text-[2rem] md:text-[2rem]">{line}</p>
        </div>
      </div>
    </div>
  </div>

  <div className={`h-fit mt-12 sm:mt-14 md:mt-16 text-center text-[#404040] text-[1.7rem] sm:text-[2.3rem] md:text-[2.5rem] poppins-regular ${need ? need : "hidden"}`}>
    <div className="container-lg px-4 sm:px-6 md:px-12 lg:px-24 xl:px-32 flex flex-col items-center justify-center">
      <p>{overview}</p>
    </div>
  </div>
</div>

  )
}

export default DetailsHeading