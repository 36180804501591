import React from "react";
import { useNavigate } from "react-router-dom";
import Edutech from "../../../static/assets/img/IndustryPage/EdutechAndLearning.png";
import IndustryBackground from "../../../static/assets/img/IndustryPage/IndustryBackground.png";
import analyticsIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/analyticsIcon.png";
import blockchainIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/blockchainIcon.png";
import complianceIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/compilanceIcon.png";
import fintechIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/fintechIcon.png";
import paymentIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/paymentIcon.png";
import securityIcon from "../../../static/assets/img/IndustryPage/ServiceOfferings/securityIcon.png";
import icon1 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon1.png";
import icon2 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon2.png";
import icon3 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon3.png";
import icon4 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon4.png";
import icon5 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon5.png";
import icon6 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon6.png";
import icon7 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon7.png";
import icon8 from "../../../static/assets/img/IndustryPage/ValuePropostionCard/EdutechAndLearning/icon8.png";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import CaseStudy from "../../common/Cards/CaseStudy";
import AdvantageSection from "../../common/Industry_Reusable_Components/AdvantageSection";
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import ReusableParagraph from "../../common/Industry_Reusable_Components/ReusableParagraph";
import ServiceOfferings from "../../common/Industry_Reusable_Components/ServiceOfferings";
import { ValuePropositionGrid } from "../../common/Industry_Reusable_Components/ValuePropositionCard";
import ClientTestimonialSec from "../ClientTestimonialSec/ClientTestimonialSec";
import Footer from "../FooterSec/Footer";
const bgColor = "bg-white";
const servicesData = [
  {
    icon: fintechIcon, // Replace with actual image path
    title: "Learning Management Systems (LMS)",
    description:
      "Centralized platform for course management, content delivery, and assessment. Personalized learning paths based on student performance. Seamless integration with other educational tools.",
  },
  {
    icon: paymentIcon,
    title: "E-Learning Platforms",
    description:
      "Engaging and interactive online courses with multimedia content. Gamification elements to enhance learner motivation. Mobile-optimized for flexible learning.",
  },
  {
    icon: blockchainIcon,
    title: "Virtual Classroom Solutions",
    description:
      "Real-time interactive learning experiences with live video, chat, and whiteboard features. Collaborative tools for group work and discussions. Integration with LMS for seamless course delivery.",
  },
  {
    icon: complianceIcon,
    title: "Edutainment Apps",
    description:
      "Fun and engaging learning experiences for young learners. Aligned with educational standards and curriculum. Gamified elements to make learning enjoyable.",
  },
  {
    icon: analyticsIcon,
    title: "Data Analytics and Insights",
    description:
      "Powerful analytics tools to measure student performance and identify trends. Actionable insights to inform instructional decisions and improve outcomes. Data privacy and security compliance.",
  },
  {
    icon: securityIcon,
    title: "Security & Fraud Prevention",
    description:
      "Implement added cooling periods, multiple authentications, and PPI limits monitoring.",
  },
];

const advantageData = {
  title: "Advantages with Us",
  image: Edutech, // Replace with actual path
  advantages: [
    {
      title: "Deep Educational Expertise",
      description:
        "Our team comprises experienced educators and technologists who understand the nuances of the education sector.",
    },
    {
      title: "Proven Track Record of Innovation",
      description:
        "We are committed to staying ahead of the curve by leveraging emerging technologies.",
    },
    {
      title: "Scalable Solutions",
      description:
        "Our offerings can adapt to the evolving needs of educational institutions of all sizes.",
    },
    {
      title: "Robust Security",
      description:
        "We prioritize the protection of sensitive student and institutional data.",
    },
    {
      title: "Client-Centric Approach",
      description:
        "We build strong partnerships with our clients to achieve their educational goals.",
    },
  ],
};

const Solutions = [
  {
    icon: icon1, // Replace with actual image path
    title: "Enhanced Student Success",
    description:
      "Drive higher engagement, improved outcomes, and increased retention.",
  },
  {
    icon: icon2,
    title: "Optimized Operations",
    description: "Streamline processes and make data-driven decisions.",
  },
  {
    icon: icon3,
    title: "Future-Ready Institution",
    description: "Stay ahead of educational trends with adaptable technology.",
  },
  {
    icon: icon4,
    title: "Empowered Educators",
    description:
      "Support personalized instruction and focus on student growth.",
  },
  {
    icon: icon5,
    title: "Personalized Learning",
    description:
      "Experience tailored education to match individual needs and pace.",
  },
  {
    icon: icon6,
    title: "Expanded Access",
    description: "Enjoy flexible learning opportunities and quality education.",
  },
  {
    icon: icon7,
    title: "Interactive Learning",
    description:
      "Engage with peers and instructors in collaborative environments.",
  },
  {
    icon: icon8,
    title: "Improved Outcomes",
    description:
      "Achieve academic success through effective learning experiences.",
  },
];

const EdutechAndLearning = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="relative w-full h-[70vh] xl:h-[70vh] bg-cover bg-center flex flex-col items-center justify-center text-center px-6"
        style={{ backgroundImage: `url(${IndustryBackground})` }}
      >
        <div className="container-lg">
          <div className="absolute inset-0 bg-blue-900 opacity-50"></div>
          <div className="relative z-10 text-white w-full flex flex-col items-center text-center">
            <ReusableHeadingSection
              heading="Empower Your Learning, Unleash Your Potential  "
              subtext="Discover a new era of education with our innovative Edutech solutions  "
              titleSize="text-[3.75rem] xl:text-[6rem]"
              subtextSize="text-[2.2rem] xl:text-[3.2rem]"
              textColor="text-white"
              subtextColor="text-white"
              align="text-center"
              additionalClasses="mt-6"
            />

            <div className="mt-10">
              <button
                onClick={() => {
                  document
                    .getElementById("advantages-section")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                class="
        flex items-center justify-center
        cursor-pointer
        border-2 rounded-full
        bg-inherit text-white hover:bg-[#00D47E] 
        w-[22rem] 
        border-[#00D47E] 
        text-white hover:text-[#0E0731]
        text-[1.6rem]
        poppins-medium
        h-[6rem]
    "
              >
                Learn More
                <div class="mx-[2rem]">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ReusableParagraph
        text="Infominez is pioneering the future of learning, delivering revolutionary EduTech solutions that elevate the educational experience. By leveraging technology, we unlock the full potential of educators and students, driving interactive learning and academic excellence. "
        textColor="text-gray-700"
        textSize="text-[1.5625rem] xl:text-[3rem]"
      />

      <AdvantageSection {...advantageData} id="advantages-section" />
      <ValuePropositionGrid items={Solutions} />
      <div className="w-full bg-[#F2F4F4]">
        <ServiceOfferings services={servicesData} />
        <div className="flex justify-center mt-8 mb-12 bg-[#F2F4F4]">
          <button
            className="bg-[#F2F4F4] border-2 border-[#00D47E] text-black text-[1.6rem] poppins-medium py-3 px-8 rounded-md transition duration-300 w-72 hover:bg-[#00D47E] hover:text-white"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us Now!
          </button>
        </div>
      </div>
      <CaseStudy bgColor={bgColor} />
      <ClientTestimonialSec />
      <Footer />
    </>
  );
};

export default EdutechAndLearning;
