import React from 'react'

function CarrerHeading() {
  return (
    <div className="h-fit "> 
  <div className="carrer min-h-screen sm:h-[471px] w-full bg-cover bg-center flex flex-col justify-center items-center">
    <div className="container-lg px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
      <div className="pt-36 sm:pt-0">
        <p className="text-[#FFFFFF] poppins-normal text-[3rem] 
                      sm:text-[3.5rem] md:text-[4.5rem] lg:text-[5rem] xl:text-[5.5rem] 2xl:text-[5.5rem]">
          Unlock Your Potential with Infominez
        </p>
      </div>

      <div className="pt-8 sm:pt-6 md:pt-8 lg:pt-2 xl:pt-2">
          <p className="poppins-light text-[#FFFFFF] text-[1.6rem] 
                        sm:text-[2rem] md:text-[2.4rem] lg:text-[2.6rem] xl:text-[2.8rem] 2xl:text-[3rem]">
            At Infominez, we're shaping the future of technology. Join us and be part of a team that thrives on
             <br className="hidden sm:block"/> innovation, creativity, and growth.
          </p>
      </div>
      {/* <div className="mt-16 sm:mt-8 md:mt-[26px] lg:mt-[30px] xl:mt-[34px] 2xl:mt-[38px] flex justify-center">
        <PrimaryButton 
          title={"Explore Open Positions"} 
          titleColor={"text-white"} 
          bgColor={"hover:bg-blue-300/10 hover:backdrop-blur-lg hover:text-white"}
          classNames={"rounded-xl poppins-medium bg-green-500 text-center w-full sm:w-[20rem] md:w-[30rem] lg:w-[35rem] xl:w-[40rem] 2xl:w-[45rem] h-[50px]"}/>
      </div> */}
    </div>
  </div> 
</div>

  )
}

export default CarrerHeading
