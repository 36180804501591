import React from "react";
import "tailwindcss/tailwind.css";
import ajit from "../../../static/assets/img/TeamSection/ajit-sir.png";
import kevin from "../../../static/assets/img/TeamSection/kevin.png";
import anand from "../../../static/assets/img/TeamSection/Anand.png";
import sanjay from "../../../static/assets/img/TeamSection/Sanjay.png";
import aditya from "../../../static/assets/img/TeamSection/Aditya.png";
import chavinath from "../../../static/assets/img/TeamSection/Chavinath.png";
import harshit from "../../../static/assets/img/TeamSection/Harshit.png";
import tarun from "../../../static/assets/img/TeamSection/Tarun.png";
import mayank from "../../../static/assets/img/TeamSection/Mayank.png";
import linkedin from "../../../static/assets/img/TeamSection/linkedin.png";
import rajdeep from "../../../static/assets/img/TeamSection/Rajdeep.png"
import jai from "../../../static/assets/img/TeamSection/Jai.png"
import raksha from "../../../static/assets/img/TeamSection/Raksha.png"
import deepika from "../../../static/assets/img/TeamSection/Deepika.png"
import pratik from  "../../../static/assets/img/TeamSection/Pratik.png"
import anita from "../../../static/assets/img/TeamSection/Anita.png"
import alok from "../../../static/assets/img/TeamSection/Alok.png"
import adityaD from "../../../static/assets/img/TeamSection/AdityaDesai.png"
import rashmi from "../../../static/assets/img/TeamSection/Rashmi.png"
import monesh from "../../../static/assets/img/TeamSection/Monish.png"
import kapil from "../../../static/assets/img/TeamSection/Kapil.png"
import nagendra from "../../../static/assets/img/TeamSection/Nagendra.png"
import aishwary from "../../../static/assets/img/TeamSection/aishwary_1.png"
import mayank1 from "../../../static/assets/img/TeamSection/Mayank_M.png"

const teamMembers = [
  { name: "Ajit", image: ajit, linkedin: "https://linkedin.com/in/ajitmaya"},
  { name: "Kevin", image: kevin, linkedin: "https://linkedin.com/in/kevinwsumrall" },
  { name: "Anand", image: anand, linkedin: "https://linkedin.com/in/anandinfominez" },
  { name: "Sanjay", image: sanjay, linkedin: "https://linkedin.com/in/sanjay-gawatiya-7b7585102" },
  { name: "Aditya", image: aditya, linkedin: "https://linkedin.com/in/aditya-shukla-629372120" },
  { name: "Chavinath", image: chavinath, linkedin: "https://linkedin.com/in/schhavi1922" },
  { name: "Harshit", image: harshit, linkedin: "https://linkedin.com/in/harshit-tomar-24a14b212" },
  { name: "Mayank", image: mayank, linkedin: "https://linkedin.com/in/mayank-namdev-5111551b8" },
  { name: "Tarun", image: tarun, linkedin: "https://linkedin.com/in/tarun-jat-15526a240" },
  { name: "Rajdeep", image: rajdeep, linkedin: "https://linkedin.com/in/rajdip-biswas-468401250" },
  { name: "Raksha", image: raksha, linkedin: "https://www.linkedin.com/in/raksha-verma/?originalSubdomain=in" },
  { name: "Jay", image: jai, linkedin: "https://linkedin.com/in/jay-sahu-47325a236" },
  { name: "Deepika", image: deepika, linkedin: "https://linkedin.com/in/deepika-gawatiya-b4bb142b8" },
  { name: "Pratik", image: pratik, linkedin: "https://linkedin.com/in/pratikjat" },
  { name: "Monesh", image: monesh, linkedin: "https://linkedin.com/in/monesh-jain-88ab50228" },
  { name: "Anita", image: anita, linkedin: "https://linkedin.com/in/anitha-balan-b8480822a" },
  { name: "Rashmi", image: rashmi, linkedin: "https://linkedin.com/in/rashmi-sharma-ba1b142b8" },
  { name: "Alok", image: alok, linkedin: "https://linkedin.com/in/drexzer" },
  { name: "Aditya D", image: adityaD, linkedin: "https://linkedin.com/in/aditya-desai-9180a61a5" },
  { name: "Kapil", image: kapil, linkedin: "https://linkedin.com/in/dr-kapil-k-kushwah-24b525254" },
  { name: "Nagendra", image: nagendra, linkedin: "https://linkedin.com/in/nagendra-sharma-567578174" },
  { name: "Aishwary", image: aishwary, linkedin: "https://www.linkedin.com/in/aishwary-dongre-51a139212" },
  { name: "Mayank M", image: mayank1, linkedin: "https://www.linkedin.com/in/mayank-matkar-042003k" },
];

const TeamSection = () => {
  return (
    <div className="text-center py-28 px-4 md:px-6 w-full overflow-visible">
      <div className="max-w-[1440px] mx-auto container-lg">
        <h2 className="text-[3.75rem] xl:text-[6rem] poppins-normal text-center mb-16">
          Our Leadership Team
        </h2>
        <p className="text-[2rem] xl:text-[2.5rem]  text-[#979EA5] poppins-meidum mb-16">
          Our team is our greatest asset. With expertise in various technological
          domains, our team members are the driving force behind our innovative
          solutions. Meet some of our key team members:
        </p>
        
        {/* Updated grid layout with larger images */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
          {teamMembers.map((member, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className="relative w-full aspect-square max-w-md mx-auto rounded-full overflow-hidden transition-all duration-300 ease-in-out border-4 border-gray-300 hover:border-green-500"
              >
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-full object-cover grayscale hover:grayscale-0 transition-all duration-300"
                />
              </div>
              <div className="flex items-center justify-center gap-2 mt-3">
                <span className="text-[2rem] xl:text-[3.2rem] font-semibold text-[#00365A]">
                  {member.name}
                </span>
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex"
                >
                  <img src={linkedin} alt="LinkedIn" className="w-6 h-6 md:w-10 md:h-10" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;