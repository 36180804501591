import { all } from "redux-saga/effects";
import aboutUsSaga from "./Sagas/aboutUsSaga";
import authSaga from "./Sagas/authSaga";
import blogsSaga from "./Sagas/blogSaga";
import caseStudySaga from "./Sagas/caseStudySaga";
import contactUsSaga from "./Sagas/contactUsSaga";
import imageSaga from "./Sagas/imageSaga";
import technologiesSaga from "./Sagas/TechnologiesSaga";
export default function* rootSaga() {
    yield all([
        blogsSaga(),
        authSaga(),
        contactUsSaga(),
        aboutUsSaga(),
        technologiesSaga(),
        caseStudySaga(),
        imageSaga(),
    ])
}
