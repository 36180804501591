import React from 'react';
import Marquee from "react-fast-marquee";
import { trustedClients } from '../../../static/constants/imageConstant';

const clients = [
    {
      logo: trustedClients.IcfLogo,
      name: "Indore Cancer Foundation",
      link:"https://indorecancerfoundation.org/",
    },
    {
      logo: trustedClients.NictLogo,
      name: "National Institute of Information",
      link:"https://nicttpl.com/",
    },
    {
      logo: trustedClients.MaayaLogo,
      name: "Maaya Money",
      link:"https://mayaa.money/",
    },
    {
      logo: trustedClients.PaulLogo,
      name: "Paul Merchants",
      link:"https://www.paulmerchants.net/",
    },
    {
      logo: trustedClients.PaidLogo,
      name: "Paid Promo",
      link:"https://www.paidpromo.ai/",
    },
    {
      logo: trustedClients.EpsLogo,
      name: "Electronic Payment & Services",
      link:"https://electronicpay.in/",
    },
    {
      logo: trustedClients.kritiLogo,
      name: "Kriti Industries",
      link:"https://kritiindustries.com/",
    },
    {
      logo: trustedClients.inadzLogo,
      name: "Inadz",
      link:"https://inadz.com/",
    },
    {
      logo: trustedClients.KastaLogo,
      name: "Kasta Pipes",
      link:"https://www.kastapipes.co.in/",
    },
    {
      logo: trustedClients.fitZoneLogo,
      name: "Fit Zone",
      link:"https://fit.zone/",
    },
    {
      logo: trustedClients.andBuyLogo,
      name: "and Buy",
      link:"https://andbuy.in/",
    },
    {
      logo: trustedClients.investmentorLogo,
      name: "Investmentor",
      link:"https://investmentor.com.au/",
    },
    {
      logo: trustedClients.rackBankLogo,
      name: "Rack Bank",
      link:"https://www.rackbank.com/",
    },
    {
      logo: trustedClients.chameleonLogo,
      name: "Chameleon",
      link:"https://chameleoncollective.com/",
    },
  ];

function OurTrustedClientsMarquee() 
{
  return (
    <>
      <div>
          <Marquee pauseOnHover="true" speed={250} loop={0}>
             {
                clients.map((val,ind)=>
                  {
                  return(
                      <div className="h-fit w-fit px-[3rem]">
                          <div className="text-center">
                              <a href={val.link} target="_blank"><img src={val.logo} key={ind} className={`w-[${val.w}] w-[18rem]`}/></a>
                            <a href={val.link} target="_blank"
                            className="text-[1.6rem] poppins-medium">{val.name}</a>
                          </div>
                      </div>
                  )})
             }
          </Marquee>   
    </div>  
</>
  )
}

export default OurTrustedClientsMarquee
