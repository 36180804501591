import React from "react";
import { useNavigate } from "react-router-dom";
import video from "../../../static/assets/videos/hero-landing-bg1.mp4";

const HeroSection = () => {
  const navigate = useNavigate();
  
  return (
    <div className="relative flex items-center justify-start h-[70vh] sm:h-[70vh] md:h-[90vh] lg:h-screen w-screen overflow-hidden">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
      
      {/* Content Container - aligned with the navbar container */}
      <div className="relative z-10 w-full max-w-[1440px] mx-auto px-4 md:px-6 py-32">
        <div className="text-center md:text-left">
          <h1 className="text-[3.75rem] xl:text-[6rem] overflow-hidden mb-8 text-white poppins-normal leading-tight">
            Transform Your Business with Intelligent
            <br className="hidden md:block" />
            AI & ML Solutions
          </h1>
          <p className="text-[2.2rem] xl:text-[3.3rem] mb-12 poppins-light text-[#FFFFFF]">
            Drive Innovation and Growth with AI-Powered Solutions
          </p>
          <div className="flex justify-center md:justify-start">
          <button
            onClick={() => navigate("/contact-us")}
            className="bg-transparent border-2 border-[#00D47E] poppins-medium text-[#FFFFFF] text-[1.6rem] xl:text-[2.2rem] px-8 py-3 rounded-full hover:bg-[#00D47E] hover:text-[#020B2D] transition duration-300 flex items-center space-x-2"
          >
            <span>Transform Your Business with AI & ML</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-10"
              viewBox="0 0 16 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HeroSection;