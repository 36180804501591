import React from 'react';
import { FaCircle } from 'react-icons/fa6';
import PrimaryButton from '../Buttons/PrimaryButton';

function ServiceGrowth({growth, growthHeadingOne, growthHeadingTwo, growthHeadingExtra, growthImg, showGrowthButton, classNames, w, yes, bgBack}) 
{
  return (
    <div className="h-fit">
      <div className={`max-w-[1440px] mx-auto px-4 md:px-6 w-full ${yes?"lg:mb-[4rem]":""}`}>
        <div className="flex flex-col md:flex-row justify-center gap-4 poppins-normal text-[4rem] md:text-[4.5rem] lg:text-[5rem] mt-6 md:mt-8 lg:mt-10 text-center md:text-left">
          <p className="text-center">{growthHeadingOne}</p>
          <p className="text-blue-500 -mt-[0.1rem] md:mt-0 sm:mt-[0.1rem]">{growthHeadingExtra}</p>
        </div>
        <div className="poppins-normal text-[4rem] md:text-[4.5rem] lg:text-[5rem] md:text-left">
          <p className="text-center lg:-mt-4">{growthHeadingTwo}</p>
        </div>

        <div className="flex flex-col lg:flex-row w-full h-fit">
          <div className={`my-6 md:my-10 ${classNames}`}>
            <img src={growthImg} className={`w-full ${w ? w : ""} imgCrop`}/>
          </div>
          <div className="ml-0 lg:ml-[10rem] mt-4 md:mt-6 lg:mt-[2.5rem] space-y-6 md:space-y-8 lg:space-y-10">
            {growth.map((val, ind) => {
              return (
                <div className="flex flex-row gap-4 md:gap-6 lg:gap-8" key={ind}>
                  <FaCircle className="flex-shrink-0 text-[#2666CF] mt-2 md:mt-3 size-4 md:size-5 lg:size-6"/>
                  <ul>
                    <li>
                      <p className="poppins-semibold text-[2rem]/[2.8rem] md:text-[2.4rem]/[3.4rem] text-[#3c436a]">{val.title}</p>
                      <p className="poppins-regular text-[1.8rem] sm:text-[1.8rem] lg:text-[2rem]/[3rem] text-[#8A929A] pt-1 md:pt-2">{val.detailOne}</p>
                    </li>
                  </ul>
                </div>      
              );
            })}
            <div className="flex justify-center lg:justify-start mt-8 md:mt-12 lg:my-[3rem] mb-6 md:mb-8">
              <PrimaryButton 
                title={"Ready to Transform Your Idea"} 
                fontWeight={"poppins-medium"}
                bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
                height="h-[3.6rem] md:h-[4.5rem] lg:h-[4.5rem]"  
                width="w-[90%] md:w-[40rem]"  
                classNames={`${showGrowthButton ? showGrowthButton : "invisible"}`}
                className="text-[1.5rem] xl:text-[2rem]"
                onClick={() => {
                  document.getElementById("ready")?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceGrowth;