import React from 'react';
import { FaCircle } from 'react-icons/fa6';
import { ServicePodImg } from '../../../../static/constants/imageConstant';

const growth = [
  {
    title: "For Growing Companies Ready to Accelerate",
    detail: "An expanded team designed for scaling businesses with evolving product needs, handling complex projects with enhanced efficiency.",
  },
  {
    title: "Team Composition",
    detail: "2 Frontend Developers, 2 Backend Developers, 1 QA and 1 Project Manager",
  },
  {
    title: "Ideal for",
    detail: "Full product development, scaling applications, integrating advanced features.",
  },
  {
    title: "Benefits",
    detail: "Balanced, expert-driven team with built-in project management for streamlined execution and faster delivery.",
  },
];

function PodGrowth() 
{
  return (
    <div className="h-fit bg-white my-10">
      <div className="container-lg px-4">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          {/* Text Section */}
          <div className="w-full sm:w-[90%] md:w-[80%] lg:w-[580px] py-8 lg:py-16 bg-white lg:absolute lg:-ml-[rem]">
            <div className="lg:pr-8">
              {/* Title */}
              <h1 className="text-center lg:text-left lg:pl-[2.5rem] text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] poppins-normal mb-10 lg:mb-16">
                Growth POD
              </h1>
              
              {/* Mobile/Tablet Image - only visible on small screens */}
              <div className="flex justify-center lg:hidden mb-8">
                <img
                  src={ServicePodImg.podGrowthImg}
                  alt="Team collaboration"
                  className="w-[90%] sm:w-[80%] md:w-[70%]"
                />
              </div>
              
              {/* Content List */}
              <div className="space-y-8">
                {growth.map((item, index) => (
                  <div className="flex items-start gap-4" key={index}>
                    <FaCircle className="text-[#41DF6B] mt-2 flex-shrink-0 size-4 sm:size-5 md:size-6 lg:size-8" />
                    <div>
                      <h3 className="poppins-semibold text-[1.5rem] sm:text-[1.6rem] md:text-[1.8rem]/[2.7rem] text-[#3C436A] 
                          lg:text-[2.4rem]/[3.4rem] xl:text-[2.5rem]/[3.5rem]">
                        {item.title}
                      </h3>
                      <p className="poppins-regular text-[1.6rem] sm:text-[1.7rem] md:text-[1.8rem]/[2.7rem] text-[#8A929A] pt-2">
                        {item.detail}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* Desktop Image - only visible on large screens */}
          <div className="hidden lg:block w-full lg:ml-[35rem] 2xl:ml-[35rem]">
            <img
              src={ServicePodImg.podGrowthImg}
              alt="Team collaboration"
              className="w-full lg:h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PodGrowth;