import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import Navbar from './components/section/Navbar/Navbar';
import AppRoutes from './routes';
import store, { persistor } from './Store/configStore';
function App() 
{
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <div className="App">
            {/* <Navigation/> */}
            <Navbar/>
            <AppRoutes />
          </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
