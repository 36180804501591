import { sub } from "framer-motion/client";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeStringify from "rehype-stringify";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";

function BlogMan({
  date,
  maintitle,
  subMainTitle,
  mainImage,
  subImageOne,
  subImageTwo,
  description_1 = [],
  subTitle_1 = [],
  description_2 = [],
  title_3,
  description_3 = [],
  title_1,
}) {
  return (
    <div className="h-fit mt-16 md:mt-24">
      <div className="nav-container max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
        <div className="poppins-regular">
          <p className="text-[1.8rem] xl:text-[1.8rem]">{date}</p>
        </div>
        <div className="poppins-medium mt-4 sm:mt-6">
          <p className="text-[2.5rem]/[3.7rem] sm:text-[2.8rem]/[4rem] md:text-[3.2rem]/[4.7rem] lg:text-[3.2rem]/[4.9rem]">
            {maintitle}
          </p>
        </div>
        <div className="poppins-regular mt-4 sm:mt-6">
          <p className="text-[1.5rem]/[2.2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem]">
            {subMainTitle}
          </p>
        </div>

        <div className="w-full mt-6 sm:mt-8">
          <img src={mainImage} className="w-full object-cover" alt="Main" />
        </div>

        <div className="poppins-regular">
          <div className="mt-4 sm:mt-6">
            {description_1?.map((val, ind) => (
              // <p key={ind} className="text-[1.5rem]/[2.2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem]">{val}</p>
              <div key={ind}>
                <article
                  key={ind}
                  className="text-[1.5rem]/[2.2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem]"
                >
                  <ReactMarkdown
                    remarkPlugins={[
                      remarkGfm,
                      remarkParse,
                      remarkRehype,
                      rehypeStringify,
                    ]}
                  >
                    {val}
                  </ReactMarkdown>
                </article>
              </div>
            ))}
          </div>

          <div className="poppins-medium mt-4 sm:mt-6">
            <p className="text-[2.5rem]/[3.7rem] sm:text-[2.8rem]/[4rem] md:text-[3.2rem]/[4.4rem] lg:text-[3.2rem]/[4.9rem]">
              {title_1}
            </p>
          </div>

          <div className="mt-4 sm:mt-6">
            {subTitle_1.map((val, ind) => (
              <div key={ind}>
                <article
                  key={ind}
                  className="text-[1.5rem]/[2.2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem]"
                >
                  <ReactMarkdown
                    remarkPlugins={[
                      remarkGfm,
                      remarkParse,
                      remarkRehype,
                      rehypeStringify,
                    ]}
                  >
                    {val}
                  </ReactMarkdown>
                </article>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between mt-6 sm:mt-10 gap-4">
          <div className="w-full sm:w-[48%]">
            <img
              src={subImageOne}
              className="w-full object-cover"
              alt="Sub 1"
            />
          </div>
          <div className="w-full sm:w-[48%]">
            <img
              src={subImageTwo}
              className="w-full object-cover"
              alt="Sub 2"
            />
          </div>
        </div>

        <div className="poppins-regular">
          <div className="mt-4 sm:mt-6">
            {description_2.map((val, ind) => (
              <div key={ind}>
                <article
                  key={ind}
                  className="text-[1.5rem]/[2.2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem]"
                >
                  <ReactMarkdown
                    remarkPlugins={[
                      remarkGfm,
                      remarkParse,
                      remarkRehype,
                      rehypeStringify,
                    ]}
                  >
                    {val}
                  </ReactMarkdown>
                </article>
              </div>
            ))}
          </div>

          <div className="poppins-medium mt-4 sm:mt-6">
            <p className="text-[2.5rem]/[3.7rem] sm:text-[2.8rem]/[4rem] md:text-[3.2rem]/[4.6rem] lg:text-[3.2rem]/[4.9rem]">
              {title_3}
            </p>
          </div>

          <div className="mt-4 sm:mt-6 mb-10">
            {description_3.map((val, ind) => (
              <div key={ind}>
                <article
                  key={ind}
                  className="text-[1.5rem]/[2.2rem] sm:text-[1.8rem]/[2.5rem] md:text-[2rem]/[2.8rem]"
                >
                  <ReactMarkdown
                    remarkPlugins={[
                      remarkGfm,
                      remarkParse,
                      remarkRehype,
                      rehypeStringify,
                    ]}
                  >
                    {val}
                  </ReactMarkdown>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogMan;
