import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ecommerce from "../../../static/assets/img/IndustryCardsIcons/ecommerce.png";
import edutech from "../../../static/assets/img/IndustryCardsIcons/edutech.png";
import fintech from "../../../static/assets/img/IndustryCardsIcons/fintech.png";
import healthcare from "../../../static/assets/img/IndustryCardsIcons/healthcare.png";

const industries = [
  {
    title: "Fintech and Banking",
    subtitle: "Pioneering the Digital Finance Revolution",
    description:
      "Experience the future of finance with our fintech and banking services, designed to accelerate your financial prosperity through seamless, secure, & transformative solutions.",
    image: fintech,
    path: "/fintech-banking",
  },
  {
    title: "E Commerce and Retail",
    subtitle: "Retail Mastery: Conquer the Digital Landscape",
    description:
      "Revolutionizing the retail space with cutting-edge solutions for seamless shopping experiences.",
    image: ecommerce,
    path: "/ecommerce-retail",
  },
  {
    title: "Edutech and Learning",
    subtitle: "Empower Your Learning, Unleash Your Potential",
    description:
      "Enhancing education through innovative technology and personalized learning platforms.",
    image: edutech,
    path: "/edutech-learning",
  },
  {
    title: "Healthcare and Wellness",
    subtitle: "Discover Your Path to Optimal Well-Being",
    description:
      "Bringing advanced healthcare solutions to improve quality of life and well-being.",
    image: healthcare,
    path: "/health-wellness",
  },
];

const IndustryCards = () => {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMidSize, setIsMidSize] = useState(false);

  useEffect(() => {
    // Check if the device is mobile or mid-size on component mount
    const checkScreenSize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      setIsMidSize(width >= 800 && width < 1002);
    };

    // Set initial state
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setHoverIndex(0);
    }
  }, [isMobile]);

  const handleMouseEnter = (index) => {
    if (isMobile) return;

    setIsTransitioning(true);
    setHoverIndex(index);

    // Reset transitioning state after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 700); // Match this with your transition duration
  };

  const handleMouseLeave = () => {
    if (isMobile) return;

    setIsTransitioning(true);
    setHoverIndex(0);

    // Reset transitioning state after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 700); // Match this with your transition duration
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  // Add a class to body when transitioning to hide scrollbars
  useEffect(() => {
    if (isTransitioning) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isTransitioning]);

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 py-32 overflow-hidden">
      {/* Using the same container style as navbar for alignment */}
      <div className="nav-container max-w-[1440px] mx-auto px-4 md:px-6 container-lg w-full">
        <h2 className="text-[3.75rem] xl:text-[6rem]/[6.3rem] 2xl:text-[6rem] poppins-normal overflow-hidden text-center mb-32 md:mb-24 text-gray-800">
          Industry We Serve
        </h2>

        <div className="w-full pb-4 md:pb-0 overflow-hidden relative">
          <div className="flex flex-col md:flex-row justify-start md:justify-center gap-4 md:gap-6 lg:gap-8 overflow-visible">
            {industries.map((industry, index) => (
              <div
                key={index}
                className={`
                  bg-white rounded-sm shadow-sm border border-gray-100 will-change-auto
                  transition-all duration-700 ease-in-out
                  cursor-pointer h-auto md:h-[300px] lg:h-[330px] xl:h-[400px] 
                  min-w-full md:min-w-0 relative
                  ${
                    !isMobile &&
                    (hoverIndex === index
                      ? "md:w-[38%] lg:w-[36%] xl:w-[34%]"
                      : "md:w-[18%] lg:w-[19%] xl:w-[20%]")
                  }
                  flex-shrink-0 overflow-hidden
                `}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {/* For desktop: Show collapsed view when not hovered */}
                <div
                  className={`
                    absolute inset-0 p-5 md:p-6 xl:pt-12 xl:pb-12 pt-8 pb-8 
                    flex flex-col items-center text-center h-full w-full
                    transition-opacity duration-700 ease-in-out
                    ${
                      !isMobile && hoverIndex !== index
                        ? "opacity-100"
                        : "opacity-0 pointer-events-none"
                    }
                    ${isMobile ? "hidden" : "block"}
                  `}
                >
                  <div className="mb-6 mt-14 xl:mb-8 xl:mt-6">
                    <img
                      src={industry.image}
                      alt={industry.title}
                      className="w-16 h-16 md:w-20 md:h-20 xl:w-24 xl:h-24 object-contain"
                    />
                  </div>

                  <h3 className="text-[1.8rem] xl:text-[2.1rem] poppins-regular text-black mb-2 xl:mb-4">
                    {industry.title}
                  </h3>

                  <p className="poppins-medium text-[1.6rem] xl:text-[2rem] text-gray-500 mt-3 mb-6 xl:mt-4 xl:mb-8">
                    {industry.subtitle}
                  </p>

                  <div className="mt-auto pt-4 xl:pt-6">
                    <button
                      onClick={() => handleNavigation(industry.path)}
                      className="px-8 py-2 mb-8 xl:mb-0 xl:px-10 xl:py-3 text-[1.4rem] xl:text-[1.6rem] bg-white text-black border border-[#00D47E] poppins-medium rounded-md hover:bg-[#00D47E] hover:text-white transition-colors"
                    >
                      Discover More
                    </button>
                  </div>
                </div>

                {/* For desktop: Show expanded view when hovered */}
                {/* For mobile: Always show expanded view */}
                <div
                  className={`
                    p-6 xl:p-8 pt-8 pb-8 xl:pt-14 xl:pb-14 flex flex-col h-full bg-white
                    transition-opacity duration-700 ease-in-out
                    ${
                      isMobile || hoverIndex === index
                        ? "opacity-100"
                        : "opacity-0 pointer-events-none"
                    }
                    ${
                      !isMobile && hoverIndex !== index
                        ? "absolute inset-0"
                        : ""
                    }
                  `}
                >
                  <div className="flex items-start gap-4 mt-16 xl:mt-0 xl:gap-6 mb-4 xl:mb-6">
                    <div className="flex-shrink-0">
                      <img
                        src={industry.image}
                        alt={industry.title}
                        className="w-16 h-16 md:w-20 md:h-20 xl:w-24 xl:h-24 object-contain"
                      />
                    </div>
                    <div className="text-left">
                      <h3 className="text-[1.8rem] xl:text-[2.1rem] poppins-regular text-black">
                        {industry.title}
                      </h3>
                      <div className="w-full h-px bg-gray-200 my-3 xl:my-4"></div>
                      <p className="text-[1.6rem] xl:text-[2rem] text-gray-500 poppins-medium">
                        {industry.subtitle}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 xl:mt-6 text-left">
                    <p className="text-[#5D656D] text-[1.5rem] xl:text-[1.8rem] poppins-regular">
                      {industry.description}
                    </p>
                  </div>
                  <div className="mt-auto pt-6 xl:pt-8">
                    <button
                      onClick={() => handleNavigation(industry.path)}
                      className="px-8 mb-8 xl:mb-0 py-2 xl:px-10 xl:py-3 text-[1.4rem] xl:text-[1.6rem] bg-white text-black border border-[#00D47E] poppins-medium rounded-md transition-colors hover:bg-[#00D47E] hover:text-white"
                    >
                      Discover More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryCards;
