import React from "react";

const ValuePropositionCard = ({ icon, title, description }) => {
  return (
    <div className="bg-gray-100 p-5 sm:p-8 lg:p-10 rounded-3xl shadow-md flex items-center gap-4 sm:gap-6 w-full h-auto sm:h-72 lg:h-80"
    style={{marginBottom: "1rem" }}>
      <div className="flex items-center justify-center w-24 sm:w-32 md:w-40 lg:w-52 h-24 sm:h-32 md:h-40 lg:h-52 shrink-0">
        <img src={icon} alt={title} className="w-full h-full object-contain" />
      </div>
      <div className="flex-1 text-left">
        <h3 className="poppins-medium  text-[1.8rem]/[2.1rem] xl:text-[2.4rem]/[3.2rem] overflow-hidden text-[#000000] text-left">
          {title}
        </h3>
        <hr className="border-[#939393] my-2 xl:my-6" />
        <p className="text-[#979EA5] poppins-regular text-[1.5rem]/[1.75rem] xl:text-[2rem]/[2.05rem] overflow-hidden  text-left">
          {description}
        </p>
      </div>
    </div>
  );
};

const ValuePropositionGrid = ({ items }) => {
  const defaultItems = [
    { icon: "icon1.png", title: "Expertise in Fintech", description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector." },
    { icon: "icon2.png", title: "Innovative Technology", description: "Harness the power of advanced technology to unlock new opportunities." },
    { icon: "icon3.png", title: "Personalized Solutions", description: "Every solution is crafted to meet your business's unique needs and aspirations." },
    { icon: "icon4.png", title: "Enhanced Security", description: "We go the extra mile to safeguard your data with cutting-edge security protocols." },
    { icon: "icon5.png", title: "Collaborative Success", description: "We work hand-in-hand with you to achieve your business objectives, ensuring a seamless experience every step of the way." },
    { icon: "icon6.png", title: "Client-Centric Approach", description: "Your success is our success—expect a dedicated, collaborative partnership." }
  ];

  const finalItems = items && items.length ? items : defaultItems;

  return (
    <div className="w-full mx-auto text-center mb-12 lg:mb-20">
      <div className="max-w-[1440px] mx-auto px-4 md:px-6 container-lg">
        <h2 className="text-[3.75rem] xl:text-[6rem] poppins-normal mt-8 lg:mt-16 mb-6 lg:mb-16">
          Value Propositions
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8 lg:gap-16">
          {finalItems.map((item, index) => (
            <ValuePropositionCard key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { ValuePropositionCard, ValuePropositionGrid };