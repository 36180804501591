import { call, put, takeLatest } from "redux-saga/effects";
import { aboutUsFailure, aboutUsSuccess } from "../actions/aboutUsActions";
import { base_url, endpoints, strapi_base_url } from "../constants";
import { actionTypes } from "../types";
import { AxiosService } from "../services";

function* aboutUsCall({ payload, callback }) {
  try {
    const response = yield call(AxiosService,
      {
        method: 'POST',
        base_url: base_url,
        endPoint: endpoints.aboutUs,
        data: payload,
        isAuth: true,
        // isRecaptcha: payload.header,
        // isRecaptcha: payload.header,
      });
     
      console.log("aboutUsCall response :" , response);
      

      yield put(aboutUsSuccess({
        status: response?.status,
        data: response?.data?.data,
        message: response?.message
      })
    )
    if (callback) {
      callback(
        response?.status,
        response?.response,
        response?.message
      ); // Pass the response data to the callback
    }
  } catch (error) {
    console.log("aboutUsCall error :", error);
    
    yield put(aboutUsFailure({
      status: error.response?.status,
      message: error.response?.message
    }));
  }
}

function* aboutUsSaga() {
  yield takeLatest(actionTypes.ABOUT_US_REQUEST, aboutUsCall);
}

export default aboutUsSaga;