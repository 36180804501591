import { call, put, takeLatest } from "redux-saga/effects";
import { blogsFailure, blogsSuccess } from "../actions/blogActions";
import { endpoints, strapi_base_url } from "../constants";
import { actionTypes } from "../types";
import { AxiosService } from "../services";

function* blogsCall({ payload, callback }) {
  try {
    const response = yield call(AxiosService,
      {
        method: 'get',
        base_url: strapi_base_url,
        endPoint: endpoints.getBlogs + '?populate=*',
        data: payload,
        isAuth: true,
      });

      console.log("blogsCall response :" , response);
      

      yield put(blogsSuccess({
        status: response?.status,
        data: response?.data?.data,
        message: response?.message
      })
    )
    if (callback) {
      callback(
        response?.status,
        response?.response,
        response?.message
      ); // Pass the response data to the callback
    }
  } catch (error) {
    console.log("blogsCall error :", error);
    
    yield put(blogsFailure({
      status: error.response?.status,
      message: error.response?.message
    }));
  }
}

function* blogsSaga() {
  yield takeLatest(actionTypes.GET_BLOGS_REQUEST, blogsCall);
}

export default blogsSaga;