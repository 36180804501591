import React from 'react';
import { ServicePodLogo } from '../../../../static/constants/imageConstant';

const choose = [
    {
        title: "Speed to Market",
        detail: "Our plug-and-play POD teams, equipped with frontend, backend, QA, and project management expertise, get your products to market faster. With agile processes in place, we help you iterate quickly, reducing development time and accelerating innovation.",
        logo: ServicePodLogo.PodLogoOne,
    },
    {
        title: "Custom-Built for Your Needs",
        detail: "Every business is unique. Whether you're a startup with a lean budget or an enterprise with specific project needs, our PODs are tailored to your exact requirements. Choose the right mix of developers, testers, and project managers, or create a fully customized solution.",
        logo: ServicePodLogo.PodLogoOne,
    },
    {
        title: "Cost-Effective, High-Impact",
        detail: "Pay only for the resources you need—our flexible POD plans give you the perfect balance between cost and quality. Avoid the long-term overhead of hiring full-time teams while enjoying the benefits of expert-driven, high-impact development.",
        logo: ServicePodLogo.PodLogoTwo,
    },
    {
        title: "Complete Ownership and Focus",
        detail: "Each POD operates as an independent, self-managed unit, ensuring projects are executed smoothly, on time, and within budget. Our teams work autonomously while integrating seamlessly into your existing processes, allowing you to focus on strategic growth.",
        logo: ServicePodLogo.PodLogoThree,
    },
    {
        title: "Scalable Teams for Enterprises",
        detail: "For large-scale enterprises, our Custom PODs provide the ability to scale your team as projects evolve. Add or adjust resources, including developers, project managers, and testers, based on specific needs and project phases.",
        logo: ServicePodLogo.PodLogoFour,
    },
    {
        title: "Expert Guidance for Startups",
        detail: "Startups thrive with Infominez PODs by accessing top-tier talent without full-time commitments. Our experienced teams help you build, test, and pivot faster, ensuring you stay competitive and achieve product-market fit.",
        logo: ServicePodLogo.PodLogoFive,
    },
    {
        title: "Seamless Collaboration",
        detail: "Our POD teams are fully integrated into your business, using the tools, methodologies, and workflows that align with your existing processes. This ensures smooth collaboration, clear communication, and the ability to scale efficiently.",
        logo: ServicePodLogo.PodLogoThree,
    },
    {
        title: "Built-In Project Leadership",
        detail: "With experienced project managers included in your POD, your initiatives move forward without the need for additional oversight. This reduces management complexity and ensures your projects are delivered on time and on budget.",
        logo: ServicePodLogo.PodLogoThree,
    },
    {
        title: "Accelerate Your Product Launch",
        detail: "Our on-demand POD teams, consisting of expert frontend and backend developers, QA engineers, and project managers, help you bring products to market swiftly. With agile methodologies at the core, we ensure rapid iterations, minimizing delays and maximizing innovation.",
        logo: ServicePodLogo.PodLogoThree,
    },
];

function PodChoose() {
    return (
        <div className="w-full bg-[#F2F4F4]">
            <div className="max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full py-16">
                {/* Title */}
                <h2 className="text-[3.75rem] xl:text-7xl/[7.2rem] poppins-normal text-gray-900 text-center mb-12">
                    Why Choose Infominez POD Services?
                </h2>
                
                {/* First Row */}
                <div className="relative grid grid-cols-1 md:grid-cols-3 gap-0">
                    {choose.slice(0, 3).map((service, index) => (
                        <div
                            key={index}
                            className={`flex flex-col items-center text-center p-6 relative border-gray-300 
                                ${index < 2 ? "md:border-r-2" : ""} 
                                md:border-b-2`}
                        >
                            <div className="flex justify-center mb-4">
                                <img src={service.logo} alt={service.title} className="w-20 h-20" />
                            </div>
                            <h3 className="text-[1.75rem] xl:text-[2.4rem] poppins-semibold text-[#3C436A] mb-4">
                                {service.title}
                            </h3>
                            <p className="text-[#707F96] poppins-regular text-[1.4rem]/[2.4rem] xl:text-[1.8rem]/[2.6rem]">
                                {service.detail}
                            </p>
                        </div>
                    ))}
                </div>
                
                {/* Second Row */}
                <div className="relative grid grid-cols-1 md:grid-cols-3 gap-0">
                    {choose.slice(3, 6).map((service, index) => (
                        <div
                            key={index}
                            className={`flex flex-col items-center text-center p-6 relative border-gray-300 
                                ${index < 2 ? "md:border-r-2" : ""} 
                                md:border-b-2`}
                        >
                            <div className="flex justify-center mb-4">
                                <img src={service.logo} alt={service.title} className="w-20 h-20" />
                            </div>
                            <h3 className="text-[1.75rem] xl:text-[2.4rem] poppins-semibold text-[#3C436A] mb-4">
                                {service.title}
                            </h3>
                            <p className="text-[#707F96] poppins-regular text-[1.4rem]/[2.4rem] xl:text-[1.8rem]/[2.6rem]">
                                {service.detail}
                            </p>
                        </div>
                    ))}
                </div>
                
                {/* Third Row */}
                <div className="relative grid grid-cols-1 md:grid-cols-3 gap-0">
                    {choose.slice(6, 9).map((service, index) => (
                        <div
                            key={index}
                            className={`flex flex-col items-center text-center p-6 relative border-gray-300 
                                ${index < 2 ? "md:border-r-2" : ""}`}
                        >
                            <div className="flex justify-center mb-4">
                                <img src={service.logo} alt={service.title} className="w-20 h-20" />
                            </div>
                            <h3 className="text-[1.75rem] xl:text-[2.4rem] poppins-semibold text-[#3C436A] mb-4">
                                {service.title}
                            </h3>
                            <p className="text-[#707F96] poppins-regular text-[1.4rem]/[2.4rem] xl:text-[1.8rem]/[2.6rem]">
                                {service.detail}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PodChoose;