import React from 'react'
import { ServiceCloudLogo } from '../../../../static/constants/imageConstant'

const cloudTransform = [
    {
      title:"Custom Cloud Architectures",
      serviceLogo:ServiceCloudLogo.CloudLogoOne, 
    },
    {
      title:"Cloud Infrastructure Development",
      serviceLogo:ServiceCloudLogo.CloudLogoTwo, 
    },
    {
      title:"Cloud Migration and Integration",
      serviceLogo:ServiceCloudLogo.CloudLogoThree, 
    },
    {
      title:"DevOps and Automation",
      serviceLogo:ServiceCloudLogo.CloudLogoFour,  
    },
    {
      title:"Cloud Security and Compliance",
      serviceLogo:ServiceCloudLogo.CloudLogoFive,
    },
    {
      title:"Cloud Migration and Integration",
      serviceLogo:ServiceCloudLogo.CloudLogoSix,
    }, 
]

function CloudServiceTransformation({id}) {
  return (
    <div id={id} className="h-auto bg-[#F2F4F4]">
    <div className="max-w-[1440px] mx-auto container-lg px-4 md:px-6 lg:px-16">
      <div className="poppins-normal text-[3.4rem] md:text-[4.5rem] lg:text-[5rem] text-center pt-[2.4rem] mt-20">
        <p>Transform your business through the power of the cloud.</p>
      </div>
      
      <div className="text-center text-[1.8rem]/[2.6rem] sm:text-[1.8rem]/[2.6rem] md:text-[2rem]/[2.9rem] lg:text-[2.3rem]/[3.2rem] poppins-medium text-[#8A929A] mt-8 sm:mt-10 md:mt-12 lg:mt-14">
        <p>
          At Infominez, we offer comprehensive cloud solutions that cover every stage of your cloud journey—from strategy and
          design to deployment, management, and ongoing optimization. Our solutions empower businesses to innovate,
          streamline operations, and stay competitive in an ever-evolving digital landscape.
        </p>
      </div>
      
      <div className="flex flex-wrap sm:flex-nowrap md:flex-nowrap lg:flex-nowrap justify-center w-full h-fit gap-y-8 sm:gap-y-12 mt-16 sm:mt-20 md:mt-24 lg:mt-28 mb-32">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8 sm:gap-12 md:gap-16">
          {cloudTransform.map((val, ind) => (
            <div className="w-full px-4 sm:px-6 md:px-8" key={ind}>
              <div className="flex flex-row gap-4 sm:gap-6 space-y-3 h-fit items-center">
                <div className="mt-2 sm:mt-4">
                  <img
                    src={val.serviceLogo}
                    className="w-[50px] sm:w-[55px] md:w-[60px] h-[50px] sm:h-[55px] md:h-[60px]"
                  />
                </div>
                <div className="h-fit">
                  <p className="poppins-semibold text-[2rem] sm:text-[2rem] md:text-[2.4rem]/[3.3rem] pt-4 sm:pt-6 md:pt-">
                    {val.title}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>


  )
}

export default CloudServiceTransformation
