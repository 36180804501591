import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IndustryBackground from "../../../static/assets/img/ResourcesPage/ResourceBackground.png";
import { aboutUs } from "../../../Store/actions/aboutUsActions";
import { auth } from "../../../Store/actions/authActions";
import { blogs } from "../../../Store/actions/blogActions";
import { strapi_img_base_url } from "../../../Store/constants";
import RightArrowFull from '../../common/Buttons/RightArrowFull';
import ReusableHeadingSection from "../../common/Industry_Reusable_Components/ReusableHeadingSection";
import Footer from "../FooterSec/Footer";
import ReadyToGetStartSec from "../ReadyToGetStartSec/ReadyToGetStartSec";
import { useNavigate } from "react-router";
const truncateText = (text, maxLength = 100) => {
  if (!text) return "";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};;

const BlogListingSection = () => {
  const dispatch = useDispatch();
  const dataa = useSelector((state) => state.blogReducer.data);

  useEffect(() => {
    dispatch(blogs());
    dispatch(auth());
    dispatch(aboutUs());
  }, [dispatch]);

  const navigate = useNavigate();
  const handleNavigate = (e, id) => 
    {
    e.preventDefault();
    navigate(`/blogDetails/${id}`);
  }
  return (
    <div>
      {/* Hero Section - Unchanged */}
      <div
        className="relative w-full h-[40vh] md:h-[70vh] xl:h-[70vh] bg-cover bg-center flex flex-col items-center justify-center text-center px-6"
        style={{ backgroundImage: `url(${IndustryBackground})` }}
      >
        <div className="container-mx-auto">
          <div className="absolute inset-0 bg-blue-900 opacity-50"></div>
          <div className="relative z-10 text-white w-full flex flex-col items-center text-center">
            <ReusableHeadingSection
              heading="From Your Idea to a Market-Ready Product-Faster, Better, Stronger"
              headingSize="text-5xl md:text-[3.75rem] xl:text-[5rem] 2xl:text-[6rem] "
              textColor="text-white poppins-normal"
              align="text-center"
              additionalClasses="mt-6 mx-auto max-w-[90%] xl:max-w-[85%] 2xl:max-w-[80%]"
            />
          </div>
        </div>
      </div>

      {/* Blog Cards Section */}
      <div className="max-w-[1440px] mx-auto py-36 px-4 md:px-6 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24">
          {dataa.map((post, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-lg overflow-hidden  transition-all duration-300 "
              style={{marginBottom: "1rem" }}
            >
              <div className="relative">
                <img
                  src={`${strapi_img_base_url + "/api_img"}${
                    post.attributes?.main_image?.data?.attributes.url
                  }`}
                  alt={post.attributes.main_title}
                  className="w-full h-[250px] object-cover"
                />
              </div>

              <div className="p-6">
                <div className="flex items-center justify-between mb-2">
                  {/* Author Section */}
                  <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full mr-2 overflow-hidden">
                      <img
                        src={`${strapi_img_base_url + "/api_img"}${
                          post.attributes?.author_image?.data?.attributes.url
                        }`}
                        alt="Author"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <p className="poppins-medium text-[1.375rem] font-medium">
                      {post.attributes.author_name}
                    </p>
                  </div>

                  {/* Date Section - Moved to the Right */}
                  <p className="text-gray-500 poppins-regular text-[1.375rem] ml-auto">
                    {moment(post.attributes.createdAt).format("DD-MMM-YYYY")}
                  </p>
                </div>

                <h3 className=" text-[1.375rem] xl:text-[2rem]/[2.1rem]  overflow-hidden poppins-semibold text-gray-800 mt-8 mb-8">
                  {post.attributes.main_title}
                </h3>
                <p className="text-gray-800  text-[1.2rem]/[2.1rem]  xl:text-[1.375rem] poppins-regular mb-4 h-[4.5rem] overflow-hidden">
                  {truncateText(post.attributes.sub_main_title, 120)}
                </p>
                <div className="flex items-center gap-2">

                <a onClick={(e) => handleNavigate(e, index)}>
                  <RightArrowFull/>
                  </a>
                <a className="text-[#2666CF] text-[1.375rem]/[2rem] lg:text-[1.5rem]/[2.3rem] font-medium" 
                onClick={(e) => handleNavigate(e, index)}>
                  Read More
                </a>
            </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ReadyToGetStartSec/>
      <Footer/>
    </div>
  );
};

export default BlogListingSection;
