import React from "react";
import { Outlet } from "react-router-dom";
import ReadyToGetStartSec from "../components/section/ReadyToGetStartSec/ReadyToGetStartSec";
const IndustryPage = () => {
  
  <div>
      <h1>Industry Page</h1>
      <Outlet />
      {/* ✅ Ye yahan nested routes render karega */}
  </div>
};

export default IndustryPage;
