import React from "react";
import "tailwindcss/tailwind.css";
import kites from "../../../static/assets/img/vision-mission-images/kites.png";
import mission from "../../../static/assets/img/vision-mission-images/mission.png";
import vision from "../../../static/assets/img/vision-mission-images/vision.png";

const VisionMission = () => {
  return (
    <div className="bg-white py-32 w-full overflow-visible">
      <div className="nav-container flex max-w-[1440px] mx-auto container-lg py-[1.2rem] px-4 md:px-6 w-full overflow-visible">
        <div className="flex flex-col md:flex-row gap-6 w-full">
          {/* Left Content Section */}
          <div className="flex flex-col gap-6 w-full md:w-2/3">
            {/* Section Heading */}
            <h1 className="text-[3.75rem] xl:text-[6rem] poppins-normal text-left text-gray-900 mb-4">
              Our Vision and Mission Statement
            </h1>
            
            {/* Vision Section */}
            <div className="bg-gray-100 p-6 rounded-xl flex flex-row items-center py-8 gap-4 shadow-md">
              <div className="px-4">
                <img
                  src={vision}
                  alt="Vision Icon"
                  className="w-32 h-24 md:w-36 md:h-32 self-center"
                />
              </div>
              <div className="text-left flex-1">
                <h2 className="text-[2.1875rem] xl:text-[3.5rem] poppins-medium py-4 text-[#121212]">
                  Our Vision
                </h2>
                <p className="text-[#707F96] poppins-regular text-[1.8rem] py-4 xl:text-[2.2rem] mt-2">
                  To be the catalyst for fearless innovation, empowering businesses
                  to harness the power of technology, transform challenges into
                  opportunities, and lead in a digital-first world. We envision a
                  future where every organization thrives through sustainable growth
                  and continuous transformation.
                </p>
              </div>
            </div>
            
            {/* Mission Section */}
            <div className="bg-gray-100 p-6 rounded-xl flex flex-row items-center py-8 gap-6 shadow-md">
              <div className="px-4">
                <img
                  src={mission}
                  alt="Mission Icon"
                  className="w-28 h-28 md:w-32 md:h-32 self-center"
                />
              </div>
              <div className="flex flex-col flex-1 text-left">
                <h2 className="text-[2.1875rem] xl:text-[3.5rem] py-4 poppins-medium text-[#121212]">
                  Our Mission
                </h2>
                <p className="text-[#707F96] poppins-regular py-4 text-[1.8rem] xl:text-[2.2rem] mt-2">
                  To deliver secure, cutting-edge digital solutions that inspire
                  innovation and enable our customers to achieve their greatest
                  ambitions. We are committed to:
                </p>
                <ul className="text-[#707F96] poppins-regular text-[1.8rem] xl:text-[2.2rem]  py-4 list-disc list-inside">
                  <li>
                    Building trust through technology that ensures safety and
                    resilience.
                  </li>
                  <li>
                    Driving progress by redefining industries and fostering
                    long-term success.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* Right Image Section */}
          <div className="hidden md:flex items-center justify-center w-1/3 relative">
            <img
              src={kites}
              alt="Paper Planes"
              className="w-full max-w-[250px] h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;