import { actionTypes } from "../types";
 
export const auth = (payload, callback) => ({
    type:actionTypes.AUTH_REQUEST,
    payload,
    callback
});
 
export const authSuccess = (payload) => ({
    type:actionTypes.AUTH_SUCCESS,
    payload,
});
 
export const authFailure = (payload) => ({
    type:actionTypes.AUTH_FAILURE,
    payload,
});
 
export const authReset = (payload) => ({
    type:actionTypes.AUTH_RESET,
    payload,
});
 
export const authResetAll = (payload) => ({
    type:actionTypes.AUTH_RESET_ALL,
    payload,
});