import React from "react";
import { useNavigate } from "react-router-dom";
import TeamPhoto1 from "../../../static/assets/img/LifeAtInfominez/TeamPhoto1.png";
import TeamPhoto2 from "../../../static/assets/img/LifeAtInfominez/TeamPhoto2.png";
import virtual from "../../../static/assets/img/LifeAtInfominez/VirtualTeamMeeting.png";

const LifeAtInfominez = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full py-32 relative bg-[#F2F4F4] overflow-hidden">
      {/* Remove px-8 to eliminate horizontal padding */}

      {/* Use the same container structure as your navbar */}
      <div className="w-full max-w-[1440px] mx-auto px-4 md:px-6 lg:px-8">
        {/* Watermark Text */}
        <h2 className="hidden lg:block [@media(min-width:1400px)]:hidden absolute text-[8rem] lg:text-[86px] poppins-bold text-[#CCCCCC] opacity-40 w-full text-left left-0 right-0 top-[90%] -translate-y-1/2 leading-none whitespace-nowrap z-30 pointer-events-none mt-[-70px] ml-[20px]">
          Life at Infominez
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start mb-12 relative z-20">
          {/* Left Side Text Section - Removed max-w-[90%] to use full width */}
          <div className="text-left space-y-6">
            <h1 className="text-left text-[3.75rem] xl:text-[6rem] poppins-normal text-gray-700">
              Life at Infominez
            </h1>
            <p className="text-left text-[1.8rem] xl:text-[2.2rem] poppins-regular text-[#707F96] leading-relaxed">
              At Infominez, we foster a culture of innovation, collaboration,
              and growth. Our team thrives in a dynamic environment where
              creativity is encouraged, ideas are valued, and every challenge is
              an opportunity to learn. Whether it's through mentorship, hands-on
              projects, or upskilling, we are committed to helping each
              individual reach their full potential, making Infominez not just a
              workplace, but a community where talent grows and success
              flourishes.
            </p>
            <button
              className="bg-white border-2 border-[#00D47E] text-black text-[1.6rem] poppins-medium px-14 py-5 rounded-md transition-colors hover:bg-[#00D47E] hover:text-white"
              onClick={() => navigate("/contact-us")}
            >
              Know More
            </button>
          </div>

          {/* Right Side Image Section */}
          <div className="grid grid-cols-2 gap-4 w-full">
            <img
              src={virtual}
              alt="Virtual team meeting"
              className="w-full h-auto max-h-[220px] rounded-0 shadow-md"
            />
            <img
              src={TeamPhoto1}
              alt="Team photo 1"
              className="w-full h-auto max-h-[220px] object-contain rounded-0 shadow-md"
            />
            <img
              src={TeamPhoto2}
              alt="Team photo 2"
              className="col-span-2 w-full h-auto max-h-[420px] object-cover rounded-0 shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeAtInfominez;
