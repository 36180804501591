import { call, put, takeLatest } from "redux-saga/effects";
import { contactUsFailure, contactUsSuccess } from "../actions/contactUsActions";
import { base_url, endpoints, strapi_base_url } from "../constants";
import { actionTypes } from "../types";
import { AxiosService } from "../services";

function* contactUsCall({ payload, callback }) {
  try {
    const response = yield call(AxiosService,
      {
        method: 'POST',
        base_url: base_url,
        endPoint: endpoints.contactUs,
        data: payload,
        isAuth: true,
        ContentType: 'multipart/form-data',
        // isRecaptcha: payload.header,
      });

      console.log("contactUsCall response :" , response);
      

      yield put(contactUsSuccess({
        status: response?.status,
        data: response?.data?.data,
        message: response?.message
      })
    )
    if (callback) {
      callback(
        response?.status,
        response?.response,
        response?.message
      ); // Pass the response data to the callback
    }
  } catch (error) {
    console.log("contactUsCall error :", error);
    
    yield put(contactUsFailure({
      status: error.response?.status,
      message: error.response?.message
    }));
  }
}

function* contactUsSaga() {
  yield takeLatest(actionTypes.CONTACT_US_REQUEST, contactUsCall);
}

export default contactUsSaga;
// import { put, call, takeLatest } from 'redux-saga/effects';
// import { base_url, endpoints } from '../constants';
// import { AxiosService } from '../services';
// import { actionTypes } from '../types';
// import { contactUsv2Failure, contactUsv2Success } from '../actions/contactUsV2Actions';

// function* contactUsv2({ payload, callback }) {
//     try {
//         const response = yield call(AxiosService, {
//             method: 'post',
//             base_url: base_url,
//             endPoint: endpoints.contactUsV2,
//             data: payload,
//             isAuth: false,
//             ContentType: 'multipart/form-data',
//         });

//         console.log('====================================');
//         console.log("CONTACT_US_V2_SUCCESS status :", response.status);
//         console.log("CONTACT_US_V2_SUCCESS data :", response.data);
//         console.log("CONTACT_US_V2_SUCCESS message :", response.data.message);
//         console.log('====================================');
//         yield put(contactUsv2Success({
//             status: response?.status,
//             data: response?.data,
//             message: response?.data.message
//         })
//         )
//     } catch (error) {
//         console.log('====================================');
//         console.log("CONTACT_US_V2_FAILURE error :", error.response?.status);
//         console.log("CONTACT_US_V2_FAILURE message :", error.response?.data.message);
//         console.log("CONTACT_US_V2_FAILURE error :", error);
//         console.log('====================================');
//         yield put(contactUsv2Failure({
//             status: error.response?.status,
//             message: error.response?.message
//         }));
//     }
// }

// function* contactUsv2Saga() {
//     yield takeLatest(actionTypes.CONTACT_US_V2_REQUEST, contactUsv2);
// }

// export default contactUsv2Saga;