import React from 'react'
import { serviceImg, serviceLogo } from '../../../static/constants/imageConstant'
import CaseStudy from '../../common/Cards/CaseStudy'
import ServiceGrowth from '../../common/ServiceCommons/ServiceGrowth'
import ServiceHeading from '../../common/ServiceCommons/ServiceHeading'
import ServiceInclude from '../../common/ServiceCommons/ServiceInclude'
import ServiceTransformation from '../../common/ServiceCommons/ServiceTransformation'
import ClientTestimonialSec from '../ClientTestimonialSec/ClientTestimonialSec'
import Footer from '../FooterSec/Footer'
import LatestInsightSec from '../LatestInsightSec/LatestInsightSec'
import ReadyToGetStartSec from '../ReadyToGetStartSec/ReadyToGetStartSec'

const bgColor="bg-white";

const serviceHeading = "Accelerate Your Vision into a Market-Ready Product";
const serviceboldHeading = "Faster, Better, Stronger";

const transformHeadingOne="Transform Your Vision into a Thriving Reality";

const serviceLineOne = "Our comprehensive product engineering services turn your ideas into reality, utilizing advanced innovations to boost efficiency and ensure exceptional customer satisfaction.";

const growthHeadingOne = "Our Product Engineering";
const growthHeadingExtra = "Services";

const includeHeading = "Why Partner with Infominez for End-to-End Success";

const growthImg = serviceImg.productServiceImg;
const includeImg = serviceImg.productPartner;

const transform = [
    {
      title:"End-to-End Expertise",
      detail:"From concept to launch, we handle everything, so you can focus on growing your business.", 
      serviceLogo:serviceLogo.serviceLogoOne, 
    },
    {
      title:"Future-Proof Innovation",
      detail:"Stay ahead in the competitive IT landscape with our team of experts injecting cutting-edge technology into your product.", 
      serviceLogo:serviceLogo.serviceLogoTwo, 
    },
    {
      title:"Accelerate Time-to-Market",
      detail:"Get your innovative product into users' hands faster with our agile development process, saving you 30-40% in development time", 
      serviceLogo:serviceLogo.serviceLogoThree, 
    },
    {
      title:"Intuitive User Experiences",
      detail:"Drive engagement, customer loyalty, and conversion rates with our seamless, user-friendly interfaces designed to exceed expectations.",
      serviceLogo:serviceLogo.serviceLogoFour,  
    },
    {
      title:"Cost-Effective Solutions",
      detail:"Enjoy transparent pricing, reduced costs, and a 20-30% decrease in development expenses with our efficient solutions.",  
      serviceLogo:serviceLogo.serviceLogoFive,
    },
    {
      title:"Partnership Beyond Launch",
      detail:"Rely on our ongoing maintenance and support to ensure your product continues to thrive, adapt, and evolve with your users' needs.",  
      serviceLogo:serviceLogo.serviceLogoSix,
    },  
] 

const growth=[
    {
        title:"Product Development",
        detailOne:"Full product development using state-of-the-art tech, agile methods, and clear communication.",
    },
    {
        title:"UI/UX Design",
        detailOne:"Intuitive and visually appealing user interfaces to boost engagement and satisfaction.",
    },
    {
        title:"Quality Assurance",
        detailOne:"Ensuring your product works flawlessly, scales securely, and delivers user-friendly experiences.",
    },
    {
        title:"DevOps and Continuous Integration",
        detailOne:"Seamless user experiences that foster customer loyalty.",
    },
    {
        title:"Maintenance and Support",
        detailOne:"Ongoing maintenance and support services to ensure your product stays up-to-date and responsive to changing market demands.",
    },
]

const include=[
    {
        title:"Expertise and Experience",
        detailOne:"Leverage our extensive industry knowledge to ensure project success and minimize risks.",
    },
    {
        title:"Cost-Effective Solutions",
        detailOne:"Benefit from efficient development and competitive pricing that align with your budget and goals.",
    },
    {
        title:"Innovative Solutions",
        detailOne:"Access proven technologies and innovative strategies that help keep your product competitive and future-ready.",
    },
    {
        title:"Adaptive Project Delivery",
        detailOne:"Experience agile project management that quickly adapts to evolving requirements while staying on time and within budget.",
    },
    {
        title:"Collaboration & Transparency",
        detailOne:"Enjoy open communication and a collaborative approach that keeps your vision at the forefront.",
    },    
]

function ProductService() 
{
  return (
    <div>
        <ServiceHeading serviceHeading={serviceHeading} serviceboldHeading={serviceboldHeading} serviceLineOne={serviceLineOne}/>
        <ServiceTransformation id="transformation" transform={transform} buttonText={"Transform Your Idea Today"} 
        transformHeadingOne={transformHeadingOne} bgBack={"bg-[#F2F4F4]"}/>
        <ServiceGrowth growth={growth} growthHeadingOne={growthHeadingOne} growthHeadingExtra={growthHeadingExtra} yes={true}
         growthImg={growthImg} growthHeadingTwoColor={"text-sky-blue-500"} showGrowthButton = {"visible"} w={"lg:w-[160rem]"}/>
        <ServiceInclude include={include} includeHeading={includeHeading} includeImg={includeImg} 
        showIncludeButton = {"visible"} w={"lg:w-[140rem]"} bgBack={"bg-[#F2F4F4]"}/>
        <CaseStudy bgColor={bgColor}/>
        <ClientTestimonialSec/>
        <LatestInsightSec/>
        <ReadyToGetStartSec id="ready" bgColor={bgColor}/>
        <Footer/>
    </div>
  )
}

export default ProductService;