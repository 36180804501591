import React from 'react'
import { FaArrowRight } from 'react-icons/fa6'

function RightArrowFull() 
{
  return (
    <button className="w-16 h-16 rounded-full bg-white border-2 border-[#00D47E] hover:bg-[#00D47E] hover:text-white">
        <FaArrowRight className="size-10 pl-4" />
        </button>
  )
}

export default RightArrowFull
