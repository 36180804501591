import React from "react";
import { Outlet } from "react-router-dom";

const ServicesPage = () => {
  
  <div>
      <h1>Services Page</h1>
      <Outlet />
      {/* ✅ Ye yahan nested routes render karega */}
  </div>
};

export default ServicesPage;