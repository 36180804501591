import React from "react";

const ReusableParagraph = ({ text, maxWidth = "max-w-[80%]", textSize = "text-2xl", textColor = "text-gray-700", additionalClasses = "" }) => {
  return (
    <div className={`w-full px-6 py-32 text-center bg-white ${textColor} ${additionalClasses}`}>
      <div className="container-lg">
      <p className={`${maxWidth} mx-auto ${textSize} poppins-medium text-[#8A929A] text-[2rem] xl:text-[2.5rem]`}>
        {text}
      </p>
      </div>
    </div>
  );
};

export default ReusableParagraph;
