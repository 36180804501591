import CaseStudy from "../components/common/Cards/CaseStudy";
import AboutUs from "../components/section/AboutUs/AboutUs";
import FeatureSection from "../components/section/AboutUs/FeatureSection";
import ClientTestimonialSec from "../components/section/ClientTestimonialSec/ClientTestimonialSec";
import Footer from "../components/section/FooterSec/Footer";
import HeroSection from "../components/section/HeroSection/HeroSection";
import IndustryCards from "../components/section/IndustryCard/IndustryCards";
import LatestInsightSec from "../components/section/LatestInsightSec/LatestInsightSec";
import OurTrustedClientsSec from "../components/section/OurTrustedClientsSec/OurTrustedClients";
import ProcessModel from "../components/section/ProcessModel/ProcessModel";
import ReadyToGetStartSec from "../components/section/ReadyToGetStartSec/ReadyToGetStartSec";
import ServiceSection from "../components/section/ServiceSection.jsx/ServiceSection";

const Home = () =>
{
    return(
        <div className="w-full">
            <HeroSection/>
            <ServiceSection/>
            <IndustryCards/>
            <ProcessModel/>
            <AboutUs/>
            <FeatureSection/>
            {/* <HomeLandingSec />
            <HomeServiceSec />
            {/* Mayank Codes */}
            <ClientTestimonialSec/>
            <LatestInsightSec/>
            <CaseStudy bgBack={"bg-[#F2F4F4]"}/>
            <OurTrustedClientsSec/>
            <ReadyToGetStartSec bgBack={"bg-[#F2F4F4]"}/>
            <Footer/>
        </div>

    )
}

export default Home;
