import React from 'react';
import { TiTick } from 'react-icons/ti';

function LeftStyle({title, image, content}) 
{
  return (
    <div className="h-fit mt-24 bg-[#F4F7FA] w-full">
      {/* Exact same container as navbar */}
      <div className="nav-container max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
        <div className="poppins-semibold text-center text-[2.5rem] sm:text-[2.7rem] xl:text-[3rem] my-20">
          <p>{title}</p>
        </div>

        <div className="flex flex-col lg:flex-row justify-between mt-10 mb-20 gap-10">
          <div className="w-full lg:w-1/2">
            <img src={image} className="w-full h-auto object-contain" alt="Left section" />
          </div>
         
          <div className="text-[#333840] text-[1.5rem] sm:text-[1.8rem] md:text-[2rem] poppins-regular flex flex-col gap-y-10 h-fit w-full lg:w-1/2">
            {
            content.map((val, index) => {
              return( 
                <div key={index} className="flex gap-x-5 w-full">
                  <div className="flex-shrink-0">
                    <TiTick className="size-12 text-[#0478FF] md:size-16 sm:size-10" />
                  </div>
                  <div>
                    <p>{val}</p>
                  </div>
                </div>
              )})
            }
          </div>
        </div>
      </div>
    </div>

  )
}

export default LeftStyle