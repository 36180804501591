import React from "react";
import { Route, Routes } from "react-router";
import AIMLService from "../components/section/AllServicesSec/AIMLService";
import CloudEngineeringService from "../components/section/AllServicesSec/CloudEngineeringService";
import DataEngineeringService from "../components/section/AllServicesSec/DataEngineeringService";
import PodService from "../components/section/AllServicesSec/PodService";
import ProductService from "../components/section/AllServicesSec/ProductService";
import CarrerPage from "../components/section/CarrerPage/CarrerPage";
import GetInTouch from "../components/section/ContactUs/GetInTouch";
import BlogDetails from "../components/section/Details/BlogDetails";
import CaseStudyDetails from "../components/section/Details/CaseStudyDetails";
import EcommerceRetail from "../components/section/IndustryDropDownComponents/EcommerceRetail";
import EdutechAndLearning from "../components/section/IndustryDropDownComponents/EdutechAndLearning";
import FintechComponent from "../components/section/IndustryDropDownComponents/Fintech";
import HealthCareAndWellness from "../components/section/IndustryDropDownComponents/HealthCare";
import BlogListingSection from "../components/section/ResourcesSection/BlogListingSection";
import CaseStudyListingSection from "../components/section/ResourcesSection/CaseStudyListingSection";
import AboutPage from "../pages/AboutPage";
import Home from "../pages/Home";
import IndustryPage from "../pages/IndustryPage";
import ResourcePage from "../pages/ResourcePage";
import ServicesPage from "../pages/ServicesPage";

function AppRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Home/>} />
      
      {/* Mayank's Code */}
      {/* CarrierDetails, BlogDetails */}
      <Route path="/blogDetails/:id" element={<BlogDetails/>} />
      <Route path="/caseStudyDetails/:id" element={<CaseStudyDetails/>} />
      <Route path="/careers" element={<CarrerPage/>} />
      

      {/* Aishwary's Code */}
      <Route path="/about-us" element={<AboutPage />} />
      <Route path="/contact-us" element={<GetInTouch/>}/>
      {/* <Route path="/fintech-banking"element={<IndustryPage/>}/>
      <Route path="/ecommerce-retail"element={<IndustryPage/>}/> */}
      <Route path="/industry/*" element={<IndustryPage />} /> 
      <Route path="fintech-banking" element={<FintechComponent title="Fintech Industry" />} />
      <Route path="ecommerce-retail" element={<EcommerceRetail title="Ecommerce Industry"/>}/>
      <Route path="edutech-learning" element={<EdutechAndLearning title="Edutech"/>}/>
      <Route path="health-wellness" element={<HealthCareAndWellness title="HealthCare"/>}/>
      <Route path="/resources/*"element={<ResourcePage/>}/>
      <Route path="blog-section" element={<BlogListingSection title="Blogs"/>}/>

      {/* CaseStudy and CaseStudyDetails */}
      <Route path="case-studies"element={<CaseStudyListingSection title="CaseStudy"/>}/>
      <Route path="/services/*"element={<ServicesPage/>}/>
      <Route path="/product-engineering" element={<ProductService title="ProductEngineering"/>}/>
      <Route path="/ai-ml-engineering" element={<AIMLService title="AIMLService"/>}/>
      <Route path="/data-engineering" element={<DataEngineeringService title="DataEngineering"/>}/>
      <Route path="/cloud-engineering" element={<CloudEngineeringService title="CloudEngineering"/>}/>
      <Route path="/pod-services" element={<PodService title="PODEngineering"/>}/>

    </Routes>

  );
}

export default AppRoutes;
