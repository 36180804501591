import React, { useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { caseStudy } from '../../../Store/actions/caseStudyAction';
import { strapi_base_url } from '../../../Store/constants';
import PrimaryButton from '../Buttons/PrimaryButton';

const CaseStudy = ({bgBack}) => 
{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  
  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const activePage = window.location.href.split("/")[window.location.href.split("/").length - 1];

  const caseStudiesData = useSelector((state) => state.caseStudyReducer.data) || [];
  const sliderRef = useRef(null);
  const [currentPage, setCurrentPage] = useState("");

  console.log("caseStudy",caseStudiesData);

  const renderPageContent = (activePage) => {
    switch (activePage) {
      case "cloud-engineering":
        return 'Cloud Engineering';

      case "services-product":
        return 'Product Engineering';

      case "ai_ml_services":
        return 'AI & ML';

      case "data-engineering":
        return 'Data Engineering';

      case "cloud-engineering":
        return 'Cloud Engineering';

      case "industry-fintech-page":
        return 'Fintech & Banking';

      case "industry-e-commerce-retial":
        return 'E-Commerce & Retail';

      case "industry-edutech":
        return 'Edutech And Learning';

      case "industry-health-care":
        return 'Health And Wellness';

      default:
        return 'All';
    }
  }

  console.log("currentPage: " + currentPage);

  const filteredData = currentPage === "All" ? caseStudiesData : caseStudiesData?.filter((data) => {
    console.log("Data :- ", data?.attributes?.industries?.data);

    let activeData = [];

    if(currentPage === "Fintech & Banking" || currentPage === "E-Commerce & Retail" || currentPage === "Edutech And Learning" || currentPage === "Health And Wellness" ){
     activeData = data?.attributes?.industries?.data || [];
    } else {
     activeData = data?.attributes?.services?.data || [];

    }
    // const serviceName = data?.attributes?.services;

    // Check if any industry in the array matches `currentPage`
    const match = activeData.some(item => item?.attributes?.Name === currentPage);

    console.log("Industry Matches: ", match);
    // console.log("Service Name: ", serviceName);

    return match;
  });


  useEffect(() => {
    const data = renderPageContent(activePage)
    setCurrentPage(data)
  }, [activePage, caseStudiesData])

  useEffect(() => {
    dispatch(caseStudy());
  }, [dispatch]);


  const gotoNext = () => {
    sliderRef.current.slickNext();
  }

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  }

  const PrevArrow = ({ className, onClick }) => (
    <div className={`${className} flex justify-center items-center`} onClick={onClick}>
      <button className="w-[5rem] h-[5rem] rounded-full border-2 border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
        <FaArrowLeft size={20} />
      </button>
    </div>
  );

  const NextArrow = ({ className, onClick }) => (
    <div className={`${className} flex justify-center items-center`} onClick={onClick}>
      <button className="w-[5rem] h-[5rem] rounded-full border-2 border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
        <FaArrowRight size={20} />
      </button>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
    centerPadding: '0',
  };

  return (
    <div className={`${activePage === "" ? "bg-[#F2F4F4]" : "bg-white"} pt-10 overflow-hidden`}>
      <div className="container-lg max-w-[1440px] mx-auto px-4 md:px-6 w-full overflow-hidden">
        <div className="pt-[2rem] pb-50 relative">
          <div className={`absolute top-[1rem] right-0 flex justify-end gap-8 z-[10] ${filteredData?.length <= 1 && "hidden"}`}>
            <button
                className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
                onClick={gotoPrev}
            >
              <FaArrowLeft size={20}/>
            </button>
            <button
                className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
                onClick={gotoNext}
            >
              <FaArrowRight size={20}/>
            </button>
          </div>
          {filteredData?.length === 1 ?
              filteredData?.map((caseStudy, i) => 
              {
                return (
                    <div key={i} className="flex">
                      <div className="relative w-full h-fit flex flex-col md:flex-row">
                        <div className="md:w-[50%]">
                          <img
                              src={strapi_base_url+"_img"+caseStudy?.attributes?.thumbnail_image?.data?.attributes?.url || '/placeholder-image.jpg'}
                              className="object-cover rounded-2xl"
                              alt="Case Study"
                          />
                        </div>
                        <div className="md:flex justify-center items-center  mx-[3.5rem]">
                          <div
                              className="h-[0rem] md:h-[59rem] w-[0rem] md:w-[0.2rem] bg-[#C7C7C7] flex justify-center">
                            <div
                                className="absolute top-[8rem] w-[0rem] md:w-[0.8rem] h-[0rem] md:h-[16rem] rounded-full bg-[#00D47E]"></div>
                          </div>
                        </div>
                        <div className="md:w-[40%] mt-[1rem] md:mt-0 w-full">
                            <h1 className="text-[#010101] text-[6rem] poppins-normal">
                              Case Study
                            </h1>
                          <div className="poppins-semibold w-full">
                            <div className="text-[4.5rem]">{truncateText(caseStudy?.attributes?.Title, 29)}</div>
                            <div className="w-full h-[0.2rem] mt-[2rem] mb-[4rem] bg-[#C7C7C7]"></div>
                          
                            <div className="poppins-regular text-[#5D656D] text-[2.2rem]">
                              {truncateText(caseStudy?.attributes?.overview, 100)}
                            </div>
                            <div className="mt-[1rem]">
                              <PrimaryButton
                                  fontWeight={"poppins-medium"}
                                  bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
                                  titleColor={`hover:text-white text-[#2B2B2B]`}
                                  height="h-[5.7rem]"
                                  width="w-[25.7rem]"
                                  title="Read More"
                                  textSize="text-[2.3rem]"
                                  onClick={() => navigate(`/case-studies`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              })
              :
              <Slider ref={sliderRef} {...settings} className="mt-[4rem] md:mt-[0rem] max-w-[1440px] mx-auto px-4 md:px-6 w-full overflow-hidden">
                {filteredData?.map((caseStudy, i) => {
                  const {id, attributes} = caseStudy;

                  console.log("attributes?.thumbhnail_image?.data?.attributes?.url", attributes?.thumbnail_image?.data?.attributes?.url)

                  return (
                  <div key={i} className="flex">
                        <div className="relative w-full my-[2rem] flex flex-col md:flex-row">
                          <div className="md:w-[50%]">
                            <img
                                src={strapi_base_url+"_img"+attributes?.thumbnail_image?.data?.attributes?.url || '/placeholder-image.jpg'}
                                className="object-cover rounded-2xl"
                                alt="Case Study"
                            />
                          </div>
                          <div className="md:flex justify-center items-center h-full mx-[3.5rem]">
                            <div
                                className="h-[0rem] md:h-[52rem] w-[0rem] md:w-[0.2rem] bg-[#C7C7C7] flex justify-center">
                              <div
                                  className="absolute top-[8rem] w-[0rem] md:w-[0.8rem] h-[0rem] md:h-[16rem] rounded-full bg-[#00D47E]"></div>
                            </div>
                          </div>
                          <div className="md:w-[40%] mt-[1rem] md:mt-0 w-full">
                            <h1 className="text-[#010101] text-[6rem] poppins-normal">
                              Case Study
                            </h1>
                            <div className="poppins-medium w-full">
                              <div className="text-[4.5rem]">{truncateText(attributes?.Title, 29)}</div>
                              <div className="w-full h-[0.2rem] mt-[2rem] mb-[4rem] bg-[#C7C7C7]"></div>
                              <div className="poppins-regular text-[#5D656D] text-[2.2rem]">
                                {truncateText(attributes?.overview, 100)}
                              </div>
                              <div className="mt-[1rem]">
                                <PrimaryButton
                                    fontWeight={"poppins-medium"}
                                    bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
                                    titleColor={`hover:text-white text-[#2B2B2B]`}
                                    height="h-[5.7rem]"
                                    width="w-[25.7rem]"
                                    title="Read More"
                                    textSize="text-[2.3rem]"
                                    onClick={() => navigate(`/case-studies`)}
                                />
                                {console.log(id)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </Slider>
          }
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
