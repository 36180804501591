import React, { useState } from "react";
import ajit from "../../../static/assets/img/AboutPage/ajit_maya.png";
import Youtube from "../../../static/assets/img/AboutPage/Youtube.png";

const StorySection = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <div className="w-full bg-[#F2F4F4] flex flex-col justify-center py-32">
      {/* Using the same container style as navbar for alignment */}
      <div className="nav-container max-w-[1440px] mx-auto px-4 md:px-6 container-lg w-full">
        <div className="w-full mx-auto">
          {/* Title and Description */}
          <div className="mb-6 md:mb-8 text-left max-w-full">
            <h2 className="text-[3.75rem] xl:text-[6rem] poppins-normal text-start mb-4">
              Our Story
            </h2>
            <p className="text-[2rem] xl:text-[2.2rem] text-[#8A929A] poppins-semibold mb-16">
              Infominez was founded in 2021 by a group of passionate entrepreneurs
              who saw an opportunity to bridge the gap between technology and
              business. Since then, we've grown from a small startup to a leading
              innovator in fintech, blockchain, and data analytics. Our journey
              has been marked by numerous milestones, including:
            </p>
          </div>

          {/* Content Layout */}
          <div className="flex flex-col md:flex-row items-center gap-8 md:gap-i16 w-full">
            {/* Video or Image Section */}
            <div className="w-full md:flex-1 relative py-0 sm:py-0 px-0 sm:px-0 md:px-0 rounded-none shadow-lg">
              {/* {isVideoPlaying ? (
                <iframe
                  className="w-full aspect-video md:h-[450px] rounded-none"
                  src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
                  title="YouTube Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <div
                  className="relative cursor-pointer"
                  onClick={() => setIsVideoPlaying(true)}
                > */}
                  <img
                    src={ajit}
                    alt="Ajit Maya - CEO Infominez"
                    className="w-full h-auto max-h-[400px] md:max-h-[450px] xl:max-h-[500px] bject-contain rounded-none"
                  />
                  {/* <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src={Youtube}
                      alt="Play"
                      className="w-16 h-16 sm:w-20 sm:h-20 md:w-28 md:h-28"
                    /> */}
                  {/* </div>
                </div> */}
              {/* )} */}
            </div>

            {/* Bullet Points Section - Keeping Original Text Styling */}
            <div className="w-full md:flex-1 flex flex-col justify-between h-auto text-left mt-6 md:mt-0">
              <ul className="text-[#8A929A] space-y-14 text-[1.8rem] xl:text-[2.2rem] poppins-regular">
                <li className="flex gap-3">
                  <span className="min-w-[8px] h-[8px] bg-black rounded-full mt-[10px]"></span>
                  <span text>
                    We cultivate homegrown heroes from Indore and beyond,
                    nurturing their skills from local saplings to global
                    shade-givers.
                  </span>
                </li>
                <li className="flex gap-3">
                  <span className="min-w-[8px] h-[8px] bg-black rounded-full mt-[10px]"></span>
                  <span>
                    80% of our team: We believe in empowering the youth and tier-3
                    talent, giving them a shot at world-changing projects.
                  </span>
                </li>
                <li className="flex gap-3">
                  <span className="min-w-[8px] h-[8px] bg-black rounded-full mt-[10px]"></span>
                  <span>
                    Our impact is brilliant! Our training programs boast a 90%
                    success rate, placing individuals in dream roles.
                  </span>
                </li>
                <li className="flex gap-3">
                  <span className="min-w-[8px] h-[8px] bg-black rounded-full mt-[10px]"></span>
                  <span>
                    We're building a vibrant ecosystem of training programs,
                    mentorship, and collaboration.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySection;