import React from 'react';
import Growth from "../../../static/assets/img/AboutImage/GrowthCatalyst.png";
import Technology from "../../../static/assets/img/AboutImage/technology-mastery.png";
import Innovation from "../../../static/assets/img/AboutImage/Innovation-hub.png";

const FeatureCard = ({ imageUrl, title, description }) => (
  <div className="bg-white flex items-start gap-2 shadow-lg border p-6 rounded-lg flex-col flex-1">
    <h2 className="text-[2rem] xl:text-[2.8rem] poppins-semibold text-[#3C436A] text-left">{title}</h2>
    <div className="flex items-start gap-4">
      <img src={imageUrl} alt={title} className="min-w-24 mt-4 w-24 min-h-24 object-contain" />
      <p className="text-[#5D656D] poppins-regular text-[1.8rem] xl:text-[2rem] leading-relaxed mt-4 text-left">
        {description}
      </p>
    </div>
  </div>
);

const FeatureSection = () => {
  const features = [
    {
      imageUrl: Technology,
      title: "Technological Mastery",
      description: "Our team champions deep expertise across diverse tech domains. We leverage this knowledge to build robust, scalable, and future-ready solutions tailored to your business needs."
    },
    {
      imageUrl: Innovation,
      title: "Innovation Hub",
      description: "We're perpetually pioneering new ways to harness next-generation tech for your advantage. Our innovative mindset ensures you're always ahead of the curve in a rapidly evolving digital landscape."
    },
    {
      imageUrl: Growth,
      title: "Your Growth Catalyst",
      description: "We prioritize understanding your unique ambitions to engineer solutions that propel you toward success. By aligning our strategies with your goals, we create impactful outcomes that accelerate your growth journey."
    }
  ];

  return (
    <div className="bg-[#F2F4F4] py-16">
      {/* Using the same container style as navbar for alignment */}
      <div className="nav-container max-w-[1440px] mx-auto px-4 md:px-6 container-lg w-full">
        <div className="flex flex-col items-center text-center">
          <h1 className="text-[3.75rem] xl:text-[6rem] text-center w-full poppins-normal mb-24">
            Why Infominez Stands Out:
          </h1>
          <div className="flex justify-center gap-8 w-full flex-wrap md:flex-nowrap flex-col md:flex-row">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                imageUrl={feature.imageUrl}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;