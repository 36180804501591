import React from 'react'
import { FaCircle } from 'react-icons/fa6'
import PrimaryButton from '../../Buttons/PrimaryButton'

function CloudServiceDetails({cloudHeading, cloudHeadingDetail, cloudSubHeading, cloudDetails, bgColor, showCloudButton, classNames, textSize, buttonTitle, bgBack}) 
{
  return (
    <div className={`h-fit ${bgColor ? bgColor : `bg-[#F2F4F4]`}`}>
      <div className="max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full">
        <div className="poppins-normal text-[3.4rem] md:text-[4.5rem] lg:text-[5rem] text-center pt-[2.4rem]">
          <p>{cloudHeading}</p> 
        </div>

        <div className="text-center text-[1.8rem]/[2.5rem] sm:text-[1.5rem]/[2.2rem] md:text-[2.4rem]/[3.3rem] poppins-medium text-[#8A929A] mt-6 md:mt-8">
          <p>{cloudHeadingDetail}</p>
        </div>

        <div className={`${classNames} mt-10 md:mt-12`}>
          <p className={`poppins-semibold ${textSize ? textSize:"text-[2rem]/[2.7rem] md:text-[2.4rem]/[3.3rem]"}`}>{cloudSubHeading}</p>
        </div>

        <div className="flex flex-wrap w-full h-fit gap-y-10 md:gap-y-16 mt-12 md:mt-20 ml-4 md:ml-10 justify-center md:justify-start">
          {
            cloudDetails.map((val,ind)=>{
              return(
                <div className="w-full md:w-1/2 lg:w-1/2 h-fit px-4" key={ind}>
                  <div className="flex flex-row gap-4 md:gap-6 space-y-3 md:space-y-5 h-fit">
                    <FaCircle className="text-[#2666CF] mt-3 md:mt-9 size-5 md:size-6 flex-shrink-0"/>
                    <div className="h-fit">
                      <p className="poppins-semibold text-[2rem]/[2.8rem] md:text-[2.4rem]/[3.4rem] text-[#3C436A]">{val.title}</p>
                      <p className="poppins-regular text-[1.5rem] sm:text-[1.8rem] lg:text-[2rem]/[3rem] text-[#8A929A] pt-2 md:pt-3">{val.detailOne}</p>
                    </div>
                  </div>
                </div>
              )})
          }
        </div>

        <div className="flex justify-center mt-8 md:mt-12 lg:my-[3rem] mb-6 md:mb-8">
              <PrimaryButton 
                title={buttonTitle} 
                fontWeight={"poppins-medium"}
                bgColor={`${bgBack ? bgBack : "bg-white"} border-2 border-[#00D47E] rounded-lg hover:bg-[#00D47E] hover:text-white`}
                height="h-[3.6rem] md:h-[4.5rem] lg:h-[4.5rem]"  
                width="w-[90%] md:w-[50rem]"  
                titleColor="text-black hover:text-white" 
                classNames={`${showCloudButton ? showCloudButton : "invisible"}`}
                className="text-base md:text-lg lg:text-xl"
                onClick={() => {
                  document.getElementById("ready")?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
      </div>
    </div>
  )
}

export default CloudServiceDetails