import React from "react";

const ReusableHeadingSection = ({
  heading,
  subtext,
  headingSize = "text-[3.75rem] xl:text-[6rem]",
  subtextSize = "text-[2.2rem] xl:text-[3.2rem]",
  textColor = "text-white",
  subtextColor="text-white",
  align = "text-center",
  maxWidthHeading = "max-w-[90%]",
  maxWidthSubtext = "max-w-[80%]",
  additionalClasses = "",

}) => {
  return (
    <div className={`relative z-10 w-full flex flex-col items-center font-poppins ${align} ${additionalClasses}`}>
      <h1 className={`${headingSize} ${textColor} w-full ${maxWidthHeading} mt-10 overflow-visible poppins-normal`}>
        {heading}
      </h1>
      <p className={`${subtextSize} ${subtextColor} w-full ${maxWidthSubtext} overflow-hidden mt-12 poppins-regular`}>
        {subtext}
      </p>
    </div>
  );
};

export default ReusableHeadingSection;
