import React from 'react'

function MiniNavbar() {
  return (
    <>
<header className="bg-white">
  <nav className="container-lg">
    <div className="h-fit bg-white w-full flex items-center">
      <ul className="flex flex-wrap justify-center gap-6 md:gap-10 lg:gap-16 xl:gap-20
       w-full text-center max-w-[1400px] mx-auto 2xl:mx-0">
        {/* <li>
          <a className="hover:underline hover:decoration-green-500 hover:decoration-4" href="">
            <p className="hover:font-bold poppins-regular text-lg md:text-xl lg:text-2xl xl:text-2xl leading-[28px] md:leading-[32px] lg:leading-[34px] xl:leading-[36px]">All</p>
          </a>
        </li> */}
        <li>
          <a className="underline decoration-green-500 decoration-4" href="/blogDetails">
            <p className="hover:font-bold poppins-semibold text-[1.5rem] md:text-[1.5rem] lg:text-[2rem]">BLOG</p>
          </a>
        </li>
        {/* <li>
          <a className="hover:underline hover:decoration-green-500 hover:decoration-4" href="">
            <p className="hover:font-bold poppins-regular text-lg md:text-xl lg:text-2xl xl:text-2xl leading-[28px] md:leading-[32px] lg:leading-[34px] xl:leading-[36px]">Whitepaper</p>
          </a>
        </li>
        <li>
          <a className="hover:underline hover:decoration-green-500 hover:decoration-4" href="">
            <p className="hover:font-bold poppins-regular text-lg md:text-xl lg:text-2xl xl:text-2xl leading-[28px] md:leading-[32px] lg:leading-[34px] xl:leading-[36px]">Newsletter</p>
          </a>
        </li>
        <li>
          <a className="hover:underline hover:decoration-green-500 hover:decoration-4" href="">
            <p className="hover:font-bold poppins-regular text-lg md:text-xl lg:text-2xl xl:text-2xl leading-[28px] md:leading-[32px] lg:leading-[34px] xl:leading-[36px]">Podcast</p>
          </a>
        </li>
        <li>
          <a className="hover:underline hover:decoration-green-500 hover:decoration-4" href="">
            <p className="hover:font-bold poppins-regular text-lg md:text-xl lg:text-2xl xl:text-2xl leading-[28px] md:leading-[32px] lg:leading-[34px] xl:leading-[36px]">News Event</p>
          </a>
        </li> */}
      </ul>
    </div>
  </nav>
</header>


    </>
  )
}

export default MiniNavbar
