import React from "react";
import ProcessModelChart from "../../../static/assets/img/ProcessModelChart/image.png";

const processSections = [
  {
    title: "Research",
    color: "bg-[#F1776A]",
    textColor: "text-[#F1776A]",
    items: [
      "Interview/Questionnaire",
      "Requirement Gathering",
      "Field Study",
      "Focus Group",
      "White-boarding",
      "Heuristic Evaluations",
    ],
  },
  {
    title: "Design",
    color: "bg-[#00D47E]",
    textColor: "text-[#00D47E]",
    items: [
      "User Journey",
      "Structure Design",
      "Information Architect",
      "Wireframe",
      "Mockup/Prototyping",
      "UX Guidelines",
    ],
  },
  {
    title: "Development",
    color: "bg-[#3394CF]",
    textColor: "text-[#3394CF]",
    items: [
      "Frontend & Backend",
      "API Integrations",
      "Database Design",
      "UX/UI Implementation",
      "Performance Optimization",
    ],
  },
  {
    title: "Launch",
    color: "bg-[#F08637]",
    textColor: "text-[#F08637]",
    items: [
      "Evaluation",
      "Usability Testing",
      "Expert Usability Review",
      "A/B Testing",
      "Performance Monitoring",
      "User Feedback & Iteration",
    ],
  },
];

const ProcessModel = () => {
  return (
    <div className="relative z-10 w-full max-w-[1440px] mx-auto px-4 md:px-6">
      <div className="w-full pb-16">
        <h2 className="text-[3.75rem] xl:text-[6rem] poppins-normal text-center mt-24 mb-8">
          Process Model
        </h2>
        <p className="text-[1.8rem] xl:text-[2.5rem] text-center text-[#5D656D] poppins-medium mb-6">
          From Imagination to Realization and Beyond: Unveiling Our Process Model for Continuous Support <br />and Future Enhancements.
        </p>
        
        {/* Image Section - Full Width */}
        <div className="w-full flex justify-center md:my-0 my-2">
          <img
            src={ProcessModelChart}
            alt="Process Model Chart"
            className="w-[100%] md:w-[100%] lg:w-[100%] h-auto md:h-[400px] lg:h-[500px] xl:h-[650px] object-cover"
          />
        </div>
        
        {/* Process Sections - Grid Layout */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mx-4 md:mx-12 mt-6 md:mt-0">
          {processSections.map((section, index) => (
            <div key={index} className="flex items-start">
              {/* Colored Vertical Line */}
              <div className={`w-2 h-full ${section.color}`} />
              
              {/* Content Section */}
              <div className="pl-4">
                <h3 className={`text-[2rem] md:text-[2.625rem] xl:text-[4.2rem] poppins-medium ${section.textColor} mb-2`}>
                  {section.title}
                </h3>
                <ul className="list-none poppins-medium text-[#333333] text-[1.8rem] xl:text-[2.4rem] pb-8">
                  {section.items.map((item, i) => (
                    <li key={i} className="mb-1 break-words overflow-visible">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProcessModel;