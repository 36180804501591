import React from "react";
import { useNavigate } from "react-router-dom";
import About from "../../../static/assets/img/AboutImage/About.png";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <section className="py-20 bg-[#F2F4F4] overflow-x-hidden">
      {/* Using the same container style as navbar for alignment */}
      <div className="nav-container max-w-[1440px] mx-auto px-4 md:px-6 container-lg w-full">
        <div className="flex flex-col items-center h-auto lg:h-auto xl:h-auto">
          <h2 className="text-[3.75rem] xl:text-[6rem] poppins-normal text-gray-900 mb-24 mx-auto overflow-hidden">
            About Us
          </h2>

          <div className="relative w-full flex flex-col lg:flex-row items-center justify-left">
            {/* Left Image Section */}
            <div className="hidden lg:block lg:w-[55%] xl:w-[60%] h-[400px] xl:h-[500px]">
              <img
                src={About}
                alt="Team working"
                className="object-cover h-full w-full"
              />
            </div>

            {/* Right Text Section */}
            <div className="w-full lg:w-[55%] xl:w-[50%] bg-white shadow-xl p-6 sm:p-8 md:p-12 lg:p-12 xl:p-16 lg:absolute lg:right-0 lg:top-1/2 lg:-translate-y-1/2">
              <p className="text-[1.8rem] xl:text-3xl/[3.2rem] text-[#707F96] mb-6 leading-relaxed">
                At Infominez, we are the driving force behind transforming
                technology into impactful business outcomes. Our team of
                visionary technologists specializes in AI and ML innovations,
                along with expertise in fintech, data analytics, and cloud
                services, to deliver solutions that redefine industry standards.
                With a deep understanding of AI-driven strategies and a
                commitment to customer success, we create transformative results
                that align with our clients' unique goals. By combining
                technical expertise with creative problem-solving, we enable
                businesses to unlock the power of data, innovate with
                confidence, and thrive in today's AI-driven world.
              </p>
              <button
                className="text-black text-[1.6rem] border-2 border-[#00D47E] bg-white poppins-medium py-3 px-8 rounded-lg transition duration-300 shadow-md hover:bg-[#00D47E] hover:text-white hover:!text-white"
                onClick={() => navigate("/about-us")}
              >
                Know more
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
