import React from 'react'
import { CarrierLogo } from '../../../static/constants/imageConstant'
  
const core = [
    {
        logo:CarrierLogo.carrierLogoOne, 
        heading:"Innovation", 
        line:"We push boundaries to deliver nextgen solutions.", 
    },
    {
        logo:CarrierLogo.carrierLogoTwo, 
        heading:"Collaboration", 
        line:"We believe great ideas come from working together.",
    },
    {
        logo:CarrierLogo.carrierLogoThree, 
        heading:"Growth", 
        line:"We invest in our people to grow both personally and professionally.",
    },
    {
        logo:CarrierLogo.carrierLogoFour, 
        heading:"Excellence", 
        line:"We strive to exceed expectations in all our endeavors.",
    },
]

function OurCoreValues() {
  return (
    <div className="h-fit bg-[#F2F4F4]">
      {/* Match the navbar container structure exactly */}
      <div className="nav-container flex flex-col max-w-[1440px] mx-auto px-4 md:px-6 w-full container-lg">
        <div className="text-center text-[6rem] md:text-[5rem] xl:text-[5rem] poppins-normal mt-16 md:mt-20 lg:mt-28">
            <p>Our Core Values</p>
        </div>
        <div className="text-[#8A929A] text-center text-[2.5rem] sm:text-[2.5rem] md:text-[2.5rem] poppins-medium mt-4">
            <p>At the heart of everything we do at Infominez are our core values that drive our mission</p>
        </div>

        <div className="flex flex-wrap justify-center gap-8 md:gap-12 lg:gap-24 mx-auto my-16 md:my-20 lg:my-24">
            {
               core.map((val, index) => {
                 return (
                    <div key={index} className="lg:h-[340px] 2xl:h-[390px] text-center bg-white w-[160px] md:w-[200px] lg:w-[280px] p-4 shadow-md rounded-lg">
                        <div className="w-[6rem] md:w-[7rem] lg:w-[8rem] mx-auto mt-8 md:mt-10 lg:mt-14">
                            <img src={val.logo} className="w-full" alt={val.heading} />
                        </div>
                        <div className="poppins-light mt-10 text-[3rem] md:text-[3rem]">
                            <p>{val.heading}</p>
                        </div>
                        <div className="mt-10 mb-6 md:mb-8 lg:mb-10 text-[#707F96] text-[2rem] md:text-[2rem] poppins-light">
                            <p>{val.line}</p>
                        </div>
                    </div>
                 )
               }) 
            } 
        </div>
      </div>
    </div>
  )
}

export default OurCoreValues