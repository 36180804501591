import { call, put, takeLatest } from "redux-saga/effects";
import { technologiesFailure, technologiesSuccess } from "../actions/TechnologiesAction";
import { endpoints, strapi_base_url } from "../constants";
import { AxiosService } from "../services";
import { actionTypes } from "../types";

function* technologies({payload, callback})
{
    try {
        const response = yield call(AxiosService, 
        {
            method: 'get',
            base_url: strapi_base_url,
            endPoint: endpoints.technologies + `?populate[logo]=*`,
            data: payload,
            isAuth: true,
        });

        yield put(technologiesSuccess({
            status: response?.status,
            data: response?.data?.data,
            message: response?.message
        })
        )
        if (callback) {
            callback(
                response?.status,
                response?.response,
                response?.message
            ); // Pass the response data to the callback
        }
    } catch (error) 
    {
        yield put(technologiesFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* technologiesSaga() {
    yield takeLatest(actionTypes.TECHNOLOGIES_REQUEST, technologies);
}

export default technologiesSaga;