import React from "react";
import icon1 from "../../../static/assets/img/icons_expertise/icon1.png"
import icon2 from "../../../static/assets/img/icons_expertise/icon2.png"
import icon3 from "../../../static/assets/img/icons_expertise/icon3.png"
import icon4 from "../../../static/assets/img/icons_expertise/icon4.png"
import icon5 from "../../../static/assets/img/icons_expertise/icon5.png"
import icon6 from "../../../static/assets/img/icons_expertise/icon6.png"
import icon7 from "../../../static/assets/img/icons_expertise/icon7.png"
import icon8 from"../../../static/assets/img/icons_expertise/icon8.png"

const expertiseData = [
  {
    title: "Enterprise Web/Mobile Solutions",
    skills: "(Java, Python, RDBMS, React.js, Angular etc.)",
    description:
      "Front-End and Back-End Development, Database Management, Security, Scalability and Performance Optimization, API Development and Integration, DevOps, Microservices Architecture",
    image: icon1,
  },
  {
    title: "Cloud Engineering",
    skills: "(Azure, AWS, GCP, Docker, Jenkins etc.)",
    description:
      "Cloud Application Development, Cloud Migration and Deployment, Security and Compliance, Scalability and Performance Optimization, DevOps Automation, Disaster Recovery and Backup",
    image: icon5,
  },
  {
    title: "Enterprise Mobile Solutions",
    skills: "(Java, Kotlin, Swift, React Native, Flutter etc.)",
    description:
      "Native App and Cross-Platform Mobile App Development, Offline Functionality, Deployment and Distribution, Device Management and MDM Integration, Push Notifications and Messaging",
    image: icon2,
  },
  {
    title: "Data Engineering",
    skills: "(DataBricks, Data Factory, Tableau, Looker etc.)",
    description:
      "Big Data Processing, ETL Pipelines, Data Warehousing, Real-Time Analytics, Business Intelligence, Data Governance, Performance Optimization",
    image:icon6,
   
  },
  {
    title: "UI/UX",
    skills: "(Figma, Adobe XD, Sketch, Axure RP, Miro etc.)",
    description:
      "Competitive Analysis, User Flow, Wireframes, Style Guide, High Fidelity Mockups, Prototypes",
    image: icon3,
  },
  {
    title: "Quality Assurance",
    skills: "(JMeter, Selenium, Postman, TestNG etc.)",
    description:
      "Automated Testing, Manual Testing, Performance Testing, API Testing, Load Testing, Security Testing, Continuous Integration Testing",
    image: icon7,
  },
  {
    title: "AI and ML",
    skills: "(TensorFlow, PyTorch, OpenAI API, etc.)",
    description:
      "Predictive Analytics, Natural Language Processing (NLP), Computer Vision, Recommendation Systems, Model Training and Optimization, Deployment and Monitoring, Data Preprocessing and Feature Engineering, AI/ML Integration with Enterprise Applications",
    image: icon4,
    
  },
  {
    title: "Smart Device Integration",
    skills: "(IoT Core, MQTT, Zigbee, RaspBerryPi etc.)",
    description:
      "Device Communication Protocols, Sensor Data Management, Edge Computing, Cloud Connectivity, IoT Device Security, Real-Time Data Processing, Smart Home and Industrial Automation Solutions",
    image: icon8,
  },
];

const ExpertiseSection = () => {
  return (
    <section className="w-full bg-[#F2F4F4] py-16">
      <div className="nav-container flex flex-col max-w-[1440px] mx-auto items-center container-lg py-[1.2rem] px-4 md:px-6 w-full overflow-visible">
        <div className="text-center w-full">
          <h2 className="text-[3.75rem] xl:text-[6rem] font-extralight text-gray-900 mb-2">Our Expertise</h2>
          <p className="text-[2rem] xl:text-[2.5rem] mb-14 text-[#8A929A]">
            We excel in developing groundbreaking solutions that redefine industry standards.
            <br /> Our core services include:
          </p>
        </div>

        {/* Grid for expertise cards */}
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
          {expertiseData.map((item, index) => (
            <div key={index} className="flex gap-4 items-start text-left">
              {/* Circular Image Container */}
              <div className="w-18 h-18 flex items-center justify-center rounded-full bg-black shrink-0 aspect-square overflow-hidden p-4">
                <img src={item.image} alt={item.title} className="w-16 h-16 object-contain" />
              </div>
              {/* Content */}
              <div>
             
                <h3 className="text-[2rem] xl:text-[2.8rem] poppins-regular text-[#121212]">{item.title}</h3>
                {item.skills && <p className="text-[1.8rem] xl:text-[2.4rem] poppins-semibold text-[#727272]">{item.skills}</p>}
                <p className="text-[1.7rem] xl:text-[2.2rem] poppins-regular text-[#707F96] mt-1">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExpertiseSection;