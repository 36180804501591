import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import video from "../../../static/assets/videos/service-landing-bg1.mp4"
import PrimaryButton from '../Buttons/PrimaryButton'
import "./ServiceHeadingCss.css"

function ServiceHeading({serviceHeading, serviceboldHeading, serviceLineOne}) 
{
  return (
    <>
      <div className="h-fit relative overflow-hidden">
        {/* Video Background */}
        <video
          className="absolute top-0 left-0 w-full h-full object-cover -z-10"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
        
        {/* Overlay for better text visibility */}
        <div className="absolute inset-0 z-[9]"></div>

        {/* Content */}
        <div className="container-lg h-[530px] px-4 sm:px-6 md:px-8 lg:px-16 xl:px-20 flex flex-col justify-center items-center text-center mt-[6rem] sm:mt-[7rem] md:mt-[8rem] lg:mt-[10rem]">

            <p className="text-[#FFFFFF] poppins-light text-[2.5rem] sm:text-[3rem]
             md:text-[3.5rem] lg:text-[4rem] xl:text-[5rem] text-shadow-dark">
              {serviceHeading}
            </p>
            <p className="text-[#FFFFFF] poppins-bold pt-2 sm:pt-4 text-[2rem]
            sm:text-[2.5rem] md:text-[3rem] lg:text-[4rem] xl:text-[5rem] text-shadow-dark">
              {serviceboldHeading}
            </p>
          
          <div className="flex justify-center pt-4 sm:pt-6 z-10">
            <PrimaryButton 
              title={"Learn More"} 
              textSize={"text-[1.6rem] xl:text-[2rem]"}
              hasArrow={<BsArrowRight />} 
              titleColor={"text-[#0E0731] hover:text-white"} 
              bgColor={"bg-[#00D47E] rounded-full"}
              classNames={"mb-6 sm:mb-10 md:mb-16 lg:mb-20 xl:mb-24 poppins-medium mt-[2rem] sm:mt-[3rem]"} 
              width={"w-[18rem] sm:w-[18rem] md:w-[20rem] lg:w-[24rem] xl:w-[26rem]"} 
              height={"h-[40px] sm:h-[55px] md:h-[50px] lg:h-[60px] xl:h-[70px]"} 
              onClick={() => 
              {
                document.getElementById("transformation")?.scrollIntoView({ behavior: "smooth" });
              }}
            />
          </div>
        </div>
      </div>

      {/* Second Section */}
      <div className="h-fit bg-white my-[5rem] sm:my-[6rem] md:my-[7rem] lg:my-[8rem]">
        <div className="container-lg">
          <div className="text-center poppins-medium text-[1.5rem] sm:text-[1.8rem] 
          md:text-[2rem] lg:text-[2.2rem] xl:text-[2.5rem] text-[#8A929A]">
            <div className="container-lg px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16">
              <p>{serviceLineOne}</p>
            </div>        
          </div>
        </div>    
      </div>  
    </>   
  )
}

export default ServiceHeading
