import { useEffect, useState } from 'react';
import { FaLinkedin } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { CarrerImg } from '../../../static/constants/imageConstant';
import { image } from '../../../Store/actions/imageAction';
import { strapi_base_url } from '../../../Store/constants';
import LeftArrowCircle from '../../common/Buttons/LeftArrowCircle';
import RightArrowButton from '../../common/Buttons/RightArrowButton';

function HearTeam() 
{
  // Redux API call
  const dispatch = useDispatch();
  const selectImage = useSelector((state) => state.imageReducer.data);

  useEffect(() => 
  {
    dispatch(image());
  },[dispatch]);

  
  const getInitialDisplayCount = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth < 768 ? 1 : 2;
    }
    return 2; 
  };
  
  const [displayCount, setDisplayCount] = useState(getInitialDisplayCount());
  
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() =>
  {
    const handleResize = () => 
    {
      const newDisplayCount = window.innerWidth < 768 ? 1 : 2;
      if (newDisplayCount !== displayCount) 
      {
        setDisplayCount(newDisplayCount);

        if (currentIndex + newDisplayCount > selectImage.length) {
          setCurrentIndex(selectImage.length - newDisplayCount);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [displayCount, currentIndex]);

  const getVisibleTeam = () => 
  {
    let visibleTeam = [];
    for (let i = 0; i < displayCount; i++) {
      const index = (currentIndex + i) % selectImage.length;
      visibleTeam.push(selectImage[index]);
    }
    return visibleTeam;
  };

  const handlePrev = () => 
  {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? selectImage.length - displayCount : prevIndex - 1
    );
    pauseAutoSlide();
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) > (selectImage.length - displayCount) ? 0 : prevIndex + 1
    );
    pauseAutoSlide();
  };

  const pauseAutoSlide = () => {
    setIsPaused(true);

    setTimeout(() => {
      setIsPaused(false);
    }, 5000);
  };

  useEffect(() => {
    let interval;
    
    if (!isPaused) {
      interval = setInterval(() => 
      {
        setCurrentIndex((prevIndex) => 
          (prevIndex + 1) > (selectImage.length - displayCount) ? 0 : prevIndex + 1
        );
      }, 6000);
    }
    
    return () => 
    {
      if (interval) 
      {
        clearInterval(interval);
      }
    };
  }, [currentIndex, isPaused, selectImage.length, displayCount]);

  const visibleTeam = getVisibleTeam();
 
  return (
    <div className="w-full h-fit bg-gradient-1">
    {/* Match the navbar container structure exactly */}
    <div className="nav-container flex flex-col max-w-[1440px] mx-auto container-lg px-4 md:px-6 w-full py-8 md:py-12 lg:py-16">
      <div className="flex flex-col lg:flex-row gap-8 lg:gap-12">
        {/* Title and description section */}
        <div className="w-full lg:w-1/2 lg:pr-6">
          <div className="text-[3.4rem] md:text-[4.5rem] lg:text-[5rem] overflow-hidden poppins-normal mt-8 lg:mt-12">
            <p>Hear from Our</p>
            <p>Team</p>
          </div>
          <div className="text-[1.6rem]/[2.3rem] lg:text-[2rem]/[3rem] overflow-hidden text-[#8A929A] poppins-medium mt-6">
            <p>Don't just take our word for it—hear what our employees
            have to say about life at Infominez. Discover their stories of
            growth, success, and the unique experiences that make
            working here so rewarding.</p>
          </div>
        </div>

{/* Team member cards */}
<div 
  className="w-full lg:w-[50%] flex flex-col md:flex-row gap-6 md:gap-8 lg:gap-10 justify-center lg:mt-20"
  onMouseEnter={() => setIsPaused(true)}
  onMouseLeave={() => setIsPaused(false)}
>
  {visibleTeam.map((val, index) => (
    <div 
      key={index}
      className="md:w-[30rem] sm:w-full w-full bg-white overflow-hidden
      my-4 rounded-lg flex flex-col items-center  shadow-md shadow-gray-200 h-[45rem]"
    >
      {/* Fixed profile photo to maintain aspect ratio */}
      <div className="w-28 md:w-32 lg:w-36 h-28 md:h-32 lg:h-44 overflow-hidden 
      my-6 md:my-8 rounded-[5rem] lg:absolute lg:-mt-[5rem]">
        <img 
          src={strapi_base_url+"_img/"+val?.attributes?.employee_profile?.data?.attributes?.url} 
          alt={"not found"} 
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="h-8 w-10 lg:mt-[10rem]">
         <img 
          src={CarrerImg.greenUpperColon}
         />
      </div>
      <div className="text-center px-4 md:px-6 lg:mt-[1rem]">
        <div className="text-xl md:text-[2rem]/[2.5rem] montserrat-medium overflow-hidden">
          <p>{val?.attributes?.employee_name}</p>
        </div>
        <div className="my-4 md:my-6 dm-sans-medium-italic lg:mt-[4rem]
         text-[#979EA5] text-[1.3rem] md:text-[1.5rem]">
          <p className=" overflow-hidden">
            {val?.attributes?.employee_review}
          </p>
        </div>
        {/* Fixed LinkedIn image size */}
        <div className="h-12 w-44 mx-auto mb-6 flex items-center justify-center overflow-hidden">
          <a href={"https://www."+val?.attributes?.employee_url} target="_blank" 
          className="flex items-center gap-1">
          <FaLinkedin size={16}/><p className="montserrat-bold text-[1.5rem]/[2rem] overflow-hidden">Linkedin</p>
          </a>
        </div>
      </div>
    </div>
  ))}
</div>
</div>
      
      {/* Navigation controls */}
      <div className="flex justify-between items-center mt-8 md:mt-12 mb-8">
        <div onClick={handlePrev} className="cursor-pointer">
          <LeftArrowCircle bgBack={"bg-[#F2F4F4]"}/>
        </div>
        
        {/* <div className="flex gap-2 items-center">
          {selectImage.map((_, index) => (
            <div 
              key={index}
              className={`h-2 w-2 rounded-full cursor-pointer ${
                (index >= currentIndex && index < currentIndex + displayCount) || 
                (currentIndex + displayCount > selectImage.length && index < (currentIndex + displayCount) % selectImage.length)
                ? 'bg-[#2666CF]' : 'bg-gray-300'
              }`}
              onClick={() => {
                setCurrentIndex(index > selectImage.length - displayCount ? selectImage.length - displayCount : index);
                pauseAutoSlide();
              }}
            />
          ))}
        </div> */}

        <div onClick={handleNext} className="cursor-pointer">
          <RightArrowButton bgBack={"bg-[#F2F4F4]"}/>
        </div>
      </div>
    </div>
  </div>
  );
}

export default HearTeam;