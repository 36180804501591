import React, { useEffect } from "react";
import DetailsHeading from "../../common/DetailsCommons/DetailsHeading";
import BlogMan from "../BlogMan/BlogMan";
import Footer from "../FooterSec/Footer";
import ReadyToGetStartSec from "../ReadyToGetStartSec/ReadyToGetStartSec";
import RelatedPost from "../RelatedPost/RelatedPost";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { blogs } from "../../../Store/actions/blogActions";
import { strapi_base_url } from "../../../Store/constants";
import moment from "moment";

function BlogDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const dataa = useSelector((state) => state.blogReducer.data);

  useEffect(() => {
    dispatch(blogs());
  }, [dispatch]);

  // Safety check to prevent errors if data is not yet loaded
  if (!dataa || !dataa[id]) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  // Safe splitting with fallback to empty array
  const description_1 =
    dataa[id]?.attributes?.description_1?.split("/n/n") || [];
  const subTitle_1 = dataa[id]?.attributes?.sub_title_1?.split("/n/n") || [];
  const description_2 =
    dataa[id]?.attributes?.description_2?.split("/n/n") || [];
  const description_3 =
    dataa[id]?.attributes?.description_3?.split("/n/n") || [];

  // Safely construct image URLs
  const mainImage = dataa[id]?.attributes?.main_image?.data?.attributes.url
    ? strapi_base_url +
      "_img" +
      dataa[id].attributes.main_image.data.attributes.url
    : null;

  const subImageOne = dataa[id]?.attributes?.sub_image_1?.data?.attributes.url
    ? strapi_base_url +
      "_img" +
      dataa[id].attributes.sub_image_1.data.attributes.url
    : null;

  const subImageTwo = dataa[id]?.attributes?.sub_image_2?.data?.attributes.url
    ? strapi_base_url +
      "_img" +
      dataa[id].attributes.sub_image_2.data.attributes.url
    : null;

  return (
    <div>
      <DetailsHeading
        bgImg={"blogDetails"}
        headingOneBlog={"Home  Digital  It's the Best. We Must"}
        headingTwoBlog={"Do  Better"}
      />
      <BlogMan
        date={moment(dataa[id]?.attributes?.publishedAt).format("DD-MMM-YYYY")}
        maintitle={dataa[id]?.attributes?.main_title}
        subMainTitle={dataa[id]?.attributes?.sub_main_title}
        mainImage={mainImage}
        description_1={description_1}
        title_1={dataa[id]?.attributes?.title_1}
        subTitle_1={subTitle_1}
        subImageOne={subImageOne}
        subImageTwo={subImageTwo}
        description_2={description_2}
        title_3={dataa[id]?.attributes?.title_3}
        description_3={description_3}
      />
      <RelatedPost />
      <ReadyToGetStartSec bgColor={"bg-white"} />
      <Footer />
    </div>
  );
}

export default BlogDetails;
